import {LitElement, css} from 'lit';
export const checkboxStyles = css`
:host {
  display: block;
  margin: 0;
  padding: 0;
  --black: #000; 
}

.block-group {
  display: grid;
}

.title {
  color: var(--black, #000);
  font-family: Poppins, sans-serif;
  font-style: normal;
  line-height: 30px;
  margin-bottom: 8px; 
  font-weight: bold;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox-group {
  margin-bottom: 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}

.hidden-checkbox {
  visibility: hidden;
  position: absolute;
}

.custom-checkbox {
  position: relative;
  height: 18px;
  width: 18px;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 4px;
  margin-right:5px;
}

.hidden-checkbox:checked + .custom-checkbox {
  background-color: rgba(142, 73, 83, 1);
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 16px;
  visibility: hidden;
}

.hidden-checkbox:checked + .custom-checkbox::before {
  content: "\\2714";
  visibility: visible;
}

`;
