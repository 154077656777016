import { LitElement, html, css } from "lit";
import html2canvas from "html2canvas"; // If using npm
import Chart from "chart.js/auto";
import { auth, db } from "../../firebase-config"; // Import your Firebase config
import { onAuthStateChanged, signOut } from "firebase/auth";
import { customElement, property } from "lit/decorators.js";

import {
  getDocs,
  collection,
  query,
  where,
  doc,
  setDoc,
  Timestamp,
  getDoc,
} from "firebase/firestore";

export class ReportView extends LitElement {
  static properties = {
    surveyId: { type: String }, // To hold the survey ID from the URL
    surveyName: { type: String },
    surveyDate: { type: String },
    organization: { type: String },
    numberOfParticipants: { type: Number },
    gradeRange: { type: String },
    medianAge: { type: Number },
    outcome: { type: String },
    conclusion: { type: String },
    chartData: { type: Object },
    reportName: { type: String },
    reportType: { type: String },
    parameters: { type: Array }, // An array of key-value pairs for parameters
    result: { type: String }, // A placeholder for the report result
    currentQuestionIndex: { type: Number }, // Track the current question
    questions: { type: Array }, // Array of questions and related data
    responses: { type: Object },

    title: { type: String },
    administered: { type: String },
    ageGroup: { type: String },
    scope: { type: String },
    measurement: { type: String },
    metrics: { type: String },
    version: { type: String },
  };

  static styles = css`
    :host {
      display: block;
      font-family: Arial, sans-serif;
      padding: 20px;
      background-color: #fff;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .buttons {
      display: flex;
      gap: 10px;
    }
    /* .buttons button {
      padding: 10px 15px;
      font-size: 14px;
      color: white;
      background-color: #5a6773;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    .buttons button:hover {
      background-color: #3e4852;
    } */
    .chart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    .chart canvas {
      max-width: 200px;
      max-height: 200px;
    }

    :host {
      display: block;
      font-family: Arial, sans-serif;
      padding: 20px;
      background-color: #fff;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .dropdowns {
      display: flex;
      gap: 20px;
    }
    .dropdowns select {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    .buttons {
      display: flex;
      gap: 10px;
    }
    /* .buttons button {
      padding: 10px 15px;
      font-size: 14px;
      color: white;
      background-color: #5a6773;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    .buttons button:hover {
      background-color: #3e4852;
    } */
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }
    table th,
    table td {
      border: 1px solid #ddd;
      padding: 12px;
      text-align: left;
    }
    table th {
      background-color: #f9f9f9;
      color: #555;
    }
    .outcome {
      background-color: #f8d7da;
      font-weight: bold;
    }
    .conclusion {
      background-color: #ffe8cc;
      font-weight: bold;
    }
    .chart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    .chart canvas {
      max-width: 200px;
      max-height: 200px;
    }

    .dropdown {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .dropdown label {
      font-size: 14px;
      color: #555;
      margin-bottom: 5px;
    }

    .dropdown select {
      padding: 8px 12px;
      font-size: 14px;
      color: #555;
      background: #f1f1f1;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
      cursor: pointer;
    }

    .dropdown select:hover {
      border-color: #007bff;
    }

    .dropdown select:focus {
      border-color: #007bff;
      box-shadow: 0 0 2px #007bff;
    }

    .report-header {
      font-size: 1.5rem;
      margin-bottom: 12px;
    }
    .report-detail {
      margin: 8px 0;
      font-size: 1rem;
    }
    .parameters {
      margin: 12px 0;
      padding: 12px;
      background: #f9f9f9;
      border: 1px solid #eee;
      border-radius: 6px;
    }
    .parameter-item {
      margin: 4px 0;
    }

    .button {
      background-color: #8e4953;
      border: 0 solid #e2e8f0;
      border-radius: 1.5rem;
      box-sizing: border-box;
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      font-family: "Basier circle", -apple-system, system-ui, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1;
      padding: 1rem 1.6rem;
      text-align: center;
      text-decoration: none #ffffff solid;
      text-decoration-thickness: auto;
      transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
    }

    .button:hover {
      margin-top: 0px;
      -moz-box-shadow: 0px 0px 4px 1px grey;
      -webkit-box-shadow: 0px 0px 4px 1px grey;
      box-shadow: 0px 0px 4px 1px grey;
    }

    #padded-container {
      padding: 40px;
      background: white;
      display: inline-block;
      box-sizing: border-box;
      width: 98%;
    }
    /* #report-section {
      margin: 0;
      padding: 0;
      display: inline-block;
      background: white;
    } */

    .button.next {
      float: right;
    }

    @media (min-width: 768px) {
      .button {
        font-size: 1.125rem;
        padding: 1rem 2rem;
      }
    }
  `;

  constructor() {
    super();

    this.currentQuestionIndex = 0; // Start with the first question
    // this.questions = [
    //   { label: "I feel confident with math", value: 35, color: "#93a392" },
    //   { label: "I enjoy solving problems", value: 50, color: "#ff7f50" },
    //   { label: "Math helps in real life", value: 60, color: "#8bcdcd" },
    //   { label: "I struggle with equations", value: 20, color: "#e57373" },
    //   { label: "Geometry is fun", value: 70, color: "#4caf50" },
    //   { label: "Statistics is useful", value: 80, color: "#fbc02d" },
    //   { label: "I like learning new concepts", value: 65, color: "#9c27b0" },
    //   { label: "Practice makes perfect", value: 90, color: "#3f51b5" },
    //   { label: "Math tests are stressful", value: 25, color: "#f44336" },
    //   { label: "Math is my favorite subject", value: 55, color: "#03a9f4" },
    // ];

    this.questions = [
      {
        order: 5,
        id: "0FEVErxGmDvgKQpuI9Qu",
        community: "community",
        source: "Demographic",
        response_type: "single-select",
        outcome: "Demographic",
        question: {
          es: "¿Con qué género te identificas?",
          en: "What gender do you identify as?",
          zh: "您的性别是?",
        },
        response_options: {
          es: {
            options: [
              "Femenino",
              "Masculino",
              "No binario",
              "Prefiero no responder",
              "Otro",
            ],
          },
          en: {
            options: [
              "Female",
              "Male",
              "Non-binary",
              "Prefer not to respond",
              "Other",
            ],
          },
          zh: {
            options: ["女性", "男性", "非二元性别", "选择不回答", "其他"],
          },
        },
        framework: "HRC",
        version: "v.1.1",
        updated_at: "2024-11-28T11:33:22.560Z",
        indicator: "Gender",
        created_at: "2024-11-28T11:33:22.560Z",
      },
      {
        order: 9,
        id: "YETEMUZANA5MxEcVRfOM",
        indicator: "Body Image",
        outcome: "Mental Health",
        question: {
          es: "Me siento bien con mi apariencia física.",
          zh: "我对自己的外貌感到满意。",
          en: "I feel good about the way I look",
        },
        community: "Youth",
        framework: "Mental Health",
        source: "Mental Health",
        created_at: "2024-11-28T11:34:01.253Z",
        response_type: "single-select",
        response_options: {
          zh: {
            options: ["完全没有", "有一点", "有些", "相当多", "非常多"],
          },
          en: {
            options: [
              "Not at all",
              "A little",
              "Somewhat",
              "Quite a bit",
              "Very much",
            ],
          },
          es: {
            options: ["Nada", "Un poco", "Algo", "Bastante", "Mucho"],
          },
        },
        version: "v.1.1",
        updated_at: "2024-11-28T11:34:01.253Z",
      },
      {
        order: 11,
        id: "1iYfZozcwp0f2lRxglct",
        community: "Youth",
        created_at: "2024-11-28T11:34:22.454Z",
        title: {
          en: "How often do you feel the following range of emotions:",
          es: "¿Con qué frecuencia sientes las siguientes emociones? .",
          zh: "您多久会感到以下情绪？快乐",
        },
        outcome: "Mental Health",
        response_options: {
          zh: {
            options: ["几乎从不", "有时", "经常", "几乎总是"],
          },
          en: {
            options: ["Almost Never", "Sometimes", "Often", "Almost Always"],
          },
          es: {
            options: [
              "Casi nunca",
              "A veces",
              "Frecuentemente",
              "Casi siempre",
            ],
          },
        },
        source: "Mental Health",
        response_type: "likert",
        version: "v.1.1",
        indicator: "General",
        question: {
          es: [
            "Feliz",
            "Triste",
            "Enojado",
            "Preocupado",
            "Celoso",
            "Avergonzado",
            "Solo",
            "Amado",
            "Emocionado",
            "Confundido.",
          ],
          zh: [
            "悲伤",
            "生气",
            "担心",
            "嫉妒",
            "尴尬",
            "孤独",
            "被爱",
            "兴奋",
            "困惑。",
          ],
          en: [
            "Happy",
            "Sad",
            "Angry",
            "Worried",
            "Jealous",
            "Embarassed",
            "Lonely",
            "Loved",
            "Excited",
            "Confused",
          ],
        },
        updated_at: "2024-11-28T11:34:22.454Z",
        framework: "Mental Health",
      },
    ];

    this.responses = [
      {
        id: "8MlReYKaPDnlu9DB1c0z",
        updatedAt: "2024-12-03T04:37:59.776Z",
        surveyId: "ZC1hh4U8iiBArs1tNQ9j",
        createdAt: "2024-12-03T04:37:59.775Z",
        answers: [
          {
            questionId: "0FEVErxGmDvgKQpuI9Qu",
            answer: "Male",
          },
          {
            answer: "A little",
            questionId: "YETEMUZANA5MxEcVRfOM",
          },
        ],
        surveyName: "Your First Survey",
        invitationCode: "cc9fd5gmpdsxjmhs3azw",
        language: "en",
        status: "active",
        name: "ok",
      },
      {
        id: "dxMk7xVAJk0D4voz90iu",
        status: "active",
        answers: [
          {
            questionId: "0FEVErxGmDvgKQpuI9Qu",
            answer: "Male",
          },
          {
            answer: "Quite a bit",
            questionId: "YETEMUZANA5MxEcVRfOM",
          },
        ],
        language: "en",
        surveyName: "Your First Survey",
        updatedAt: "2024-12-03T22:06:22.551Z",
        name: "ok",
        createdAt: "2024-12-03T22:06:22.551Z",
        surveyId: "ZC1hh4U8iiBArs1tNQ9j",
        invitationCode: "cc9fd5gmpdsxjmhs3azw",
      },
      {
        id: "tLQpCpgcU1RQegeA7Djx",
        name: "ok",
        status: "active",
        surveyName: "Your First Survey",
        invitationCode: "cc9fd5gmpdsxjmhs3azw",
        answers: [
          {
            questionId: "0FEVErxGmDvgKQpuI9Qu",
            answer: "Male",
          },
          {
            questionId: "YETEMUZANA5MxEcVRfOM",
            answer: "Somewhat",
          },
        ],
        surveyId: "ZC1hh4U8iiBArs1tNQ9j",
        language: "en",
        updatedAt: "2024-12-02T18:19:40.279Z",
        createdAt: "2024-12-02T18:19:40.279Z",
      },
    ];

    this.surveyId = null; // Initialize as null
    this.surveyName = "First Year Findings";
    this.surveyDate = "05.06.24 - 11.06.24";
    this.organization = "Connect Every Day";
    this.numberOfParticipants = 32;
    this.gradeRange = "2-10";
    this.medianAge = 14;
    this.outcome = "";
    this.conclusion = "";
    this.chartData = {
      label: "I feel confident with math",
      value: 35,
      color: "#93a392",
    };
    this.reportName = "Sample Report";
    this.reportType = "Sales";
    this.parameters = [
      { key: "Date Range", value: "01/01/2023 - 01/31/2023" },
      { key: "Region", value: "North America" },
    ];
    this.result = ""; // Initially empty

    this.title = ""; // Initially empty
    this.administered = ""; // Initially empty
    this.ageGroup = ""; // Initially empty
    this.scope = ""; // Initially empty
    this.measurement = ""; // Initially empty
    this.metrics = ""; // Initially empty
    this.version = ""; // Initially empty
  }

  connectedCallback() {
    super.connectedCallback();
    this.extractSurveyIdFromUrl();
    //this.loadResponses();
    this.loadSurveyData();
  }

  extractSurveyIdFromUrl() {
    const pathSegments = window.location.pathname.split("/");
    this.surveyId = pathSegments[pathSegments.indexOf("reports") + 2]; // Finds the ID after "edit"
    console.log("this.surveyId" + this.surveyId);
    //return inviteId;
  }

  async getSurveyDetailsById(surveyId) {
    try {
      // Reference to the survey document in the Firestore collection
      const surveyDocRef = doc(db, "surveys", this.surveyId);

      // Fetch the document
      const surveyDoc = await getDoc(surveyDocRef);

      if (surveyDoc.exists()) {
        // Return survey data if document exists
        //console.log("Survey found:", surveyDoc.data());
        return surveyDoc.data();
      } else {
        // Survey document not found
        //console.error(`No survey found with ID: ${this.surveyId}`);
        return null;
      }
    } catch (error) {
      // Handle and log errors
      console.error("Error fetching survey details:", error);
      throw error;
    }
  }

  renderTimestamp(timestamp) {
    if (!timestamp) return "N/A";

    const date = timestamp.toDate();
    return date.toLocaleString("en-US", {
      timeZone: "America/New_York", // Your desired timezone
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  // Load survey data based on the extracted surveyId
  async loadSurveyData() {
    if (this.surveyId) {
      // Example: Simulate an API call using the surveyId
      console.log(`Fetching data for surveyId: ${this.surveyId}`);

      try {
        const surveyData = await this.getSurveyDetailsById(this.surveyId);

        if (surveyData) {
          console.log("Survey Details:", surveyData);

          const timestamp = new Timestamp(
            surveyData.publishAt.seconds,
            surveyData.publishAt.nanoseconds
          );
          // Convert to JavaScript Date
          const publishDate = timestamp.toDate();

          // Format the date (example using locale strings)
          const formattedDate = publishDate.toLocaleString("en-US", {
            timeZone: "UTC", // Change this to the desired timezone if needed
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });

          this.surveyDate = formattedDate;

          this.surveyName = surveyData.title;
          //this.surveyDate = "05.06.24 - 11.06.24";
          // this.organization = "Connect Every Day";

          this.title = surveyData.title;
          this.administered = surveyData.time_period;
          this.ageGroup = surveyData.ageGroup;
          this.scope = surveyData.scope;
          this.measurement = surveyData.measurement;
          this.metrics = surveyData.metrics;
          this.version = surveyData.version;

          // Step 2: Fetch organization name using organization_id
          if (surveyData.organization_id) {
            const orgDocRef = surveyData.organization_id; // Assuming it's a DocumentReference
            const orgDoc = await getDoc(orgDocRef);

            if (orgDoc.exists()) {
              const organizationData = orgDoc.data();
              this.organization = organizationData.name; // Assuming organization document has a `name` field
              console.log("Organization Name:", this.organization);
            } else {
              console.error("Organization document not found");
            }
          } else {
            console.error("Survey does not have a valid organization_id");
          }

          // Process and display the survey data

          // Step 3: Fetch questions based on references
          if (surveyData.questions && Array.isArray(surveyData.questions)) {
            this.questions = [];

            for (const question of surveyData.questions) {
              const { reference, order } = question;

              if (reference) {
                const questionDoc = await getDoc(reference);

                if (questionDoc.exists()) {
                  this.questions.push({
                    order,
                    id: reference.id,
                    ...questionDoc.data(),
                  });
                } else {
                  console.error(
                    `Question document with reference ${reference.id} not found`
                  );
                }
              } else {
                console.error(`Invalid question reference for order ${order}`);
              }
            }

            // Sort questions by order
            this.questions.sort((a, b) => a.order - b.order);
            //console.log("Questions Loaded:", this.questions);
          } else {
            console.error("Survey does not contain a valid questions array");
          }
        } else {
          console.log("No survey data found for the given ID.");
        }
      } catch (error) {
        console.error("Failed to load survey data:", error);
      }
    }
  }

  firstUpdated() {
    this.generateReport();
    this.loadResponses();
  }

  async loadResponses() {
    try {
      // Query to fetch responses where surveyId matches
      const responsesRef = collection(db, "responses");
      const q = query(responsesRef, where("surveyId", "==", this.surveyId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        this.responsesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Responses found:", this.responsesData);

        this.numberOfParticipants = this.responsesData.length;
      } else {
        console.log("No responses found for the given survey ID");
      }
    } catch (error) {
      console.error("Error fetching responses:", error);
    }
  }

  async generateReport() {
    // Simulate a report generation delay
    const pageLoader = this.shadowRoot.querySelector("page-loader");
    pageLoader.loadShowPage(); // Call the loadPage method in the PageLoader component
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulate delay

    this.result = "Generating...";
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API delay
    pageLoader.loadHidePage();
    this.result = "Report successfully generated!";
    this.showAlert("success", "Report successfully generated!");
  }

  async downloadImage() {
    const element = this.shadowRoot.getElementById("padded-container"); // Select the section to capture
    const canvas = await html2canvas(element, {
      scale: 5, // Increase scale for higher resolution
      width: element.offsetWidth, // Ensure the entire width is captured
      height: element.offsetHeight, // Ensure the entire height is captured
      useCORS: true, // Allow cross-origin resources
      // backgroundColor: null, // Ensure transparent or white background
      padding: 0, // No padding
      x: 0, // Crop any margin
      y: 0, // Crop any margin
    });

    // Use toBlob for efficient and high-quality export
    canvas.toBlob(
      (blob) => {
        const link = document.createElement("a");
        link.download = "report.png";
        link.href = URL.createObjectURL(blob);
        link.click();
      },
      "image/png",
      1.0
    ); // Set format and quality
  }

  async copyToClipboard() {
    const element = this.shadowRoot.getElementById("padded-container"); // Select the section to capture
    const canvas = await html2canvas(element, {
      scale: 5, // Increase scale for higher resolution
      width: element.offsetWidth, // Ensure the entire width is captured
      height: element.offsetHeight, // Ensure the entire height is captured
      useCORS: true, // Allow cross-origin resources
      // backgroundColor: null, // Ensure transparent or white background
      padding: 0, // No padding
      x: 0, // Crop any margin
      y: 0, // Crop any margin
    });

    // Convert canvas to Blob and copy to clipboard
    canvas.toBlob(async (blob) => {
      try {
        const clipboardItem = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([clipboardItem]);

        this.showAlert("success", "Image copied to clipboard!");

        // alert("Image copied to clipboard!");
      } catch (error) {
        console.error("Failed to copy image:", error);
        this.showAlert(
          "error",
          "Failed to copy image to clipboard. Please try again."
        );
        //alert("Failed to copy image to clipboard. Please try again.");
      }
    });
  }

  showAlert(type, message) {
    const alert = this.shadowRoot.querySelector("custom-alert");
    if (alert) {
      alert.type = type;
      alert.message = message;
      alert.visible = true;

      // Auto-hide the alert after 3 seconds
      setTimeout(() => {
        alert.visible = false;
      }, 3000);
    }
  }

  async loadQuestionData(questionId) {
    this.isLoading = true; // Show a loading state
    try {
      // Reference the Firestore document
      const questionDocRef = doc(
        this.firestoreInstance,
        "question-box",
        questionId
      );
      const questionDoc = await getDoc(questionDocRef);

      if (questionDoc.exists()) {
        this.questionData = questionDoc.data();
        console.log("Question data loaded:", this.questionData);

        // Optionally process and display the data in your component
      } else {
        console.error("No question found with the given ID");
      }
    } catch (error) {
      console.error("Error fetching question data:", error);
    } finally {
      this.isLoading = false; // Hide the loading state
    }
  }

  // Function to calculate frequencies
  calculateFrequencies(data) {
    const frequencies = {};

    data.forEach((entry) => {
      const { answer } = entry;

      if (typeof answer === "string") {
        // Single answer: Increment frequency
        frequencies[answer] = (frequencies[answer] || 0) + 1;
      } else if (typeof answer === "object") {
        // Multiple answers: Loop through nested answers
        Object.values(answer).forEach((response) => {
          frequencies[response] = (frequencies[response] || 0) + 1;
        });
      }
    });

    return frequencies;
  }

  getAnswerStatistics(
    surveyResponses = [],
    questionsData = [],
    questionId,
    language = "en"
  ) {
    // Validate inputs
    if (!Array.isArray(surveyResponses)) {
      console.error("surveyResponses must be an array");
      return null;
    }
    if (!Array.isArray(questionsData)) {
      console.error("questionsData must be an array");
      return null;
    }

    let answerCountsss = [];

    // Check if the question exists in the questionsData
    const question = questionsData.find((q) => q.id === questionId);
    if (!question) {
      console.error(`Question with ID ${questionId} not found`);
      return null;
    }

    // Retrieve the response options for the specified language
    const options = question.response_options?.[language]?.options || [];

    // Initialize a count object for each response option
    const answerCounts = options.reduce((acc, option) => {
      acc[option] = 0;
      return acc;
    }, {});

    // Iterate through survey responses to count answers
    surveyResponses.forEach((response) => {

      console.log("responseInner"+JSON.stringify(response.answers.answer));
      console.log("responseMain"+JSON.stringify(response));
      console.log("response,likert"+response.likert);

      let ArryaRes = [];
      response.answers.forEach(answer => {
        if (typeof answer.answer === "string") {
          console.log(`Question ID: ${answer.questionId}, Answer: ${answer.answer}`);
        } else if (typeof answer.answer === "object") {
          console.log(`Question ID: ${answer.questionId}, Detailed Answers:`);
          for (const [emotion, response] of Object.entries(answer.answer)) {
            console.log(`  ${emotion}: ${response}`);

            ArryaRes.push(answer.answer);
          }
         
        }
      });


      console.log("ArryaRes"+JSON.stringify(ArryaRes));
     
      const databaseResponses = [
        {
          Angry: "Sometimes",
          Confused: "Sometimes",
          Embarassed: "Sometimes",
          Excited: "Almost Always",
          Happy: "Almost Always",
          Jealous: "Sometimes",
          Lonely: "Sometimes",
          Loved: "Almost Always",
          Sad: "Sometimes",
          Worried: "Sometimes",
        },
        {
          Angry: "Often",
          Confused: "Sometimes",
          Embarassed: "Almost Always",
          Excited: "Often",
          Happy: "Sometimes",
          Jealous: "Sometimes",
          Lonely: "Almost Never",
          Loved: "Often",
          Sad: "Almost Never",
          Worried: "Often",
        },
        {
          Angry: "Often",
          Confused: "Sometimes",
          Embarassed: "Almost Always",
          Excited: "Often",
          Happy: "Sometimes",
          Jealous: "Sometimes",
          Lonely: "Almost Never",
          Loved: "Often",
          Sad: "Almost Never",
          Worried: "Often",
        },
        // Add more responses as needed
      ];

      answerCountsss = this.calculateAnswerCounts(ArryaRes);
      console.log("answerCounts", answerCountsss);

      const answerEntry = response.answers.find(
        (answer) => answer.questionId === questionId
      );

      if (answerEntry && answerEntry.answer) {
        const { answer } = answerEntry;

        if (typeof answer === "string") {
          // Single-answer case
          if (answerCounts[answer] !== undefined) {
            answerCounts[answer] += 1;
          } else {
            // Handle answers not listed in the response options
            answerCounts[answer] = (answerCounts[answer] || 0) + 1;
          }
        } else if (typeof answer === "object") {
          // Multi-answer case
          Object.values(answer).forEach((nestedAnswer) => {
            if (answerCounts[nestedAnswer] !== undefined) {
              answerCounts[nestedAnswer] += 1;
            } else {
              // Handle nested answers not listed in the response options
              answerCounts[nestedAnswer] =
                (answerCounts[nestedAnswer] || 0) + 1;
            }
          });
        }
      }
    });

    return {
      question: question.question?.[language] || "Question not found",
      answers: answerCounts,
      title: question.title || "",
      type: question.response_type,
      likert: answerCountsss,
    };
  }

  calculateAnswerCounts(responses) {
    const answerCounts = {};

    responses.forEach((response) => {
      Object.entries(response).forEach(([emotion, answer]) => {
        if (!answerCounts[emotion]) {
          answerCounts[emotion] = {
            "Almost Never": 0,
            Sometimes: 0,
            Often: 0,
            "Almost Always": 0,
          };
        }

        // Increment the count for the specific answer
        if (answerCounts[emotion][answer] !== undefined) {
          answerCounts[emotion][answer] += 1;
        } else {
          console.warn(`Unexpected answer: ${answer} for emotion: ${emotion}`);
        }
      });
    });

    return answerCounts;
  }

  render() {
    const currentQuestion = this.questions[this.currentQuestionIndex];
    const questionId = currentQuestion.id;
    const statistics = this.getAnswerStatistics(
      this.responsesData,
      this.questions,
      questionId,
      "en"
    );
    this.chartData = statistics;

    console.log("this.chartData"+JSON.stringify(this.chartData,2,null));

    return html`
      <custom-alert
        type="error"
        message="An error occurred while saving your data."
      ></custom-alert>
      <page-loader></page-loader>
      <div class="header">
        <div class="buttons">
          <!-- <button @click="${this.downloadImage}">
            Download Report as Image
          </button>
          <button @click="${this
            .copyToClipboard}">Copy to Clipboard</button> -->
          <!-- "Back" button - hides at the first question -->

          <button class="button" @click="${this.copyToClipboard}">
            Copy Image to Clipboard
          </button>
          <button class="button" @click="${this.downloadImage}">
            Export to PNG
          </button>
          <button class="button" @click="${this.generateReport}">
            Generate Report
          </button>
        </div>
      </div>

      <div id="padded-container">
        <div id="report-section">
          <!-- <div class="report-detail">${this.result}</div> -->
          <div class="report-header">Report of: ${this.surveyName}</div>
          <!-- <div class="report-detail"><b>Result:</b> ${this.result}</div>

          <div class="report-header">Report: ${this.reportName}</div>
          <div class="report-detail"><b>Type:</b> ${this.reportType}</div> -->

          <table>
            <tr>
              <th>Organization</th>
              <th>Survey Name</th>
              <th>Survey Date</th>
            </tr>
            <tr>
              <td>${this.organization}</td>
              <td>${this.surveyName}</td>
              <td>${this.surveyDate}</td>
            </tr>
            <tr>
              <th>Number of Participants</th>
              <th>Participant Grade Range</th>
              <th>Median Participant Age</th>
            </tr>
            <tr>
              <td>${this.numberOfParticipants}</td>
              <td>${this.ageGroup}</td>
              <td>${this.scope}</td>
            </tr>
            <tr>
              <td class="outcome">Outcome</td>
              <td class="conclusion">Conclusion</td>
              <td></td>
            </tr>

            <tr>
              <td class="outcome">${this.outcome}</td>
              <td class="conclusion">${this.conclusion}</td>
              <td class="chartmap">
                <div class="chart">
                  <p>${currentQuestion.outcome}</p>
                  <p>
                    ${currentQuestion.response_type == "likert"
                      ? currentQuestion.title.en
                      : currentQuestion.question.en}
                  </p>

                  <!-- <p>${currentQuestion.value}% Yes</p> -->
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="chart">
          <p>${currentQuestion.outcome}</p>
          <p>
            ${currentQuestion.response_type == "likert"
              ? currentQuestion.title.en
              : currentQuestion.question.en}
          </p>
          <canvas id="chartCanvas"></canvas>
          <canvas id="emotionChart"></canvas>

          <!-- <p>${currentQuestion.value}% Yes</p> -->
        </div>
      </div>
      <div
        style="
    display: block;
    padding: 30px;
    margin: 10px;
"
      >
        ${this.currentQuestionIndex > 0
          ? html`<button class="button back" @click="${this.previousQuestion}">
              Back
            </button>`
          : ""}

        <!-- "Next" button - hides at the last question -->
        ${this.currentQuestionIndex < this.questions.length - 1
          ? html`<button class="button next" @click="${this.nextQuestion}">
              Next
            </button>`
          : ""}
      </div>
    `;
  }

  updated() {
    this.renderChart();
  }


  renderChart() {
    // Get the canvas element
    const canvas = this.shadowRoot.getElementById("chartCanvas");
    const ctx = canvas.getContext("2d");

    // Clear any existing chart instance to avoid duplicates
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }

    if (this.chartData.type === "likert") {
     
      const questionLabels = this.chartData.question; // The emotion questions (e.g., "Happy", "Sad", etc.)
      const answerLabels = Object.keys(this.chartData.likert); // The Likert scale options (e.g., "Almost Never", "Sometimes", etc.)
      const answerCounts = Object.values(this.chartData.likert); // The counts for each answer option (e.g., 4, 5, etc.)
      const likerData = this.chartData.likert;
      // console.log("answerLabels"+likerData);
     //console.log("answerCounts"+answerCounts);

      console.log("answerCounts",JSON.stringify(likerData, null, 2));
      console.log("questionLabels",JSON.stringify(this.chartData.answer, null, 2));

      // // Prepare the dataset dynamically
      // const datasets = answerLabels.map((label, index) => {
      //   return {
      //     label: label, // The label for each category (e.g., "Almost Never", "Sometimes")
      //     data: questionLabels.map(() => answerCounts[index] || 0), // Assign the same count for each emotion question
      //     backgroundColor: getColorForLabel(label), // Get dynamic colors for categories
      //   };
      // });

      // Utility function to assign colors dynamically
      // function getColorForLabel(label) {
      //   const colors = {
      //     "Almost Never": "#FF9999",
      //     Sometimes: "#FFCC99",
      //     Often: "#99CCFF",
      //     "Almost Always": "#CC99FF",
      //   };
      //   return colors[label] || "#CCCCCC"; // Default color if label not found
      // }

      // const dataValue = {
      //   labels: questionLabels, // Emotion questions (e.g., "Happy", "Sad", etc.)
      //   datasets: datasets, // The dataset for the chart
      // };

      // Example Chart.js configuration
      // const config = {
      //   type: "bar", // You can change this to other types (e.g., "horizontalBar", "pie") as needed
      //   data: dataValue,
      //   options: {
      //     responsive: true,
      //     plugins: {
      //       legend: {
      //         position: "top",
      //       },
      //       title: {
      //         display: true,
      //         text: this.chartData.title.en || "Emotion Responses", // Use the title or a default
      //       },
      //     },
      //     scales: {
      //       x: {
      //         stacked: true, // Make the x-axis stacked for a grouped chart
      //       },
      //       y: {
      //         stacked: true, // Make the y-axis stacked for a grouped chart
      //       },
      //     },
      //   },
      // };

      // Create the chart
      // if (this.chartInstance) {
      //   this.chartInstance.destroy(); // Destroy previous instance if it exists
      // }
      // this.chartInstance = new Chart(ctx, config);


      // Prepare the data for the chart
      const emotions = Object.keys(this.chartData.likert); // Get the emotions (e.g., "Angry", "Confused", etc.)
      const responseTypes = ["Almost Never", "Sometimes", "Often", "Almost Always"]; // Likert scale options

      // Prepare the datasets for Chart.js
      // const datasets = responseTypes.map((responseType) => {
      //   return {
      //     label: responseType, // e.g., "Almost Never"
      //     data: emotions.map((emotion) => answerCounts[emotion][responseType]), // Get count for each emotion
      //     backgroundColor: generateRandomColor(),
      //   };
      // });

      const datasets = responseTypes.map((responseType) => {
        return {
          label: responseType, // e.g., "Almost Never"
          data: emotions.map((emotion) => {
            // Safe check for missing values
            return likerData[emotion] && likerData[emotion][responseType] !== undefined
              ? likerData[emotion][responseType]
              : 0; // Default to 0 if undefined
          }),
          backgroundColor: generateRandomColor(),
        };
      });


      console.log("emotions",JSON.stringify(emotions, null, 2));

       function generateRandomColor(label) {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      const config = {
        type: 'bar', // Bar chart type
        data: {
          labels: emotions, // Emotions (x-axis labels)
          datasets: datasets, // Likert scale data (y-axis values)
        },
        options: {
          responsive: true,
          scales: {
            x: {
              // Rotate the labels to ensure they fit
              ticks: {
                autoSkip: false,  // Prevent auto-skip
                maxRotation: 45,  // Maximum rotation for labels
                minRotation: 45,  // Minimum rotation for labels
              },
            },
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.raw + " responses"; // Display count in tooltip
                },
              },
            },
          },
        },
      }


      

      this.chartInstance = new Chart(ctx, config);

    //   const myChart = new Chart(ctx, {
    //     type: 'bar', // Bar chart type
    //     data: {
    //       labels: emotions, // Emotions (x-axis labels)
    //       datasets: datasets, // Likert scale data (y-axis values)
    //     },
    //     options: {
    //       responsive: true,
    //       scales: {
    //         y: {
    //           beginAtZero: true,
    //         },
    //       },
    //       plugins: {
    //         legend: {
    //           position: 'top',
    //         },
    //         tooltip: {
    //           callbacks: {
    //             label: function (tooltipItem) {
    //               return tooltipItem.raw + " responses"; // Display count in tooltip
    //             },
    //           },
    //         },
    //       },
    //     },
    //   });
    // });





    } else {
      // Non-likert chart data and configuration
      const labels = Object.keys(this.chartData.answers);
      const data = Object.values(this.chartData.answers);

      const config = {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label:
                this.chartData.type === "likert"
                  ? this.chartData.title
                  : this.chartData.question,
              data: data,
              backgroundColor: [
                "#4BC0C0",
                "#36A2EB",
                "#FFCE56",
                "#FF6384",
                "#4BC0C0",
                "#9966FF",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
            tooltip: {
              enabled: true,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
            x: {
              ticks: {
                maxRotation: 45,
                minRotation: 0,
              },
            },
          },
        },
      };

      // Create the non-likert chart
      this.chartInstance = new Chart(ctx, config);
    }
  }

  async previousQuestion() {
    if (this.currentQuestionIndex > 0) {
      const pageLoader = this.shadowRoot.querySelector("page-loader");
      pageLoader.loadShowPage(); // Call the loadPage method in the PageLoader component
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulate delay
      pageLoader.loadHidePage();
      this.currentQuestionIndex--;
    }
  }

  async nextQuestion() {
    if (this.currentQuestionIndex < this.questions.length - 1) {
      const pageLoader = this.shadowRoot.querySelector("page-loader");
      pageLoader.loadShowPage(); // Call the loadPage method in the PageLoader component
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulate delay
      pageLoader.loadHidePage();

      this.currentQuestionIndex++;
    }
  }
}

customElements.define("report-view", ReportView);
