import { LitElement, html } from 'lit';
import { passwordStyles } from './dp-password-css';

export class DpPassword extends LitElement {
  static get styles() {
    return [ passwordStyles ];
  }

  static get properties() {
    return {
      id: {type: String},
      minLength: {type: String},
      required: {type: Boolean},
      title:{type:String}
    };
  }

  constructor() {
    super();
    this.id = '';
    this.minLength = '';
    this.required = 'false';
    this.title = '';
  }

  render() {
    return html`
       

         <div class="block-group">
      <label for="${this.id}" class="title">${this.title}</label>
       <input
            class="password-box"
            type="password"
            id=${this.id}
            minLength=${this.minLength}
            ?required=${this.required}
        >            
        </input>
      </div>


    `;
  }
}

customElements.define('dp-password', DpPassword);
