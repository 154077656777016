import {LitElement, html} from 'lit';
import {checkboxStyles} from './dp-checkbox-css.js';

export class DpCheckbox extends LitElement {
  static get styles() {
    return [checkboxStyles];
  }

  static get properties() {
    return {
      title: {type: String},
      options: {type: Array},
    };
  }

  constructor() {
    super();
    this.options = [];
  }

  render() {
    return html`
      <div class="checkbox-group block-group">
        <span class="form-label title">${this.title}</span>
        ${this.options.map((option) => html`
          <label class="checklabel">
            <input 
              type="checkbox" 
              name="${option}" 
              value="${option}" 
              class="hidden-checkbox">
            <span class="custom-checkbox"></span>
            ${option}
          </label>
        `)}
      </div>
    `;
  }
}

customElements.define('dp-checkbox', DpCheckbox);
