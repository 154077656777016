import { LitElement, html, css } from 'lit';
import { collection, query, where, getDocs, doc, setDoc,getDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('survey-publish-component')
export class SurveyPublishComponent extends LitElement {
 
 @property({ type: String }) surveyId = '';
  @property({ type: String }) surveyTitle = '';
  @property({ type: String }) inviteCode = '';


  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      max-width: 900px;
      margin: 0 auto;
      padding: 16px;
      font-family: Arial, sans-serif;
    }

    .invite-container {
      display: flex;
      flex-direction: column;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 16px;
      margin-top: 24px;
    }
    .invite-container input {
      margin-bottom: 8px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
      box-sizing: border-box;
    }
    .invite-container button {
      background-color: rgb(142, 73, 83);
      color: white;
      border: none;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    .invite-container button:hover {
      background-color: rgb(112, 63, 70);
    }
 .survey-container {
      padding: 20px;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      text-align: center;
    }
    .button {
      background-color: rgb(142, 73, 83);
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    .button:hover {
      background-color: rgb(112, 63, 70);
    }
    .invite-code {
      margin-top: 10px;
      font-weight: bold;
    }
    

    
  `;

  constructor() {
    super();

  }

   copyToClipboard() {
    const link = this.getSurveyLink();
    console.log("this.inviteCode"+this.inviteCode);
    navigator.clipboard.writeText(this.inviteCode)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  }

  copyLinkToClipboard() {
    const link = this.getSurveyLink();
    console.log("this.inviteCode"+this.inviteCode);
    navigator.clipboard.writeText(link)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  }

  getSurveyLink() {
    return `${window.location.origin}/complete/${this.inviteCode}`; // Adjust as necessary
  }

  copyInviteCode() {
    navigator.clipboard.writeText(this.inviteCode)
      .then(() => {
        alert('Invite code copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy invite code: ', err);
      });
  }



  async connectedCallback() {
    super.connectedCallback();
    const urlParams = new URLSearchParams(window.location.search);
    this.surveyId = urlParams.get('id');

    if (this.surveyId) {
      await this.loadSurveyData();
    } else {
      console.warn('No survey ID found in URL');
    }

  }

  // async loadSurveyData(surveyId) {
  //   // Implement logic to load survey data based on the surveyId
  //   console.log(`Loading survey data for ID: ${surveyId}`);
  //   // Add your Firebase fetch logic here
  // }

  generateInviteCode() {
    // You can create a more complex logic if needed
    return `SURVEY-${this.surveyId}`; // Example invite code
}

async loadSurveyData() {
  // Fetch survey data from Firestore
  const surveyDocRef = doc(db, 'surveys', this.surveyId); // Get a reference to the document
  const surveyDoc = await getDoc(surveyDocRef); // Fetch the document

  if (surveyDoc.exists()) {
    const data = surveyDoc.data();
    this.surveyTitle = data.title || 'Untitled Survey';
    this.inviteCode = data.invitation_code || 'No code available';
  } else {
    console.error('No such document!');
  }
}



  render() {
    return html`
      <div class="container">
        <h2>Publish Survey</h2>
       <div class="survey-container">
        <h2>${this.surveyTitle}</h2>
        <button class="button" @click="${() => window.open(this.getSurveyLink(), '_blank')}">View Survey</button>
        <div class="invite-code">
          
          <button @click="${this.copyToClipboard}">Copy Invite Code </button>
           <button @click="${this.copyLinkToClipboard}">Copy Survey Link</button>
        </div>
      </div>
      </div>
    `;
  }
}