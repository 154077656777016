import { css } from 'lit';

export const baseStyles = css`
    :root {
        --dp-rose: #EFB0A1;
        --dp-pink: #F4AFB4;
        --dp-beige: #C9B7AD;
        --dp-sage: #94A89A;
        --dp-grey: #797D81;
    }
    body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
  }


`;
