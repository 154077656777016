// footer-component.js
import { LitElement, html, css } from "lit";

export class FooterComponent extends LitElement {
  static styles = css`
    footer {
      text-align: right;
     
    }
    .help,
    .privacy,
    .terms {
      padding:1em;
      cursor: pointer;
      text-align:right;
      color :#755b00;
      font-family :inter;
      font-size:16px;
      line-height:14px

    }
      .main-form {
      width:516px;
      height:276px;}
  `;

  help() {
    // Add your login logic here
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/help" },
        bubbles: true,
        composed: true,
      })
    );
  }

  privacy() {
    // Add your login logic here
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/privacy" },
        bubbles: true,
        composed: true,
      })
    );
  }

  terms() {
    // Add your login logic here
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/terms" },
        bubbles: true,
        composed: true,
      })
    );
  }

  render() {
    return html`
      <footer>
        <span class="help" @click=${this.help}>Help</span>
        <span class="privacy" @click=${this.privacy}>Privacy</span>
        <span class="terms" @click=${this.terms}>Terms</span>
      
        
      </footer>
    `;
  }
}

customElements.define("footer-component", FooterComponent);
