import { LitElement, html, css } from 'lit';
import { baseStyles } from '../../styles/base-styles.js';
import { navBarStyles } from "./nav-bar-styles.js";

const defaultProfileIcon = new URL('../assets/profile-circle.svg', import.meta.url).href;

export class Navbar extends LitElement {
    static properties = {
        links: { type: Object },
        appSuiteHeader: { type: String },
        profileImageUrl: { type: String },
        menuOpen: { type: Boolean }
    };

    static styles = [
        baseStyles,
        navBarStyles
    ];

    constructor() {
        super();
        this.menuOpen = false;
        this.collapsed = true;
        this.handleSidebarToggle = this.handleSidebarToggle.bind(this);

        // Handle if we were not passed a profile image icon
        if (!this.profileImageUrl) {
            this.profileImageUrl = defaultProfileIcon;
        }

        // Handle if we were not passed in links
        if (!this.links) {
            this.links = [];
        }
    }

    toggleSidebar() {
        this.collapsed = !this.collapsed;
        window.dispatchEvent(new CustomEvent('sidebar-toggle', { detail: this.collapsed }));
    }

    connectedCallback() {
        super.connectedCallback();
        // Add event listener
        window.addEventListener('sidebar-toggle', this.handleSidebarToggle);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('sidebar-toggle', this.handleSidebarToggle);
    }    

    handleSidebarToggle(event) {
        // Update internal state based on the event, but avoid re-dispatching
        if (this.collapsed !== event.detail) {
            this.collapsed = event.detail;
        }
    }

    render() {
        return html`
            <nav class="navbar">
                <span @click="${this.toggleSidebar}" class="hamburger">☰</span>
                <span class="app-suite-name" id="app-suite-header">${this.appSuiteHeader}</span>
                <ul class="nav-links">
                    ${Object.entries(this.links).map(([key, url]) =>
                        html`<li><a data-route=${url} href=${url} class="nav-bar-link">${key}</a></li>`
                    )}
                </ul>
                <div class="profile-icon" style="background-image: url(${this.profileImageUrl})"
                    @click="${() => this.menuOpen = true}" 
                    @mouseenter="${() => this.menuOpen = true}" 
                    @mousedown="${() => this.menuOpen = true}"
                    @mouseleave="${() => this.menuOpen = false}"></div>
                <div class="${this.menuOpen ? 'popup-menu menu-open' : 'popup-menu'}">
                    <a href="/account">My Account</a>
                    <a href="/logout">Logout</a>
                </div>
            </nav>
        `;
    }
}

customElements.define('q1-navbar', Navbar);
