import { css } from 'lit';

export const sidebarStyles = css`
  aside {
    background: #ffffff;
  }

  /* Suite Navigation */
  .navigation {
    width: 15%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    transition: transform 0.15s ease-in-out;
    padding: 10px;
    box-sizing: border-box;
  }

  aside[collapsed] {
    transform: translateX(-100%);
  }

  /* Header */
  .header {
    position: absolute;
    left: 0%;
    right: -0.38%;
    top: 0%;
    bottom: 93.36%;
    background: #D9D9D9;
  }

  /* Q1 App Suite */
  .title {
    position: absolute;
    left: 0.38%;
    right: -0.38%;
    top: 0.88%;
    bottom: 94.14%;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }

  .links {
    margin-top: 20px;
  }

  .links a {
    display: block;
    color: #333;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .links a:hover {
    text-decoration: underline;
  }

  .links a.active {
    font-weight: bold; /* make it bold */
  }

  /* Hide the navbar content from the sidebar when we are not in mobile */
  @media (min-width: 600px) {
    .navbar {
      display: none;
    }

    .app-suite-name a {
      color: #000000;
      text-decoration: none;
    }
  }

  @media (max-width: 600px) {
    /* Hide the app suite name from the side bar if we are on mobile */
    .app-suite-name {
      display: none;
    }

    .navigation {
      margin-top: 52px;
      position: fixed;
      z-index: 100;
      width: 60%;
      transform: translateX(0);
      transition: transform 0.15s ease-in-out;
    }

    aside[collapsed] {
      transform: translateX(-100%);
    }

    .nav-links {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .nav-links a {
      text-decoration: none;
      color: black; /* Adjust the color as needed */
    }

    .nav-links a:hover {
      text-decoration: underline;
    }
  }
`;
