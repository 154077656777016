import { css } from "lit";

export const iconStyles = css`
    /* Set up the md-icons */
    md-icon {
        font-family: 'Material Icons', 'Material Icons';
    }

    md-icon[type="rounded"] {
        font-family: 'Material Icons Rounded', 'Material Icons';
    }

    md-icon[type="outlined"] {
        font-family: 'Material Icons Outlined', 'Material Icons', sans-serif;
    }

    md-icon[type="sharp"] {
        font-family: 'Material Icons Sharp', 'Material Icons', sans-serif;
    }

    md-icon[type="fill"] {
        font-variation-settings: 'FILL' var(--fill-level, 1);
    } 

`;