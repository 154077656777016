import { LitElement, html } from 'lit';
import { baseStyles } from '../../styles/base-styles.js';
import { sidebarStyles } from './sidebar-styles.js';

export class Sidebar extends LitElement {
    static properties = {
        collapsed: { type: Boolean },
        links: { type: Object },
        appSuiteHeader: { type: String },
        internalLinks: { type: Object },
        externalLinks: { type: Object },
    };

    static styles = [
        baseStyles,
        sidebarStyles
    ];

    constructor(internalLinks) {
        super();
        this.collapsed = true;
        this.handleSidebarToggle = this.handleSidebarToggle.bind(this);

        // Handle if we were not passed in links
        if (!this.links) {
            this.links = [];
        }

        if (!this.internalLinks) {
            this.internalLinks = internalLinks;
        }

        if (!this.externalLinks) {
            this.externalLinks = [];
        }
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('sidebar-toggle', this.handleSidebarToggle);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('sidebar-toggle', this.handleSidebarToggle);
    }

    handleSidebarToggle(event) {
        const asideElement = this.shadowRoot.getElementById("navigation-aside");
        asideElement.toggleAttribute('collapsed', event.detail);
        this.toggleAttribute('collapsed', event.detail);
    }

    handleLinkClick(event) {
        const routePath = event.target.closest('[data-route]').getAttribute('data-route');
        if (routePath) {
            event.preventDefault();
            this.dispatchEvent(new CustomEvent('navigate-to-route', {
                detail: { routePath },
                bubbles: true, // Allows the event to bubble up through the DOM
                composed: true // Allows the event to cross the shadow DOM boundary
            }));
        }
    }

    render() {
        return html`
            <div>
                <aside id="navigation-aside" class="navigation" collapsed>
                    <h2 class="app-suite-name" id="app-suite-header"><a data-route="/" href="/">${this.appSuiteHeader}</a></h2>

                    <nav class="navbar">
                        <ul class="nav-links">
                            ${Object.entries(this.links).map(([key, url]) =>
                                html`<li><a href=${url} class="nav-bar-link">${key}</a></li>`
                            )}
                        </ul>
                    </nav>

                    <div class="links internal-links">
                        <h3>Internal Links</h3>
                        ${Object.entries(this.internalLinks).filter(([route, routeInfo]) => route !== '*').map(([route, routeInfo]) =>
                            html`<a href=${route} data-route="${route}" class="${routeInfo.active ? 'active' : ''}"  @click="${this.handleLinkClick}">${routeInfo.title}</a>`
                        )}
                    </div>

                    <div class="links external-links">
                        <h3>External Links</h3>
                        ${Object.entries(this.externalLinks).map(([key, url]) =>
                            html`<a href=${url} target="_blank">${key}</a>`
                        )}
                    </div>
                </aside>
            </div>
        `;
    }
}

customElements.define('q1-sidebar', Sidebar);
