import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('ranking-component')
export class RankingComponent extends LitElement {
  @property({ type: String }) label = ''; // Question label
  @property({ type: Array }) activities = []; // List of options
  @state() selectedValues = []; // Holds selected options
  @state() errorMessage = ''; // Holds validation error message

  // Validate the ranking input
  validate() {
    if (this.selectedValues.length < this.activities.length) {
      this.errorMessage = 'Please rank all activities.';
    } else {
      this.errorMessage = ''; // No errors
    }
    this.requestUpdate(); // Re-render to show validation message
    this.dispatchValidationEvent(); // Dispatch validation with all data
    return this.errorMessage === ''; // Return true if valid
  }

  // Dispatch validation event with all data
  dispatchValidationEvent() {
    const event = new CustomEvent('validation-changed', {
      detail: {
        valid: this.errorMessage === '',
        label: this.label,
        activities: this.activities,
        selectedValues: this.selectedValues,
      },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  // Handle selection
  handleSelection(index, value ,activity) {
    // this.selectedValues =[activity] = value;
  this.selectedValues[index] = {title:activity,value : value };
  
    this.validate(); // Re-validate on every change
  }

  render() {
    return html`
      <div class="ranking-group">
        <label>${this.label}</label>
        <div>
          ${this.activities.map(
            (activity, index) => html`
              <div>
                <span>${activity}</span>
                <select
                  @change="${(e) =>
                    this.handleSelection(index, e.target.value,activity)}"
                >
                  <option value="">Select rank</option>
                  ${Array.from({ length: this.activities.length }, (_, i) => i + 1).map(
                    (rank) => html`<option activity="${activity}" value="${rank}">${rank}</option>`
                  )}
                </select>
              </div>
            `
          )}
        </div>
        <div class="validation-message">
          ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}
        </div>
      </div>
    `;
  }
}
