import { LitElement, html } from 'lit';
import { textFieldStyles } from './text-field-styles.js';
import { baseStyles } from "../../styles/base-styles.js";

import '@material/web/textfield/filled-text-field.js';
import '@material/web/textfield/outlined-text-field.js';

export class TextField extends LitElement {
    static properties = {
        idFor: { type: String },
        value: { type: String },
        type: { type: String },
        disabled: { type: Boolean },
        inputType: { type: String },
        label: { type: String },
        supportingText: { type: String },
        prefixText: { type: String },
        suffixText: { type: String },
        maxLength: { type: Number },
        pattern: { type: String },
        
        iconName: { type: String },
        selectedIconName: { type: String },
        iconTrailing: { type: Boolean },

        rows: { type: Number } // Only for TextArea
    };

    static styles = [
        baseStyles,
        textFieldStyles
    ];

    constructor() {
        super();
        this.type = 'outlined'; // Default type
        this.disabled = false;
        this.inputType = 'Text'; // Default inputType
        this.label = '';
        this.icon = '';
        this.maxLength = -1;
        this.rows = 3; // Default for TextArea
    }

    /**
     * 
     * @returns {"text" | "number" | "url" | "email" | "password" | "search" | "tel" | "text" | "textarea" | "color" | "date" | "datetime-local" | "file" | "month" | "time" | "week"}
     */
    safeInputType() {
        const allowedTypes = ["number", "url", "email", "password", "search", "tel", "text", "textarea", "color", "date", "datetime-local", "file", "month", "time", "week"];
        return allowedTypes.includes(this.inputType) ? this.inputType : 'text';
    }

    renderIcon() {
        if (!this.iconName) {
            return ''; // No icon to render
        }

        const iconSlot = this.iconTrailing ? 'trailing-icon' : 'leading-icon';

        // If there's a different icon when selected...
        if (this.selectedIconName) {
            return html`
                <q1-icon-button toggle slot="${iconSlot}" icon="${this.iconName}" toggledIcon="${this.selectedIconName}">
                </q1-icon-button>
            `;
        } else {
            return html`<q1-icon slot="${iconSlot}">${this.iconName}</q1-icon>`;
        }
    }

    // TODO text area should not have icons or prefix/suffix
    renderHelper() {
        return this.type === 'filled' ?
            html`
        <md-filled-text-field id="${this.idFor}"
                            label="${this.label}"
                            ?disabled=${this.disabled}
                            supporting-text="${this.supportingText}"
                            prefix-text="${this.prefixText}"
                            suffix-text="${this.suffixText}"
                            maxlength="${this.maxLength}"
                            pattern="${this.pattern}"
                            type="${this.safeInputType()}"
                            value="${this.value}"
                            >${this.renderIcon()}</md-filled-text-field>
            ` :
            html`
        <md-outlined-text-field id="${this.idFor}"
                            label="${this.label}"
                            ?disabled=${this.disabled}
                            supporting-text="${this.supportingText}"
                            prefix-text="${this.prefixText}"
                            suffix-text="${this.suffixText}"
                            maxlength="${this.maxLength}"
                            pattern="${this.pattern}"
                            type="${this.safeInputType()}"
                            value="${this.value}"
                            >${this.renderIcon()}</md-outlined-text-field>
            `
    }

    render() {
        return html`<div class="q1-button-container">${this.renderHelper()}</div>`;
    }
}

customElements.define('q1-text-field', TextField);
