import { LitElement, html } from "lit";
import { baseStyles } from "../../styles/base-styles";
import { menuStyles } from "./menu-styles";

import '@material/web/menu/menu'
import '@material/web/menu/menu-item'

export class Menu extends LitElement {
    static properties = {
        anchor: {type: String},
        open: {type: Boolean},
        items: {type: Object}
    };

    static styles = [
      baseStyles,
      menuStyles
    ]

    constructor() {
        super();
        this.anchor = '';
        this.open = false;
        this.items = {};
    }

    _handleClick(e) {
      if(e.target.id){
        const menuElement = document.querySelector(`[anchor = ${e.target.id}]`);
        menuElement.toggleAttribute('open', !menuElement.open);
      }

    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('click', this._handleClick);
      }

      disconnectedCallback() {
        window.removeEventListener('click', this._handleClick);
        super.disconnectedCallback();
      }

    render() {
        return html`
            <span style="position: relative">
              <md-menu 
                anchor=${this.anchor} 
                ?open=${this.open}
                @click=${(e) =>this._handleClick(e)}
              >
                ${Object.entries(this.items).map(([key, value]) => {
                  return html`
                    <md-menu-item
                      ?disabled=${value.disabled === 'true' ? true: false} 
                      href=${value.href}
                      target=${value.target}
                    >
                      <div slot="headline">
                        ${key}                        
                      </div>
                    </md-menu-item>
                  `
                })}
              </md-menu>
            </span>
        `
    }

    createRenderRoot(){
      return this;
  }
}

customElements.define('q1-menu', Menu);