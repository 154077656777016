import { LitElement, html, css } from 'lit';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup ,signOut } from 'firebase/auth';
import { auth } from "../../firebase-config";
import {baseStyles} from '../../styles/base-styles';
import {containerStyles} from '../../components/container/container-styles';

export class AuthView extends LitElement {
  static styles = css`
     :host {
      display: block;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
    }
        div.main {display:block;width:100%;max-width:600px;margin:0px auto;}
  `;

  static properties = {
    user: { type: Object },
  };

  static styles = [
    baseStyles,
    containerStyles
  ];


  constructor() {
    super();
    this.user = null;

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.requestUpdate();
      } else {
        this.user = null;
      }
    });


  }

  render() {
    return html`
        ${this.user ? this._renderHome() : this._renderLanding()}
    `;
  }

  _renderHome() {
    return html`<survey-dashboard-component .user=${this.user}></survey-dashboard-component>`;
  }


  _renderLogin() {
    return html`
  
     <login-component></login-component>
  
    `;
  }

  _renderLanding() {
    return html`
    
 
<landing-component></landing-component>


    
    `;
  }
  
  
  _login() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(this.auth, provider).catch((error) => {
      console.error("Error during login:", error);
    });
  }

  _logout() {
    signOut(this.auth).catch((error) => {
      localStorage.clear();
      console.error("Error during logout:", error);
    });
  }

}

customElements.define('auth-view', AuthView);