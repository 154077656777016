import { LitElement, html, css } from 'lit';

export class SurveyReport extends LitElement {
  static styles = css`
    :host {
      display: block;
      font-family: Arial, sans-serif;
      color: #333;
    }
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      padding: 24px;
      background-color: #f9f9f9;
      border-radius: 12px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    .header {
      grid-column: span 3;
      text-align: left;
      margin-bottom: 16px;
      font-size: 1.5em;
      font-weight: bold;
    }
    .cell {
      padding: 16px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }
    .title {
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .chart {
      margin-top: 16px;
      text-align: center;
    }
    .chart-bar {
      background-color: #f3c1c6;
      width: 80%;
      margin: 4px auto;
      height: 16px;
      border-radius: 4px;
    }
    .chart-bar[data-percentage='20'] {
      width: 20%;
    }
    .chart-bar[data-percentage='25'] {
      width: 25%;
    }
    .chart-bar[data-percentage='32'] {
      width: 32%;
    }
    .chart-bar[data-percentage='35'] {
      width: 35%;
    }
    .chart-bar[data-percentage='42'] {
      width: 42%;
    }
    .chart-bar[data-percentage='50'] {
      width: 50%;
    }
  `;

  render() {
    return html`
      <div class="container">
        <div class="header">Connect Every Day - First Year Findings</div>
        
        <!-- Left column -->
        <div class="cell">
          <div class="title">Details</div>
          <p><strong>Number of Participants:</strong> 32</p>
          <p><strong>Grade Range:</strong> 2-10</p>
          <p><strong>Median Age:</strong> 14</p>
        </div>
        
        <!-- Middle column -->
        <div class="cell">
          <div class="title">Key Findings</div>
          <ul>
            <li>
              <strong>Engagement and Satisfaction:</strong> Most participants found activities engaging.
            </li>
            <li>
              <strong>Community Building:</strong> Programs fostered a strong sense of belonging.
            </li>
          </ul>
        </div>
        
        <!-- Right column -->
        <div class="cell">
          <div class="title">Math Confidence Growth</div>
          <div class="chart">
            <div class="chart-bar" data-percentage="20">20%</div>
            <div class="chart-bar" data-percentage="25">25%</div>
            <div class="chart-bar" data-percentage="32">32%</div>
            <div class="chart-bar" data-percentage="35">35%</div>
            <div class="chart-bar" data-percentage="42">42%</div>
            <div class="chart-bar" data-percentage="50">50%</div>
          </div>
        </div>
        
        <!-- Conclusion -->
        <div class="cell" style="grid-column: span 3;">
          <div class="title">Conclusion</div>
          <p>
            Our findings show daily activities have fostered confidence and community. 
            More diverse activities in STEM and arts are needed to meet participants' growing interests.
          </p>
        </div>
      </div>
    `;
  }
}

customElements.define('survey-report', SurveyReport);
