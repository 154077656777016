import { LitElement, html, css } from 'lit';
import axios from 'axios';
import '../../components/loadingSpinner/loading-spinner.js'; // Import the spinner component
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'; // Firebase Authentication import

export class ForgotPasswordComponent extends LitElement {
  static styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh;
     
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      max-width: 400px;
      padding: 20px;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }

    h2 {
      margin-bottom: 20px;
    }

    .forgot-password-form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .forgot-password-form input {
      margin-bottom: 15px;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: rgb(240, 222, 223);
    }

    .forgot-password-form button {
      padding: 10px;
      font-size: 16px;
      background-color: #8e4953;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 20px;
      margin-top: 10px;
    }

    .forgot-password-form button:hover {
      background-color: #7c4049;
    }

    .error {
      color: red;
      margin-top: 10px;
    }

    .success {
      color: green;
      margin-top: 10px;
    }

    p.pt20 {
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
    }

    .back-button {
      align-self: flex-start;
      background: none;
      border: none;
      color: #8e4953;
      font-size: 16px;
      cursor: pointer;
      text-decoration: underline;
      margin-bottom: 20px;
    }

    .back-button:hover {
      color: #7c4049;
    }
 .button {
      position: relative;
     
      font-size: 16px;
      cursor: pointer;
    }

    .button[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .loader-container {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    .hidden {
      display: none;
    }
  `;

  static properties = {
    email: { type: String },
    errorMessage: { type: String },
    successMessage: { type: String },
    isLoading: { type: Boolean }
  };

  constructor() {
    super();
    this.email = '';
    this.errorMessage = '';
    this.successMessage = '';
    this.isLoading = false;
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this[name] = value;
    this.email = event.target.value;
  }

  async handleForgotPassword() {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, this.email);
      alert('Password reset email sent! Check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Failed to send reset email. Please try again.');
    }
  }


  async handleSubmit(event) {
    event.preventDefault();
    this.errorMessage = ''; // Clear any previous error message
    this.successMessage = ''; // Clear any previous success message
    this.isLoading = true; // Start loading
    const auth = getAuth();
    try {
      // const response = await axios.post('https://us-central1-datapoint-44397.cloudfunctions.net/api/reset-password', {
      //   email: this.email,
      // });

      // const response =  this.handleForgotPassword();
      await sendPasswordResetEmail(auth, this.email);
      this.successMessage = 'Password reset email sent! Check your inbox';
      this.isLoading = false; // Start loading

      // if (response.status === 200) {
      //   this.successMessage = 'Password reset link sent to your email!';
      //   this.isLoading = false; // Start loading
      //   console.log('Password reset link sent:', response.data);
      // } else {
      //   this.errorMessage = 'Failed to send password reset link.';
      //   this.isLoading = false; // Start loading
      //   console.error('Failed to send password reset link');
      // }
    } catch (error) {
      this.errorMessage = 'An error occurred while sending the password reset link.';
      this.isLoading = false; // Start loading
      console.error('Error:', error);
    }
  }

  navigateBack() {
    this.dispatchEvent(new CustomEvent('navigate-to-route', {
      detail: { routePath: '/login' },
      bubbles: true,
      composed: true,
    }));
  }

  render() {
    return html`
      <div class="main">
        <button class="back-button" @click="${this.navigateBack}">Back to Login</button>
        <p class="pt20">Enter the email associated with your account, and we’ll send you a link to reset your password.</p>
        <form class="forgot-password-form" @submit="${this.handleSubmit}">
          <input
            type="email"
            name="email"
            .value="${this.email}"
            @input="${this.handleInputChange}"
            placeholder="Email"
            required
          />
         
           <button type="submit" class="button" ?disabled="${this.isLoading}">
            ${this.isLoading ? 'Sending...' : 'Submit'}
            <span class="loader-container ${this.isLoading ? '' : 'hidden'}">
              <loading-spinner></loading-spinner>
            </span>
          </button>


          ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}
          ${this.successMessage ? html`<p class="success">${this.successMessage}</p>` : ''}
        </form>
      </div>
    `;
  }
}

customElements.define('forgot-password-component', ForgotPasswordComponent);
