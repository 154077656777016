import { LitElement, html, css } from 'lit';
import { collection, query, where, getDocs, doc, setDoc, getDoc, updateDoc,Timestamp } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { customElement, property, state } from 'lit/decorators.js';
import '../../views/survey-component/survey-summary-component';
@customElement('survey-publish-component')
export class SurveyPublishComponent extends LitElement {



  

  static properties = {
    surveyId: { type: String }, // Survey ID passed as a property
    responses: { type: Array }, // Array to hold fetched responses
    errorMessage: { type: String }, // Error message if the API call fails
  };


  @property({ type: String }) surveyTitle = '';
  @property({ type: String }) inviteCode = '';
  @property({ type: String }) surveyStatus = '';
  @property({ type: Number }) surveyResponsesCount = 0;
  @property({ type: Object, attribute: false })
  dateSurveyIssued = Timestamp.now();
  
  @property({ type: String }) surveyData = '';

  @state() invitationCount = 0;
  @state() surveyDate = '';
  @state() responseCount = 0;
  @state() showPopup = false;
  @state() showPopupCopy = false;
  @state() showPopupChangeStatus = false;
  @state() showStatusPopup = false;


  @property({ type: String }) invitationLink = '';


  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      max-width: 900px;
      margin: 0 auto;
      padding: 16px;
      font-family: Arial, sans-serif;
    }

    .invite-container {
      display: flex;
      flex-direction: column;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 16px;
      margin-top: 24px;
    }
   
   
   
 .survey-container {
      padding: 20px;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      text-align: center;
    }
   
   
    .invite-code {
      margin-top: 10px;
      font-weight: bold;
    }

    .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 500px;
}

.link-input {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}




table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }
    th, td {
      border: 1px solid #ccc;
      padding: 10px;
      text-align: left;
    }
    th {
      background-color: #f4f4f4;
    }
    .table-container {
      margin: 16px 0;
      font-family: Arial, sans-serif;
    }

    .container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.section {
  flex: 1 1 calc(33.333% - 1rem); /* Three sections side by side */
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
}

.section h2 {
  margin-bottom: 0.5rem;
}

.section p {
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section {
    flex: 1 1 calc(50% - 1rem); /* Two sections per row */
  }
}

@media (max-width: 480px) {
  .section {
    flex: 1 1 100%; /* Stack sections vertically */
  }
}

.button {
  margin-top:1%;
  background-color: #8e4953;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  //padding: 1rem 1.6rem;
  padding:0.8rem 1.1rem;
  text-align: center;
  text-decoration: none #FFFFFF solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
    margin-top: 0px;
    -moz-box-shadow: 0px 0px 4px 1px grey;
    -webkit-box-shadow: 0px 0px 4px 1px grey;
    box-shadow: 0px 0px 4px 1px  grey;
}

@media (min-width: 768px) {
  .button {
    font-size: 1rem;
    //padding: 1rem 2rem;
    padding:0.5rem 1rem;
  }
}
    
  `;

  constructor() {
   super();
    this.surveyId = '';
    this.responses = [];
    this.errorMessage = '';

  }

  showSuccessPopup(message) {
    this.popupMessage = message;
    this.showPopup = true;
    setTimeout(() => {
      this.showPopup = false;
      this.requestUpdate();  // Trigger a re-render to hide the popup
    }, 3000);  // Auto-hide after 3 seconds
    this.requestUpdate();  // Trigger a re-render immediately after showing the popup
  }


  // Show the modal
  showInvitePopup() {
    this.showPopup = true;
    this.requestUpdate();
  }

  showStatusSuccessPopup() {
    this.showStatusPopup = true;
    this.requestUpdate();
  }

  // Hide the modal
  hidePopup() {
    this.showPopup = false;
    this.requestUpdate();
  }

  hideStatusPopup() {
    this.showStatusPopup = false;
    this.requestUpdate();
  }



  hidePopupCopy() {
    this.showPopupCopy = false;
    this.requestUpdate();
  }

  copyLinkToClipboard() {
    const link = this.getSurveyLink();
    console.log("this.inviteCode" + this.inviteCode);
    navigator.clipboard.writeText(link)
      .then(() => {


        this.showPopup = false;
        this.showPopupCopy = true;
        this.requestUpdate();

        //alert('Link copied to clipboard!');

      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  }



  getSurveyLink() {
    return `${window.location.origin}/survey/complete/${this.inviteCode}`; // Adjust as necessary
  }

  copyInviteCode() {
    navigator.clipboard.writeText(this.inviteCode)
      .then(() => {
        alert('Invite code copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy invite code: ', err);
      });
  }



  async connectedCallback() {
    super.connectedCallback();
    // const urlParams = new URLSearchParams(window.location.search);
    // this.surveyId = urlParams.get('id');
    const pathSegments = window.location.pathname.split('/');
    this.surveyId = pathSegments[pathSegments.indexOf('publish') + 1]; // Finds the ID after "edit"
    if (this.surveyId) {
      await this.loadSurveyData();
      await this.getPublishSurvey(this.surveyId);

    } else {
      console.warn('No survey ID found in URL');
    }

  }

  // async loadSurveyData(surveyId) {
  //   // Implement logic to load survey data based on the surveyId
  //   console.log(`Loading survey data for ID: ${surveyId}`);
  //   // Add your Firebase fetch logic here
  // }

  generateInviteCode() {
    // You can create a more complex logic if needed
    return `SURVEY-${this.surveyId}`; // Example invite code
  }

  async loadSurveyData() {
    // Fetch survey data from Firestore
    const surveyDocRef = doc(db, 'surveys', this.surveyId); // Get a reference to the document
    const surveyDoc = await getDoc(surveyDocRef); // Fetch the document

    if (surveyDoc.exists()) {
      const data = surveyDoc.data();
      this.surveyTitle = data.title || 'Untitled Survey';
      this.surveyStatus = data.status || '';
      this.dateSurveyIssued = data.publishAt || '';
      this.inviteCode = data.invitation_code || 'No code available';
      this.invitationLink = `${window.location.origin}/survey/complete/${this.inviteCode}`;
      this.requestUpdate();
    } else {
      console.error('No such document!');
    }
  }


  async toggleSurveyStatus(surveyId, currentStatus) {
    const surveyRef = doc(db, 'surveys', surveyId);

    try {
      // Determine the new status
      const newStatus = currentStatus === 'active' ? 'closed' : 'active';
      

      // Update the Firestore document

           // Update the Firestore document

      // createdAt: Timestamp.now(), // Add created date
      // updatedAt: Timestamp.now(),  // Add updated date
      // publishAt:Timestamp.now(), // Add Publish date
      // closedAt:Timestamp.now(), // Add Closed date
      // archivedAt:Timestamp.now(), // Add Closed date
      // draftAt:Timestamp.now(), // Add Draft date

      let dataUpdated = {};

      dataUpdated =  newStatus === 'closed' ?  { status: newStatus,updatedAt:Timestamp.now(),closedAt:Timestamp.now() } :  { status: newStatus,updatedAt:Timestamp.now(),publishAt:Timestamp.now() }
      await updateDoc(surveyRef, dataUpdated);


     // await updateDoc(surveyRef, { status: newStatus });

      console.log(`Survey status updated to ${newStatus}`);

      this.showStatusPopup = true;
      this.requestUpdate();

      // Optionally, show a success popup or update UI
      this.showStatusSuccessPopup(`Survey ${newStatus === 'active' ? 'activated' : 'deactivated'} successfully.`);

      // Refresh the list of surveys to reflect the updated status
      //this.fetchSurveysByOrg(this.selectedOrg, this.activeTab);
    } catch (error) {
      console.error('Error updating survey status:', error);
      this.showStatusSuccessPopup('Error updating survey status. Please try again.');
    }

  }

  async draftSurveyStatus(surveyId, currentStatus) {
    const surveyRef = doc(db, 'surveys', surveyId);

    try {
      // Determine the new status
      const newStatus = currentStatus === 'active' ? 'draft' : 'active';
      

      // Update the Firestore document

      // createdAt: Timestamp.now(), // Add created date
      // updatedAt: Timestamp.now(),  // Add updated date
      // publishAt:Timestamp.now(), // Add Publish date
      // closedAt:Timestamp.now(), // Add Closed date
      // archivedAt:Timestamp.now(), // Add Closed date
      // draftAt:Timestamp.now(), // Add Draft date


      let dataUpdated = {};

      dataUpdated =  newStatus === 'draft' ?  { status: newStatus,updatedAt:Timestamp.now(),draftAt:Timestamp.now() } :  { status: newStatus,updatedAt:Timestamp.now(),publishAt:Timestamp.now() }
      await updateDoc(surveyRef, dataUpdated);


      console.log(`Survey status updated to ${newStatus}`);

      this.showStatusPopup = true;
      this.requestUpdate();

      // Optionally, show a success popup or update UI
      this.showStatusSuccessPopup(`Survey ${newStatus === 'active' ? 'activated' : 'deactivated'} successfully.`);

      // Refresh the list of surveys to reflect the updated status
      //this.fetchSurveysByOrg(this.selectedOrg, this.activeTab);
    } catch (error) {
      console.error('Error updating survey status:', error);
      this.showStatusSuccessPopup('Error updating survey status. Please try again.');
    }

  }




  async getPublishSurvey(surveyId) {
    try {
      // Reference to the 'responses' collection
      const responsesRef = collection(db, 'responses');
  
      // Create a query to filter by 'surveyId'
      const queryRef = query(responsesRef, where('surveyId', '==', surveyId));
  
      // Execute the query and get the documents
      const querySnapshot = await getDocs(queryRef);
  
      // Check if any documents were found
      if (querySnapshot.empty) {
        console.log("No responses found for the given surveyId.");
        this.surveyResponsesCount = 0;
        return null; // Return null if no documents match the query
      }
  
      // Extract and log response data
      const responseData = [];
      querySnapshot.forEach((doc) => {
        responseData.push({ id: doc.id, ...doc.data() });
      });
  
      console.log("Fetched responses for surveyId:", responseData);

      this.surveyResponsesCount = responseData.length;
      this.requestUpdate();
      //return responseData; // Return the array of response data
    } catch (error) {
      console.error("Error fetching responses for surveyId:", error);
      this.surveyResponsesCount = 0;
      throw error; // Rethrow error for further handling
    }
  }

  

  // async getPublishSurvey (surveyId) {

  //   try {
  //     // Extract survey ID from query parameters
  //     if (!surveyId) {
  //       console.error("surveyId not found");
  //       return;
  //     }

  //     // Reference to the 'responses' collection
  //   const responsesRef = collection(db, "responses");




  //   } catch (error) {
  //     console.error("Error fetching responses:", error);
  //     res.status(500).json({
  //       status: "error",
  //       message: "Error fetching responses",
  //       error: error.message,
  //     });
  //   }
  //  }



  async generateNewInvitationLink(surveyId, inviteCode) {

    const surveyRef = doc(db, 'surveys', surveyId);

    try {
      // Determine the new status
      // const newStatus = currentStatus === 'active' ? 'draft' : 'active';

      // Update the Firestore document


      const invitationCode = this.generateUniqueCode();

      await updateDoc(surveyRef, { invitation_code: invitationCode, oldCode: inviteCode });

      console.log(`Survey status updated to ${invitationCode}`);

      this.showPopupCopy = true;
      this.requestUpdate();

      // Optionally, show a success popup or update UI
      this.showSuccessPopup(`Survey ${newStatus === 'active' ? 'activated' : 'deactivated'} successfully.`);

      // Refresh the list of surveys to reflect the updated status
      //this.fetchSurveysByOrg(this.selectedOrg, this.activeTab);
    } catch (error) {
      console.error('Error updating survey status:', error);
      this.showSuccessPopup('Error updating survey status. Please try again.');
    }

    this.requestUpdate();
  }


  generateUniqueCode(length = 20) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;


  }


  render() {

    // Convert Firebase Timestamp to a Date object
    const dateObject = this.dateSurveyIssued.toDate();


     // Format the Date object for display
    const formattedDate = dateObject.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return html`
      <div class="container">
      <h2>${this.surveyTitle}</h2>
      
       <div class="survey-container">
        
      

         <!-- Modal Popup -->
      ${this.showPopup
        ? html`
          <div class="modal-overlay">
            <div class="modal-content">
              <h2>Invitation Link</h2>
              <p>Copy the link below to invite users:</p>
              <input 
                id="invite-link" 
                type="text" 
                readonly 
                value="${this.invitationLink}" 
                class="link-input"
              />
              <div class="modal-buttons">
                <button class="button" @click=${this.copyLinkToClipboard}>Copy</button>
                <button class="button" @click=${this.hidePopup}>Close</button>
              </div>
            </div>
          </div>
        `
        : ''}


${this.showPopupChangeStatus
        ? html`
          <div class="modal-overlay">
            <div class="modal-content">
              <h2>Invitation Link</h2>
              <p>Copy the link below to invite users:</p>
              <input 
                id="invite-link" 
                type="text" 
                readonly 
                value="${this.invitationLink}" 
                class="link-input"
              />
              <div class="modal-buttons">
                <button class="button" @click=${this.copyLinkToClipboard}>Copy</button>
                <button class="button" @click=${this.hidePopup}>Close</button>
              </div>
            </div>
          </div>
        `
        : ''}



      <!-- Modal Popup -->
      ${this.showStatusPopup
        ? html`
          <div class="modal-overlay">
            <div class="modal-content">
              <h2>Status Change</h2>
              <p>You have Change the Status</p>
              
              <div class="modal-buttons">
              
                <button class="button"  @click=${this.hideStatusPopup}>Close</button>
              </div>
            </div>
          </div>
        `
        : ''}


            <!-- Modal Popup -->
      ${this.showPopupCopy
        ? html`
          <div class="modal-overlay">
            <div class="modal-content">
              <h2>Invitation Link</h2>
              <p>You have copied the Invitation Link</p>
              
              <div class="modal-buttons">
                <button class="button" @click=${this.hidePopupCopy}>Close</button>
              </div>
            </div>
          </div>
        `
        : ''}


      

       
        <div class="container">
       
    <section class="section two">
    <h2>${this.surveyTitle}</h2>
      <p>This is the content of the second section.</p>
      <p><button class="button" @click="${() => window.open(this.getSurveyLink(), '_blank')}">View Published Survey</button>
     <button class="button" 
              @click=${() => this.toggleSurveyStatus(this.surveyId, this.surveyStatus)}
            >
              ${this.surveyStatus === 'active' ? 'Closed The Survey' : 'Publish The Survey'}
            </button>

            <button class="button" 
              @click=${() => this.draftSurveyStatus(this.surveyId, this.surveyStatus)}
            >
              ${this.surveyStatus === 'active' ? 'Draft The Survey' : 'Publish The Survey'}
            </button>
            
            
          
            <button class="button" @click=${() => this.generateNewInvitationLink(this.surveyId, this.inviteCode)}>
        Generate New Invitation Link
      </button>

      <button class="button" @click=${this.showInvitePopup}>Invite User</button>
      <button class="button" @click="${this.copyLinkToClipboard}">Copy Survey Link</button>
          </p>

     
    </section>
    <section class="section three">
      <h2>Survey Response Data</h2>
      <p>This is the content of the Survey Response Data.</p>
      
     
      <table>
          <thead>
            <tr>
              <th>Title</th>
              <th></th>
             
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Date of Survey Issued</td>
              <td>${formattedDate}</td>
            </tr>
            <tr>
              <td>Number of Responses received
              </td>
              <td>${this.surveyResponsesCount}</p></td>
            </tr>
           
          </tbody>
        </table>
    </section>
</div>

        <!-- <survey-summary
  invitation-count="${this.invitationCount}"
  survey-date="${this.surveyDate}"
  response-count="${this.responseCount}"
  survey-data="${this.surveydata}"
></survey-summary> -->


      </div>
      </div>
    `;
  }
}