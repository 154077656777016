import { css } from 'lit';

export const buttonStyles = css`
.button {
  background-color: #8e4953;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #FFFFFF solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
    margin-top: 0px;
    -moz-box-shadow: 0px 0px 4px 1px grey;
    -webkit-box-shadow: 0px 0px 4px 1px grey;
    box-shadow: 0px 0px 4px 1px  grey;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}
`;
