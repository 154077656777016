import { LitElement, html, css } from 'lit';
import { collection, query, where, getDocs, doc, setDoc, DocumentReference, getDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('survey-question-view-component')
export class SurveyQuestionViewComponent extends LitElement {

  static properties = {
    loadSelectedQuestions: { type: Array }, // Reactive property
    suveryWithQuestions: { type: Array }, // Reactive property
    questionsWithQuestionsBank: { type: Array }, // Reactive property
    surveyQuestionsByBank: { type: Array },
    expandedBanks: { type: Object }, // Tracks which banks are expanded
    selectedBankId: { type: String },  // Store the selected bank's ID

  };




  @property({ type: Array }) questionBank = [];
  @property({ type: Array }) allQuestions = [];
  @property({ type: Array }) filteredQuestions = [];
  @property({ type: Array }) selectedQuestions = [];
  @property({ type: String }) selectedQuestionBank = '';
  @property({ type: String }) surveyId = ''; // Store the survey ID from URL

  //@state() selectedQuestionIds = new Set(); // To track selected questions
  @state() showPopup = false;
  @state() popupMessage = '';
  @state() questions = new Set(); // To track selected questions


  constructor() {
    super();
    this.questionBank = [];
    this.allQuestions = [];
    this.filteredQuestions = [];
    this.loadSelectedQuestions = [];
    this.selectedQuestions = [];
    this.selectedQuestionBank = '';
    this.questionsWithQuestionsBank = [];
    this.selectedQuestionIds = new Set();  // Ensure initialization
    this.surveyQuestionsByBank = []; // Initialize with your JSON data
    this.expandedBanks = {}; // Object to track expanded banks (e.g., { bankId1: true, bankId2: false })
    this.selectedBankId = '';  // No bank selected initially

  }

  connectedCallback() {
    super.connectedCallback();

    //this.fetchQuestionBanks();
    const pathSegments = window.location.pathname.split('/');
    this.surveyId = pathSegments[pathSegments.indexOf('questions') + 1]; // Finds the ID after "edit"
    if (this.surveyId) {
      this.getQuestionsForSurvey();
      this.questionsWithBank();
    } else {
      console.warn('No survey ID found in URL');
    }
  }

  // Function to update the selectedQuestions property
  updateSelectedQuestions(newQuestions) {
    this.loadSelectedQuestions = newQuestions; // Updates the reactive property
  }


  // Lifecycle method: React to property changes
  updated(changedProperties) {
    if (changedProperties.has('selectedQuestions')) {
      console.log('Selected questions updated:', this.loadSelectedQuestions);
      // Perform additional actions if needed
    }
  }



  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      max-width: 1200px;
      margin: 0 auto;
      padding: 16px;
      font-family: Arial, sans-serif;
    }
    .question-bank {
      display: flex;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 16px;
      width: 100%;
    }
    .categories {
      width: 30%;
      border-right: 1px solid #e5e5e5;
      padding-right: 16px;
    }
    .categories ul {
      list-style-type: none;
      padding: 0;
    }
    .categories li {
      padding: 8px;
      cursor: pointer;
      border-radius: 4px;
      transition: background 0.3s;
    }
    .categories li:hover {
      background-color: #f0f0f0;
    }
    .categories li.selected {
      font-weight: bold;
      color:rgb(142, 73, 83);
    }
    .questions {
      width: 70%;
      padding-left: 16px;
    }
    .selected-questions {
      margin-top: 24px;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 16px;
    }
    .button-group {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
    button {
      padding: 8px 16px;
      margin-right: 8px;
      background-color: rgb(142, 73, 83);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
    }
    button:hover {
      background-color: #d0d0d0;
    }
    .spanBtn { cursor: pointer;}

    .popup {
      position: fixed;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
      z-index: 1000;
    }
    .popup-message {
      margin-bottom: 20px;
      font-size: 18px;
    }
    .close-popup {
      background-color: #8e4953;
      color: #fff;
      border: none;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
    }

    .layout {
      display: flex;
      gap: 20px;
      padding: 20px;
    }

    /* Left Sidebar (Question Banks List) */
    .left-pane {
      flex: 1;
      padding: 15px;
      background-color: #f9f9f9;
      border-radius: 5px;
      height: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .left-pane ul {
      list-style: none;
      padding: 0;
    }
    .left-pane li {
      margin-bottom: 10px;
      cursor: pointer;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #fff;
      transition: background-color 0.2s;
    }
    .left-pane li:hover {
      background-color: #f0f0f0;
    }
    .bank-header {
      font-size: 14px;
    }

    /* Right Panel (Questions for Selected Bank) */
    .right-pane {
      flex: 2;
      padding: 15px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #ddd;
      height: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      


      
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 18px;
      color: #555;
    }
    .right-pane h3 {
      margin-bottom: 15px;
    }
    .right-pane ul {
      list-style: none;
      padding: 0;
    }
    .right-pane li {
      margin-bottom: 10px;
    }
    .right-pane p {
      margin: 5px 0;
    }

    /* Basic Styling for Layout */
    .layout {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: flex-start;
    }
    .button {
  background-color: #8e4953;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #FFFFFF solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
    margin-top: 0px;
    -moz-box-shadow: 0px 0px 4px 1px grey;
    -webkit-box-shadow: 0px 0px 4px 1px grey;
    box-shadow: 0px 0px 4px 1px  grey;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}

.title {
    color: var(--black, #000);
    font-family: Poppins, sans-serif;
    font-style: normal;
    line-height: 30px;
    /* margin-bottom: 2px;  */
    margin-left: 8px; 
    font-weight: bold;
  }

  .text-s{
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 4px 4px 0 0;
    border: unset;
    border-bottom: 1px solid #000;
    background-color: rgba(240, 222, 223, 1);
    padding:10px;
  }

  .hidden-checkbox:checked + .custom-checkbox::before {
  content: "\\2714";
  visibility: visible;
}

.options-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 5px;
    }
    .option {
      display: ruby;
      align-items: center;
      gap: 10px;
    }
    .option input[type='checkbox'] {
      appearance: none;
      -webkit-appearance: none;
      width: 24px;
      height: 24px;
      border: 2px solid #a50034;
      border-radius: 3px;
      background-color: #fff;
      cursor: pointer;
    }
    .option input[type='checkbox']:checked {
      background-color: #a50034; /* Matches checkbox fill color */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .option input[type='checkbox']:checked::after {
      content: '✔';
      color: white;
      font-size: 15px;
    }
    .option label {
      font-size: 18px;
    color: rgb(51, 51, 51);
    margin-left: 10px;
    }

  `;


  async getQuestionsForSurvey() {
    try {
      const surveyRef = doc(db, 'surveys', this.surveyId);
      const surveyDoc = await getDoc(surveyRef);

      if (!surveyDoc.exists()) {
        console.warn(`Survey with ID "${surveyId}" does not exist.`);
        return [];
      }

      const surveyData = surveyDoc.data();
      const selectedQuestions = surveyData?.selectedQuestions || [];

      if (!Array.isArray(selectedQuestions)) {
        console.warn('Invalid format for selectedQuestions in survey data.');
        return [];
      }

      const questionFetchPromises = selectedQuestions.map(async (selectedQuestion) => {
        const questionRef = selectedQuestion.reference;
        const questionDoc = await getDoc(questionRef);

        if (questionDoc.exists()) {
          console.log(`Question with ID "${questionRef.id}" does  exist.`);

          console.log('Question exists:', questionDoc.data());

          console.log("this.filteredQuestions" + JSON.stringify(this.filteredQuestions, null, 2));




          this.requestUpdate();
          return { ...questionDoc.data(), order: selectedQuestion.order, id: questionRef.id }; // Add order to the question data
        } else {
          console.log('Question does not exist at the given reference.');
        }

      });

      // Use Promise.all to resolve all the question fetch promises
      const questions = await Promise.all(questionFetchPromises);

      // Filter out any null values (questions that didn't exist or had errors)
      const validQuestions = questions.filter(q => q !== null);

      console.log('Fetched questions:', validQuestions);
      this.selectedQuestion = validQuestions;
      this.loadSelectedQuestions = validQuestions;
      this.requestUpdate();

    } catch (error) {
      console.error('Error fetching questions for survey:', error);
      return [];
    }
  }

  async fetchQuestions(selectedQuestions) {
    const questions = [];
    console.log(`selectedQuestions ${selectedQuestions}`);

    const questionDetails = selectedQuestions.map((question, index) => {
      console.log(`Processing Question ${index + 1}:`, question);

      // You can return a new object or manipulate the data here
      return {
        order: question.order,
        reference: question.reference
      };
    });

    console.log(questionDetails);


    // Assuming the reference is already inside the object
    const questionReference = questionDetails.reference;

    // Fetch the document based on the reference
    const questionDoc = await getDoc(questionReference);

    // Check if the document exists
    if (questionDoc.exists()) {
      console.log("Document Dataxxx:", questionDoc.data());
    } else {
      console.log("No such document!xxx");
    }


    for (const question of selectedQuestions) {
      const questionDoc = await getDoc(question.reference);  // Fetch the document reference
      if (questionDoc.exists()) {
        const data = questionDoc.data();  // Get the data from the document
        questions.push({
          order: question.order,
          data: data // Storing question data here
        });
        console.log(`questions ${questions}`);

      } else {
        console.log(`No such document for order ${question.order}`);
      }
    }

    // Sort the questions array by the `order` if needed
    questions.sort((a, b) => a.order - b.order);

    return questions; // Return sorted or unsorted questions array
  }


  async fetchSurveyQuestionsByBank() {
    // Step 1: Fetch all banks and survey questions for each bank

    const surveysRef = collection(db, 'question-banks');
    const queryRef = query(surveysRef, where('status', '==', 'active'));
    const querySnapshot = await getDocs(queryRef);
    this.questionBank = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));



    const surveyQuestionsByBank = await Promise.all(
      this.questionBank.map(async (bank) => {
        // Query the `questions-box` collection for this bank
        const surveyQuestionDoc = collection(db, 'questions-box');
        const queryRef = query(surveyQuestionDoc, where('framework', '==', bank.id)); // Assuming `framework` matches `bank.id`
        const querySnapshot = await getDocs(queryRef);

        // Step 2: Map the survey questions for this bank
        const questions = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Step 3: Return combined data for this bank
        return {
          id: bank.id,
          name: bank.name,
          key: bank.key,
          questions, // Include all the questions related to this bank
        };
      })
    );

    // Step 4: Store or use the combined data
    this.surveyQuestionsByBank = surveyQuestionsByBank; // Save for rendering or further processing
    console.log(this.surveyQuestionsByBank);
  }



  async questionsWithBank() {
    try {
      const surveysRef = collection(db, 'question-banks');
      const queryRef = query(surveysRef, where('status', '==', 'active'));
      const querySnapshot = await getDocs(queryRef);
      this.questionBank = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));



      const surveyQuestionsByBank = await Promise.all(
        this.questionBank.map(async (bank) => {
          // Query the `questions-box` collection for this bank
          const surveyQuestionDoc = collection(db, 'questions-box');
          const queryRef = query(surveyQuestionDoc, where('framework', '==', bank.name)); // Assuming `framework` matches `bank.id`
          const querySnapshot = await getDocs(queryRef);

          // Step 2: Map the survey questions for this bank
          const questions = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          //this.filteredQuestions = questions;

          // Step 3: Return combined data for this bank
          return {
            id: bank.id,
            name: bank.name,
            key: bank.key,
            questions, // Include all the questions related to this bank
          };
        })
      );

      // Step 4: Store or use the combined data
      this.surveyQuestionsByBank = surveyQuestionsByBank; // Save for rendering or further processing
      console.log(this.surveyQuestionsByBank);

        // console.log("surveyQuestionsByBank" + JSON.stringify(this.surveyQuestionsByBank, null, 2));

     // Extract all questions and add `is_active: true`
const allQuestionsWithActive = this.surveyQuestionsByBank.flatMap((bank) =>
  bank.questions.map((question) => ({
    ...question,
    checked: true, // Adding the `is_active` property
  }))
);


console.log("allQuestionsWithActive" + JSON.stringify(allQuestionsWithActive, null, 2));


      if (this.questionBank.length > 0) {
        //this.selectQuestionBank(this.questionBank[0].name);
      }
      this.requestUpdate();
    } catch (error) {
      console.error("Error fetching question banks:", error);
    }
  }




  async fetchQuestionBanks() {
    try {
      const surveysRef = collection(db, 'question-banks');
      const queryRef = query(surveysRef, where('status', '==', 'active'));
      const querySnapshot = await getDocs(queryRef);
      this.questionBank = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (this.questionBank.length > 0) {
        //this.selectQuestionBank(this.questionBank[0].name);
      }
      this.requestUpdate();
    } catch (error) {
      console.error("Error fetching question banks:", error);
    }
  }


  // Method to show popup with a success message
  showSuccessPopup(message) {
    this.popupMessage = message;
    this.showPopup = true;
    setTimeout(() => { this.showPopup = false; }, 3000); // Auto-hide after 3 seconds
    this.requestUpdate();
  }


  async fetchQuestionsBy(questionBankId) {
    try {
      const questionsRef = collection(db, 'questions-box');
      const queryRef = query(questionsRef, where('framework', '==', questionBankId));
      const querySnapshot = await getDocs(queryRef);
      this.filteredQuestions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("this.filteredQuestions" + JSON.stringify(this.filteredQuestions, null, 2));
      this.requestUpdate();
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  }

  selectQuestionBank(questionBankName) {
    this.selectedQuestionBank = questionBankName;
    //this.fetchQuestionsBy(questionBankName);
  }

  async toggleQuestion(questionId) {
    console.log("Before adding, this.selectedQuestionIds:", [...this.selectedQuestionIds]);

    if (this.selectedQuestionIds.has(questionId)) {
      // If the question is already selected, unselect it
      this.selectedQuestionIds.delete(questionId);
      this.loadSelectedQuestions = this.loadSelectedQuestions.filter(q => q.id !== questionId);
    } else {
      // If the question is not selected, select it
      this.selectedQuestionIds.add(questionId);

      console.log("filteredQuestions", JSON.stringify(this.filteredQuestions, null, 2));


      const docRef = doc(db, "questions-box", questionId);

      try {
        // Fetch the document data
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Survey question found:", docSnap.data());
          const que = docSnap.data(); // You can return or process the data as needed

          this.loadSelectedQuestions.push({
            id: questionId,
            question: que.question,
            title: que.title || '',
            framework: que.framework,
            response_type: que.response_type,
            order: this.loadSelectedQuestions.length + 1 // Assigning an order automatically
          });

        } else {
          console.log("No such document!");
          return null;
        }




      } catch (error) {
        console.error("Error getting document:", error);
      }

    }
    this.requestUpdate();
    // Log to check
    console.log("After toggle, this.selectedQuestionIds as array:", [...this.selectedQuestionIds]);
    console.log("Is this.selectedQuestionIds a Set? ", this.selectedQuestionIds instanceof Set);
    console.log("Type of this.selectedQuestionIds: ", typeof this.selectedQuestionIds);
    //   // Request update to trigger UI refresh

    console.log("this.selectedQuestionIds:", JSON.stringify(this.selectedQuestionIds, null, 2));
    console.log("requestUpdate this.loadSelectedQuestions", JSON.stringify(this.loadSelectedQuestions, null, 2));

  }


  updateOrder(questionId, newOrder) {
    const question = this.loadSelectedQuestions.find(q => q.id === questionId);
    if (question) {
      question.order = parseInt(newOrder, 10);
      this.requestUpdate();
    }
  }

  selectBank(bankId) {
    // Set the selected bank ID when a bank is clicked
    this.selectedBankId = this.selectedBankId === bankId ? '' : bankId;  // Toggle between selected and none
  }


  async saveAndPublishSurvey() {
    const questionsArray = Array.from(this.selectedQuestionIds);
    console.log("Questions to save:", questionsArray);

    const selectedQuestionsData = Array.from(this.selectedQuestionIds).map((questionId) => {
      const question = this.loadSelectedQuestions.find(q => q.id === questionId);
      return {
        reference: doc(db, 'questions-box', questionId), // Firestore document reference
        order: question ? question.order : null // Get the order if exists
      };
    });


    const surveyData = {
      selectedQuestions: selectedQuestionsData,
      questions: selectedQuestionsData, // Store references and orders
      status: 'active', // Set the survey status to 'active'
      updatedAt: new Date() // Update timestamp
    };

    const surveyRef = doc(db, 'surveys', this.surveyId || 'newSurveyId');
    try {
      await setDoc(surveyRef, surveyData, { merge: true });

      //this.showSuccessPopup('Survey saved successfully with data!');

      this.dispatchEvent(
        new CustomEvent("navigate-to-route", {
          detail: { routePath: "/survey/publish/" + this.surveyId },
          bubbles: true,
          composed: true,
        })
      );

      console.log('Survey saved successfully with data:', surveyData);
    } catch (error) {
      console.error('Error saving survey:', error);
    }



  }


  async saveSurvey() {
    const questionsArray = Array.from(this.selectedQuestionIds);
    console.log("Questions to save:", questionsArray);
    await this.saveToFirestore(questionsArray);
  }

  async saveOrUpdateSurvey(surveyId = null) {
    try {
      // Create an array of question references with their order
      const selectedQuestionsData = Array.from(this.selectedQuestionIds).map((questionId) => {
        const question = this.loadSelectedQuestions.find(q => q.id === questionId);
        return {
          reference: doc(db, 'questions-box', questionId), // Firestore document reference
          order: question ? question.order : null // Get the order if exists
        };
      });

      // Prepare the survey data
      const surveyData = {
        selectedQuestions: selectedQuestionsData, // Store references and orders
        questions: selectedQuestionsData, // Store references and orders
        updatedAt: new Date() // Add timestamp
      };

      const surveyRef = doc(db, 'surveys', surveyId || 'newSurveyId');

      // Save the survey document, merging it if it already exists
      await setDoc(surveyRef, surveyData, { merge: true });
      console.log('Survey saved successfully with data:', surveyData);
    } catch (error) {
      console.error("Error saving or updating survey:", error);
      alert("Error saving or updating survey. Please try again.");
    }
  }

  async saveToFirestore(questionsArray) {
    console.log("Saving questions:", questionsArray);

    const selectedQuestionsData = Array.from(this.selectedQuestionIds).map((questionId) => {
      const question = this.loadSelectedQuestions.find(q => q.id === questionId);
      return {
        reference: doc(db, 'questions-box', questionId), // Firestore document reference
        order: question ? question.order : null // Get the order if exists
      };
    });


    const surveyData = {
      selectedQuestions: selectedQuestionsData,
      questions: selectedQuestionsData, // Store references and orders

    };

    const surveyRef = doc(db, 'surveys', this.surveyId || 'newSurveyId');
    try {
      await setDoc(surveyRef, surveyData, { merge: true });

      this.showSuccessPopup('Survey saved successfully with data!');

      console.log('Survey saved successfully with data:', surveyData);
    } catch (error) {
      console.error('Error saving survey:', error);
    }
  }

  async publishSurvey() {

    const surveyData = {
      status: 'active', // Set the survey status to 'active'
      updatedAt: new Date() // Update timestamp
    };

    // Reference to the survey document in Firestore
    const surveyRef = doc(db, 'surveys', this.surveyId);

    // Update the survey document with the active status
    setDoc(surveyRef, surveyData, { merge: true })
      .then(() => {
        //alert('Survey published successfully!');
        console.log('Survey status updated to active.');
        this.dispatchEvent(
          new CustomEvent("navigate-to-route", {
            detail: { routePath: "/survey-publish?id=" + this.surveyId },
            bubbles: true,
            composed: true,
          })
        );


      })
      .catch((error) => {
        console.error('Failed to publish survey:', error);
        alert('Failed to publish survey. Please try again.');
      });
  }

  toggleBank(bankId) {
    // Toggle the visibility state for a specific bank
    this.expandedBanks = {
      ...this.expandedBanks,
      [bankId]: !this.expandedBanks[bankId],
    };
  }


  backQuestion() {


    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/survey/view/questions/" + this.surveyId },
        bubbles: true,
        composed: true,
      })
    );
  }

  nextViewPublishSurvey() {


    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/survey/publish/" + this.surveyId },
        bubbles: true,
        composed: true,
      })
    );
  }



  render() {

    const selectedBank = this.surveyQuestionsByBank.find(bank => bank.id === this.selectedBankId);
    return html`
      <div class="container">
         <!-- Success Popup -->
         ${this.showPopup ? html`
          <div class="popup">
            <div class="popup-message">${this.popupMessage}</div>
            <button class="close-popup button" @click="${() => { this.showPopup = false; this.requestUpdate(); }}">Close</button>
          </div>
        ` : ''}

            <h2>Question Selection for Survey ID: ${this.surveyId}</h2>
              <div class="layout">
                    <!-- Left Sidebar: List of Question Banks -->
                      <div class="left-pane">
                          <h3>Question Banks</h3>
                             
                              <ul>
                                ${this.surveyQuestionsByBank.map(
      (bank) => html`
                                <li @click="${() => this.selectBank(bank.id)}">
                                  <div class="bank-header">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style=" vertical-align: middle;"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/></svg>
                                      <span>${bank.name}</span>
                                    </div>
                                </li>`)}
                              </ul>
                        </div>
                    <!-- Right Panel: Show Questions for the Selected Bank -->
                      <div class="right-pane">
                        ${selectedBank ? html`
                          <h3>Questions for "${selectedBank.name}"</h3>
                              ${selectedBank.questions.length > 0 ? html`
                                  ${selectedBank.questions.map((question) => html`
                                  <div class="options-grid" id="${question.id}"> <div class=" option"> <input id="question-${question.id}" type="checkbox" 
       .checked="${this.selectedQuestionIds.has(question.id)}" 
       @change="${() => this.toggleQuestion(question.id)}">
                                    <label class="title">${question.response_type === 'likert' ? html`${question.title.en}` : `${question.question.en}`}</label>
                            </div></div>
                                
                      </div>`)}`
          : html`<p>No questions available for this bank.</p>`}
              `
        : html`<p>Please select a question bank to view questions.</p>`}
          </div>
       </div>
       
        <div class="selected-questions">
          <ul>
            <h4>All Selected Questions (${this.loadSelectedQuestions.length} / 30 selected)</h4>

         
                 ${this.loadSelectedQuestions.length === 0 ? html`<p>No questions selected.</p>` : this.loadSelectedQuestions.map((question) => html`
                <li id="${question.id}"><strong>${question.framework}</strong>: ${question.response_type === 'likert' ? html`${question.title.en}` : `${question.question.en}`}
                    <input type="number" .value="${question.order}" @input="${(e) => this.updateOrder(question.id, e.target.value)}" />
                    <span class="spanBtn" @click="${() => this.toggleQuestion(question.id)}"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z" fill="black"/></svg></span>
                </li>` )}
          </ul>
      
        </div>
        <div class="button-group">
          <button class="button" @click="${this.backQuestion}">Back</button>
          <button class="button" @click="${this.nextViewPublishSurvey}">Next</button>
        </div>
      </div>
    `;
  }
}