import { LitElement, html, css } from 'lit';
import axios from 'axios';
import { getAuth, confirmPasswordReset } from 'firebase/auth'; // Firebase Authentication import


export class CreatePasswordComponent extends LitElement {
  static styles = css`
    .forgot-password-form {
      display: flex;
      flex-direction: column;
      width: 300px;
    }
    .forgot-password-form input {
      margin-bottom: 10px;
      padding: 8px;
      font-size: 14px;
      height: 25px;
      width: 100%;
      margin-bottom: 20px;
      border-radius: 4px 4px 0 0;
      border-top: unset;
      border-right: unset;
      border-left: unset;
      border-image: unset;
      border-bottom: 1px solid rgb(0, 0, 0);
      background-color: rgb(240, 222, 223);
    }
    .forgot-password-form button {
      padding: 10px;
      font-size: 16px;
      background-color: rgb(142, 73, 83);
      color: white;
      border-top: unset;
      border-right: unset;
      border-left: unset;
      border-image: unset;
      cursor: pointer;
      border-radius: 20px;
      margin-bottom: 10px;
    }
    .error {
      color: red;
      margin-top: 10px;
    }
    .success {
      color: green;
      margin-top: 10px;
    }
      p.pt20 {
      font-size:20px;
      }
  `;

  static properties = {
    email: { type: String },
    errorMessage: { type: String },
    successMessage: { type: String },
    password: { type: String },
    oobCode: { type: String } // Firebase out-of-band code (oobCode) from the email link
  };

  constructor() {
    super();
    this.email = '';
    this.errorMessage = '';
    this.successMessage = '';
    this.password = '';
    this.oobCode = new URLSearchParams(window.location.search).get('oobCode'); // Get oobCode from URL
 
    
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this[name] = value;
  }

  handlePasswordChange(e) {
    this.password = e.target.value;
  }

  async handleResetPassword() {
    const auth = getAuth();
    this.errorMessage = ''; // Clear any previous error message
    this.successMessage = ''; // Clear any previous success message


    try {
      await confirmPasswordReset(auth, this.oobCode, this.password);
      this.successMessage = 'Password reset successful! You can now log in with your new password.';
    } catch (error) {
      this.errorMessage = 'An error occurred while sending the password reset link.';
      //alert('Failed to reset password. Please try again.');
    }
  }


  async handleSubmit(event) {
    event.preventDefault();
    this.errorMessage = ''; // Clear any previous error message
    this.successMessage = ''; // Clear any previous success message

    try {
      const response = await axios.post('https://us-central1-datapoint-44397.cloudfunctions.net/api/reset-password', {
        email: this.email,
      });

      if (response.status === 200) {
        this.successMessage = 'Password reset link sent to your email!';
        console.log('Password reset link sent:', response.data);
      } else {
        this.errorMessage = 'Failed to send password reset link.';
        console.error('Failed to send password reset link');
      }
    } catch (error) {
      this.errorMessage = 'An error occurred while sending the password reset link.';
      console.error('Error:', error);
    }
  }

  render() {
    return html`
        <div class="main">
      
      <p class="pt20">Enter the email associated with your account, and we’ll send you a link to reset your password.</p>
      
      <div>
        <h2>Reset Password</h2>
        <input type="password" placeholder="Enter your new password" @input="${this.handlePasswordChange}" />
        <button @click="${this.handleResetPassword}">Reset Password</button>
      </div>
        ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}
        ${this.successMessage ? html`<p class="success">${this.successMessage}</p>` : ''}
   
      </div>
     
    `;
  }
}

customElements.define('create-password-component', CreatePasswordComponent);