import { LitElement, html, css } from 'lit';
import Chart from 'chart.js/auto';

export class PieChart extends LitElement {
    static styles = css`
    .chart-container {
      width: 100%;
      max-width: 600px;
      margin: auto;
    }
  `;

  firstUpdated() {
    const ctx = this.renderRoot.querySelector('#myPieChart').getContext('2d');
    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Red', 'Blue', 'Yellow'],
        datasets: [
          {
            label: 'Votes',
            data: [12, 19, 3],
            backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)'],
          },
        ],
      },
    });
  }

  render() {
    return html`<h1>Pie Chart</h1><div class="chart-container"><canvas id="myPieChart"></canvas></div>`;
  }
}

customElements.define('pie-chart', PieChart);