// firebase-config.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "YOUR_API_KEY",
//   authDomain: "YOUR_AUTH_DOMAIN",
//   projectId: "YOUR_PROJECT_ID",
//   storageBucket: "YOUR_STORAGE_BUCKET",
//   messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
//   appId: "YOUR_APP_ID"
// };

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAcCwlK28uu9atHlm-6JGAbG15V_1xh-3k",
  authDomain: "datapoint-44397.firebaseapp.com",
  projectId: "datapoint-44397",
  storageBucket: "datapoint-44397.appspot.com",
  messagingSenderId: "109451003258",
  appId: "1:109451003258:web:fa68a25cad9fbe7815c2c3",
  measurementId: "G-348TPC4KGJ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
export { auth, db };
