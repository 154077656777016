import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('survey-summary')
export class SurveySummary extends LitElement {
  @property({ type: Number }) invitationCount = 0;
  @property({ type: String }) surveyDate = '';
  @property({ type: Number }) responseCount = 0;

  static styles = css`
    /* Add your styles here */
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }
    th, td {
      border: 1px solid #ccc;
      padding: 10px;
      text-align: left;
    }
    th {
      background-color: #f4f4f4;
    }
    .table-container {
      margin: 16px 0;
      font-family: Arial, sans-serif;
    }
  `;

  render() {
    return html`
       <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>Metric</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr>
              <td>Invitations Sent</td>
              
            </tr> -->
            <tr>
              <td>Survey Publish Date</td>
              <td>${this.surveyDate || 'N/A'}</td>
            </tr>
            <tr>
              <td>Responses Received</td>
              <td>${this.responseCount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    `;
  }
}
