import { LitElement, html } from 'lit';
import { baseStyles } from '../../styles/base-styles.js';
import { radioStyles } from './radio-styles';

import '@material/web/radio/radio.js'

export class Radio extends LitElement {

    static properties = {
        groupName: {type: String},
        options: {type: Array}, // Array of objects with the keys: value: string, label: string, id: string
        checked: {type: Number}
    };

    static styles = [
        baseStyles,
        radioStyles
    ];

    constructor(){
        super();
        this.groupName = '';
        this.options = [];
        this.checked = -1
    }

    _handleClick(index) {
        this.checked = index;
    }

    render() {
        return html`
        <form class="radio-group">
            ${this.options.map((option, index) =>{
                return html`
                    <div class="radio-button" @click=${() => this._handleClick(index)}>
                        <md-radio 
                            name=${this.groupName} 
                            value=${option.value} 
                            id=${option.id}>
                            ?checked={${this.checked === index}}
                        </md-radio>
                        <label for=${option.id}>${option.label}</label>
                    </div>
                `
            })}
        </form>
        `
    }
}

customElements.define('q1-radio-button', Radio)