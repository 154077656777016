import {
  LitElement, html
} from 'lit';
import { radioStyles } from './dp-radio-css.js';

/**
 * This class accepts a group name and an array of objects for the radio options
 * The options array must be in the following format:
 * [
 *  {"value": "testValue", "label": "testLabel", "id": "testID"},
 *  {"value": "secondValue", "label": "secondLabel", "id": "secondID"}
 * ]
 */
export class DpRadio extends LitElement {
  static get styles() {
    return [ radioStyles ];
  }

  static get properties() {
    return {
      groupName: {type: String},
      options: {type: Array}
    };
  }

  constructor() {
    super();
    this.groupName = '';
    this.options = [];
  }

  render() {
    return html`
    <form class="radio-group">
        ${this.options.map(option => html`
                <div class="radio-button">
                    <input type="radio" 
                        class="custom-radio"
                        name=${this.groupName} 
                        value=${option.value} 
                        id=${option.id}>
                    </input>
                    <label for=${option.id}>${option.label}</label>
                </div>
            `)}
    </form>
    `;
  }
}

customElements.define('dp-radio', DpRadio);
