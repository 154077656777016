import { LitElement, html, css } from 'lit';

export class LanguageSelector extends LitElement {

    static styles = css`
  :host {
    display: block;
    margin: 0;
    padding: 0;
    --black: #000; 
  }

    .block-group {
    display: grid
  }


    .title {
    color: var(--black, #000);
    font-family: Poppins, sans-serif;
    font-style: normal;
    line-height: 30px;
    margin-bottom: 8px; 
    font-weight: bold;
  }


    select {
      height: 56px;
    width: 30%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px 4px 0 0;
  border: unset;
  border-bottom: 1px solid #000;
  background-color: rgba(240, 222, 223, 1);
    }

    .form-label {
      display: block;
      font-weight: bold;
      margin-bottom: 25px;
    }
  `;


  static properties = {
    options: { type: Object },
    language: { type: String },
    languages: { type: Object },
    errorMessage: { type: String },
  };


  

  constructor() {
    super();
    this.options = '';
    this.language = 'en'; // Default selected language
    this.languages = null;
    this.errorMessage = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchLanguages();
  }


  async fetchLanguages() {
    try {

       //const url = `http://127.0.0.1:5001/datapoint-44397/us-central1/api/getLang`;
    const url = `https://us-central1-datapoint-44397.cloudfunctions.net/api/getLang`;


      const response = await fetch(url);


      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.languages = data.languages; // Assuming the API returns a "languages" field.
      this.options = data.languages;
    } catch (error) {
      console.error('Error fetching languages:', error);
      this.errorMessage = 'Failed to fetch languages. Please try again later.';
     // this.languages = data.languages; // Assuming the API returns a "languages" field.
      this.options =  { "en": "English", es: "Spanish", "zh": "Chinese" };
    }
  }
  

  _onChange(event) {
    this.language = event.target.value;
    console.log(`Selected language: ${this.language}`);
    // Add any additional logic (e.g., emitting an event, updating state, etc.)
    this.dispatchEvent(new CustomEvent('language-changed', { detail: { language: this.language } }));

  }

  render() {
    return html`
      <label for="language-select">Choose a language:</label>
      <select id="language-select" @change="${this._onChange}">
        ${Object.entries(this.options).map(
          ([key, name]) => html`
            <option value="${key}" ?selected="${this.language === key}">${name}</option>
          `
        )}
      </select>
    `;
  }
}

customElements.define('language-selector', LanguageSelector);
