import {LitElement, html} from 'lit';
import {checkboxStyles} from './dp-checkbox-css.js';

export class DpCheckbox extends LitElement {
  static get styles() {
    return [checkboxStyles];
  }

  // static get properties() {
  //   return {
  //     title: {type: String},
  //     options: {type: Array},
  //   };
  // }


  static properties = {
    title: { type: String },
    options: { type: Array },
  };
  
  constructor() {
    super();
    this.title = "Select Options";
    this.options = []; // Initialize with empty array
  }

  connectedCallback() {
    super.connectedCallback();
    // Simulating dynamic data assignment
    this.options = this.options;
  }

  handleCheckboxChange(event) {
    const checkboxes = this.renderRoot.querySelectorAll('input[type="checkbox"]:checked');
    let options = Array.from(checkboxes).map((checkbox) => checkbox.value);

    

      // Create an object that includes the question ID, title, and selected values
  const payload = {
    questionId: this.id, // Assuming `id` is passed as an attribute
    title: this.title,
    options,
  };

 
    this.dispatchEvent(
      new CustomEvent('checkbox-change', {
        detail: { payload },
        bubbles: true,
        composed: true,
      })
    );
  }




  render() {
    return html`
      <div class="checkbox-group block-group">
        <span class="form-label title">${this.title}</span>

        ${Array.isArray(this.options) && this.options.length > 0
          ? this.options.map(
              (option) => html`
                <label class="checklabel">

               
  




                  <input 
                    type="checkbox" 
                    name="${option}" 
                    value="${option}" 
                    class="hidden-checkbox"
                    @change="${this.handleCheckboxChange}"
                    >
                  <span class="custom-checkbox"></span>
                  ${option}
                </label>
              `
            )
          : html`<p>Loading question...</p>`}
      </div>
    `;
  }
}

customElements.define('dp-checkbox', DpCheckbox);
