import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';


export class MessageBox extends LitElement {
  static styles = css`
    .message-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      text-align: center;
      background-color: #f9f9f9;
      color: #333;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .message-title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: #0077cc; /* Customize highlight color */
    }

    .message-text {
      font-size: 1rem;
      color: #666;
    }
  `;

  @property({ type: String }) title = 'Message';
  @property({ type: String }) message = 'Default message.';

  render() {
    return html`
      <div class="message-container">
        <div class="message-title">${this.title}</div>
        <div class="message-text">${this.message}</div>
      </div>
    `;
  }
}

customElements.define('message-box', MessageBox);
