import { css } from 'lit';

export const radioStyles = css`

.custom-radio {
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 24px;
}

input[type='radio'] {
    accent-color: rgba(142, 73, 83, 1);
}
`;

