// home-component.js
import { LitElement, html } from 'lit';

export class HomeComponent extends LitElement {
  render() {
    return html`
      
        <div class="main">
      
      <h1>Welcome to the Home Dashboard</h1>
      </div>
     
    `;
  }
}

customElements.define('home-component', HomeComponent);