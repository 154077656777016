import Emitter from './Emitter.js';

export default class Events {
  constructor(host) {
    this.host = host;
    new Emitter(host);
    host.on = (eventName, func) => {
      host.addEventListener(eventName, func);

      return host;
    };
  }

  trigger(event, detail, ev) {
    if (typeof event === 'object' && event instanceof Event) {
      return this.host.dispatchEvent(event);
    }
  
    // Create a new event if 'ev' is not an instance of Event
    const newEv = ev instanceof Event ? ev : new Event(
      event,
      {
        bubbles: false,
        cancelable: true
      }
    );
  
    // Add detail to the event
    newEv.detail = {
      ...detail || {},
      host: this.host
    };

    // Dispatch the newly created event
    return this.host.dispatchEvent(newEv);
  }
  
}

