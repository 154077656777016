import { css } from 'lit';

export const numberStyles = css`
    .number-box {
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 4px 4px 0 0;
        border: unset;
        border-bottom: 1px solid #000;
        background-color: rgba(240, 222, 223, 1);
    }
`;
