import { LitElement, html, css } from "lit";
import Chart from "chart.js/auto";
export class ReportHome extends LitElement {
  static properties = {
    reportName: { type: String },
    reportType: { type: String },
    parameters: { type: Array }, // An array of key-value pairs for parameters
    result: { type: String }, // A placeholder for the report result
  };

  static styles = css`
    .report-container {
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 16px;
      margin: 16px 0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }
    .report-header {
      font-size: 1.5rem;
      margin-bottom: 12px;
    }
    .report-detail {
      margin: 8px 0;
      font-size: 1rem;
    }
    .parameters {
      margin: 12px 0;
      padding: 12px;
      background: #f9f9f9;
      border: 1px solid #eee;
      border-radius: 6px;
    }
    .parameter-item {
      margin: 4px 0;
    }
    button {
      padding: 10px 16px;
      font-size: 1rem;
      color: white;
      background-color: #007bff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    button:hover {
      background-color: #0056b3;
    }

    .survey-container {
      display: flex;
      gap: 20px;
    }

    .dropdown {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .dropdown label {
      font-size: 14px;
      color: #555;
      margin-bottom: 5px;
    }

    .dropdown select {
      padding: 8px 12px;
      font-size: 14px;
      color: #555;
      background: #f1f1f1;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
      cursor: pointer;
    }

    .dropdown select:hover {
      border-color: #007bff;
    }

    .dropdown select:focus {
      border-color: #007bff;
      box-shadow: 0 0 2px #007bff;
    }
  `;

  constructor() {
    super();
    this.reportName = "Sample Report";
    this.reportType = "Sales";
    this.parameters = [
      { key: "Date Range", value: "01/01/2023 - 01/31/2023" },
      { key: "Region", value: "North America" },
    ];
    this.result = ""; // Initially empty
  }

  async generateReport() {
    // Simulate a report generation delay
    this.result = "Generating...";
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API delay
    this.result = "Report successfully generated!";
  }

  renderParameters() {
    return html`
      <div class="parameters">
        ${this.parameters.map(
          (param) =>
            html`<div class="parameter-item">
              <b>${param.key}:</b> ${param.value}
            </div>`
        )}
      </div>
    `;
  }

  render() {
    return html`
      <div class="report-container">
      <page-loader></page-loader>
        <report-view></report-view>
        <!-- <survey-report></survey-report>
        <bar-chart></bar-chart>
        <stacked-bar-chart></stacked-bar-chart>
        <scatter-plot></scatter-plot>
        <pie-chart></pie-chart>
        <line-chart></line-chart> -->
      </div>
    `;
  }
}

customElements.define("report-home", ReportHome);
