import { css } from "lit";

export const radioStyles = css`
    .radio-group {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
    }

    .radio-button {
        margin: 5px 0;
    }
`;