import { LitElement, html } from 'lit';
import { numberStyles } from './dp-number-css';

export class DpNumber extends LitElement {
  static get styles() {
    return [ numberStyles ];
  }

  static get properties() {
    return {
      id: {type: String},
      min: {type: String},
      max: {type: String},
      value: {type: String}
    };
  }

  constructor() {
    super();
    this.id = '';
    this.min = '';
    this.max = '';
    this.value = '';
  }

  render() {
    return html`
        <input 
            class="number-box"
            type="number" 
            id=${this.id}
            value=${this.value}
            min=${this.min}
            max=${this.max}
        </input>
    `;
  }
}

customElements.define('dp-number', DpNumber);
