import { LitElement, html, css } from 'lit';

export class CustomAlert extends LitElement {
  static properties = {
    type: { type: String }, // success, error, warning, info
    message: { type: String },
    visible: { type: Boolean }
  };

  static styles = css`
    :host {
      display: block;
    }
    .alert {
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 16px;
      font-family: Arial, sans-serif;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    .alert.visible {
      opacity: 1;
    }
    .alert.success {
      background-color: #e8f5e9;
      color: #2e7d32;
      border: 1px solid #81c784;
    }
    .alert.error {
      background-color: #ffebee;
      color: #c62828;
      border: 1px solid #ef5350;
    }
    .alert.warning {
      background-color: #fff8e1;
      color: #ff8f00;
      border: 1px solid #ffb74d;
    }
    .alert.info {
      background-color: #e3f2fd;
      color: #1565c0;
      border: 1px solid #64b5f6;
    }
    .close-btn {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
      color: inherit;
      margin-left: 16px;
    }
  `;

  constructor() {
    super();
    this.type = 'info'; // default type
    this.message = '';
    this.visible = false;
  }

  closeAlert() {
    this.visible = false;
  }

  render() {
    return html`
      <div class="alert ${this.type} ${this.visible ? 'visible' : ''}">
        <span>${this.message}</span>
        <button class="close-btn" @click="${this.closeAlert}">&times;</button>
      </div>
    `;
  }
}

customElements.define('custom-alert', CustomAlert);
