import { LitElement, html } from 'lit';
import { baseStyles } from '../../styles/base-styles';
import { switchStyles } from './switch-styles';

import '@material/web/switch/switch.js';

export class Switch extends LitElement {

    static properties = {
        selected: { type: Boolean },
        disabled: { type: Boolean },
        icons: { type: Boolean },
        showOnlySeletedIcons: { type: Boolean },
        label: { type: String },
        required: { type: Boolean }
    }

    constructor() {
        super();
        this.selected = false;
        this.disabled = false;
        this.icons = false;
        this.label = '';
        this.showOnlySeletedIcons = false;
        this.required = false;
    }

    static styles = [
        baseStyles,
        switchStyles
    ];

    _handleSelected(e){
        this.selected = e.target.selected;
    }

    render() {
        return html`
        <div class="q1-switch-container">
                <label>${this.label}
                    <md-switch 
                    @change=${(e) => this._handleSelected(e)}
                    ?selected=${this.selected} 
                    ?disabled=${this.disabled}
                    ?icons=${this.icons}
                    ?show-only-selected-icon=${this.showOnlySeletedIcons}
                    ?required=${this.required}
                    ></md-switch>
                </label>
        </div>
        `
    }
}

customElements.define('q1-switch', Switch)