import { equalsIgnoringCase, fetchContent } from "../utils.js";
import Router from "./Router.js";

/**
 * This class is a Shim between Q1Container and Router, to serve Q1 components
 * in an SPA with minimal config
 */
export class Q1Router {
  /**
   * Class constructor
   * @param {object} config
   * @param {object} q1container
   * @param {object} contentDiv
   */
  constructor(config, q1container, contentDiv) {
    this.router = new Router(config);
    this.config = config;
    this.contentDiv = contentDiv;
    this.container = q1container;
    this.init();
  }

  /**
   * Initializes the router and event listeners
   */
  init() {
    // Delegate event handling to Router
    this.router.listen();
    this.router.on("route", this.updateContent.bind(this));
    
    // Set the initial route based on the current URL
    const currentPath = window.location.pathname; // Get the current path
    this.router.setRoute(currentPath);

    // Use `this` instead of `self`
    window.addEventListener("click", (event) => this.handleNavigation(event));
    window.addEventListener("navigate-to-route", (event) => this.handleNavigation(event));
  }

  /**
   * Combined event handler for both click and custom navigation events
   * Used to keep the user internally routing through our SPA
   *
   * @param {object} event
   */
  handleNavigation(event) {
    let routePath;

    if (event.type === "click") {
      // For click events, check if the target has a 'data-route' attribute
      routePath = event.target.getAttribute("data-route");
      if (!routePath) return; // Exit if no routePath found
    } else if (event.type === "navigate-to-route") {
      // For custom 'navigate-to-route' events, get the routePath from event detail
      routePath = event.detail?.routePath;
    }

    if (routePath) {
      event.preventDefault();
      this.router.setRoute(routePath);
    }
  }

  // This event is fired from the Router's setRoute method
  async updateContent(event) {
    this.updateInternalLinks(event.detail.path);
    this.contentDiv.innerHTML = await fetchContent(event.detail.route.content);
  }

  /*
   * Update the container's internalLinks to a copy of the object,
   * this will force Lit.js to update the active links on the sidebar
   */
  updateInternalLinks(path) {
    const updatedLinks = {};

    Object.keys(this.config.routes).forEach((key) => {
      updatedLinks[key] = {
        ...this.config.routes[key],
        active: equalsIgnoringCase(key, path) || (key === "/" && path === "home"),
      };
    });

    if (this.container) {
      this.container.internalLinks = updatedLinks;
    }
  }
}
