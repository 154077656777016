export const routerConfig = {
  routes: {
    // General Routes
    "/": { title: "Home", content: "home.html", active: false },
    "/dashboard": { title: "Dashboard", content: "dashboard.html", active: false },
    "/my-account": { title: "My Account", content: "my-account.html", active: false },
    "/manage-org": { title: "Manage Organization", content: "manage-org-account.html", active: false },
    "/help": { title: "Help", content: "help.html", active: false },
    "/privacy": { title: "Privacy", content: "privacy.html", active: false },
    "/terms": { title: "Terms", content: "terms.html", active: false },
    "/signup": { title: "Sign Up", content: "signup.html", active: false },
    "/login": { title: "Log In", content: "login.html", active: false },
    "/forgot-password": { title: "Forgot Password", content: "forgot-password.html", active: false },
    "/reset-password": { title: "Reset Password", content: "create-password.html", active: false },

    // Survey Routes
    "/survey": { title: "Survey Home", content: "home.html", active: false },
    "/survey/create": { title: "Create Survey", content: "create-survey.html", active: false },
    "/survey/edit/:surveyId": { title: "Edit Survey", content: "edit-survey.html", active: false },
    "/survey/publish/:surveyId": { title: "Publish Your Survey", content: "survey-publish-component.html", active: false },
    "/survey/create/questions/:surveyId": { title: "Choose Survey Questions", content: "survey-questions.html", active: false },
    "/survey/view/questions/:surveyId": { title: "View Survey Questions", content: "survey-questions-view.html", active: false },
    "/survey/view/:surveyId": { title: "View Survey", content: "view-survey.html", active: false },
    "/survey/complete/:surveyId": { title: "Complete Survey", content: "public-survey.html", active: false },


    // Reports Routes 
    "/reports/view/:surveyId": { title: "Survey Reports", content: "report-home.html", active: false },

    // Error Handling Routes
    "/complete": { title: "Survey Not Found", content: "survey-error.html", active: false }, // Specific fallback for surveys
    "/404": { title: "Not Found", content: "404.html", active: false }, // Generic 404 page
    "*": { title: "Not Found", content: "404.html", active: false }, // Wildcard fallback
  },
};
