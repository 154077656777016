import { LitElement, html, css } from 'lit';

export class RatingScale extends LitElement {
  static properties = {
    label: { type: String },
    minLabel: { type: String },
    maxLabel: { type: String },
    minValue: { type: Number },
    maxValue: { type: Number },
    selectedValue: { type: Number },
    showError: { type: Boolean }, // Tracks whether to show a validation error
  };

  static styles = css`
    :host {
      display: block;
      font-family: 'Arial', sans-serif;
      color: #333;
    }
    .rating-scale {
      font-family: Arial, sans-serif;
      margin: auto;
      padding: 20px;
      border: 1px solid rgb(165, 0, 52);
      border-radius: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .question {
      color: var(--black, #000);
      font-family: Poppins, sans-serif;
      font-style: normal;
      line-height: 30px;
      margin-bottom: 8px;
      font-weight: bold;
    }
    .scale {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    .scale input[type='radio'] {
      appearance: none;
      -webkit-appearance: none;
      width: 24px;
      height: 24px;
      border: 1.5px solid rgb(165, 0, 52);
      border-radius: 50%;
      cursor: pointer;
      background-color: #fff;
    }
    .scale input[type='radio']:checked {
      background-color: #a50034;
      border: 1.5px solid #a50034;
    }
    .scale label {
      font-size: 18px;
      text-align: center;
    }
    .labels {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 400px;
      font-size: 14px;
      color: #666;
      margin-top: 10px;
    }
    .error {
      color: red;
      font-size: 14px;
      margin-top: 10px;
    }
  `;

  constructor() {
    super();
    this.label = 'How likely is it that you would recommend this program to a friend?';
    this.minLabel = 'Not at all likely';
    this.maxLabel = 'Extremely likely';
    this.minValue = 1;
    this.maxValue = 10;
    this.selectedValue = null;
    this.showError = false; // Initialize error display as false
  }

  handleChange(e) {
    this.selectedValue = e.target.value;
    this.showError = false; // Clear the error on valid selection
    this.dispatchEvent(new CustomEvent('rating-changed', { detail: { value: this.selectedValue } }));
  }

  validateSelection() {
    if (!this.selectedValue) {
      this.showError = true; // Show error if no value is selected
      return false;
    }
    this.showError = false;
    return true;
  }

  render() {
    return html`
      <div class="rating-scale">
        <div>
          <label class="question">${this.label}</label>
        </div>
        <div class="scale">
          ${Array.from({ length: this.maxValue - this.minValue + 1 }, (_, i) => i + this.minValue).map(
            (value) => html`
              <input
                type="radio"
                id="rating-${value}"
                name="rating"
                value="${value}"
                @change="${this.handleChange}"
                ?checked="${this.selectedValue == value}"
              />
              <label for="rating-${value}">${value}</label>
            `
          )}
        </div>
        <div class="labels">
          <span>${this.minLabel}</span>
          <span>${this.maxLabel}</span>
        </div>
        ${this.showError
          ? html`<div class="error">Please select a value before proceeding.</div>`
          : ''}
      </div>
    `;
  }
}

customElements.define('rating-scale', RatingScale);
