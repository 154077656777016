import { LitElement, html, css } from 'lit';
import { containerStyles } from "./container-styles.js";
import { baseStyles } from "../../styles/base-styles.js";
import { defaultlinkConfig, defaultRouterConfig } from "../../defaultConfig.js";

export class Container extends LitElement {
    static properties = {        
        appSuiteHeader: { type: String },
        applicationLinks: { type: Object },
        internalLinks: { type: Object },
        externalLinks: { type: Object },
    }

    static styles = [
        baseStyles,
        containerStyles
    ];

    constructor() {
        super();
        this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
        this.appSuiteHeader = "";

        this.internalLinks = defaultRouterConfig.routes;
        this.applicationLinks = defaultlinkConfig.applicationLinks;
        this.externalLinks = defaultlinkConfig.externalLinks;
    }
    
    connectedCallback() {
        super.connectedCallback();
        // Add event listener
        window.addEventListener('sidebar-toggle', this.handleSidebarToggle);
        this.appSuiteHeader = this.getAttribute('data-app-suite-header');
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('sidebar-toggle', this.handleSidebarToggle);
    }

    handleSidebarToggle(event) {
        // Update the component's attribute based on the sidebar's state
        this.toggleAttribute('collapsed', event.detail);
    }

    render() {
        return html`
           <header><div class="logo" data-route="/" >Logo</div><q1-navbar id="navbar" class="menu"
          .links=${this.applicationLinks} 
          appSuiteHeader=${this.appSuiteHeader}></q1-navbar></header>
            <slot></slot>`;
    }
}

customElements.define('q1-container', Container);


/* // <q1-navbar id="navbar" 
            //     .links=${this.applicationLinks} 
            //     appSuiteHeader=${this.appSuiteHeader}></q1-navbar>
            // <q1-sidebar id="sidebar"
            //     .links=${this.applicationLinks}
            //     .internalLinks=${this.internalLinks}
            //     .externalLinks=${this.externalLinks}
            //     appSuiteHeader=${this.appSuiteHeader}
            //     collapsed></q1-sidebar>
            */
