import { LitElement, html, css } from 'lit';
import { auth } from '../../../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { customElement, property, state } from 'lit/decorators.js';
import axios from 'axios';
@customElement('survey-component')
export class SurveyComponent extends LitElement {
  @property({ type: Object }) user = null;
  @property({ type: String }) surveyStatus = 'loading';
  @property({ type: String }) errorMessage = '';
  @property({ type: String }) message = '';
  @property ({type:Array  }) surveyDetails = {};

  @state() name = '';
  @state() language = '';
  @state() languages = ['English', 'Spanish', 'French'];
  @state() step = 'initial';
  @state() page = 1; // Track the current survey page
  @state() isComplete = false; // Track survey completion status



  static styles = css`
    .container {
      padding: 20px;
      text-align: center;
    }
      .button-container {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    
      input {
      margin-bottom: 15px;
      padding: 12px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: rgb(240, 222, 223);
    }

    .button {
      padding: 12px;
      font-size: 16px;
      background-color: #8e4953;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 20px;
      margin-bottom: 10px;
    }

  `;

  constructor() {
    super();
    this.checkAuth();
    this.checkSurveyStatus();
    this.setDefaultLanguage();
  }

  connectedCallback() {
    super.connectedCallback();
    this.checkAuth();
  }

  checkAuth() {
    onAuthStateChanged(auth, (user) => {
      this.user = user;
      this.requestUpdate();
    });
  }
  goBack() {
    if (this.page > 1) 
      {
      this.page -= 1;
      this.requestUpdate();
    }
 }

 saveAndContinue() {
  if (this.page < 3) { // Assuming 3 pages of survey
    this.page += 1;
    this.requestUpdate();
  } 
  else
  {
    console.log("complete Survey");

    this.completeSurvey();
    this.requestUpdate();
  }
}

completeSurvey() {
  console.log("call this Survey");
  this.isComplete = true;
  this.requestUpdate();
    }



  async checkSurveyStatus() {
    const invitationCode = this.getInvitationCodeFromUrl().replace(/\s+/g, '');

    try {
      const surveyData = await this.fetchSurveyStatus(invitationCode);
      this.surveyStatus = surveyData.status;
      this.surveyDetails = surveyData;
     
      this.requestUpdate();
      console.log("sss"+this.surveyDetails);

      switch (this.surveyStatus) {
        case 'loading':
          this.message = 'Loading Survey ...';
          break;
        case 'active':
          this.message = 'Survey is active. Please proceed.';
          break;
        case 'draft':
          this.message = 'The survey is currently not accepting new submissions.';
          break;
        case 'published':
          this.message = 'Survey is published. Ready to take.';
          break;
        case 'expired':
          this.message = 'Survey has expired and is no longer available.';
          break;
        case 'error':
          this.message = 'The survey is currently not accepting new submissions.';
          break;
        default:
          this.message = 'Unknown survey status.';
      }
    } catch (error) {
      this.errorMessage = error.message || 'Failed to fetch survey status.';
      this.surveyStatus = 'error';
      this.message = 'Failed to fetch survey status.';
    }
  }

  getInvitationCodeFromUrl() {
    const urlParts = window.location.pathname.split('/');
    return urlParts[urlParts.length - 1];
  }

  async fetchSurveyStatus(invitationCode) {
    const url = `https://us-central1-datapoint-44397.cloudfunctions.net/api/survey-status?invitation_code=${invitationCode}`;
    try {
      const response = await axios.get(url);
      return response.data;


    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          // Specific handling for 404 error
          this.surveyStatus = 'error';
          this.message = 'Survey not found. Please check the invitation code.';
        } else {
          // Handle other HTTP errors
          this.surveyStatus = 'error';
          this.message = `Error: ${error.response.status} - ${error.response.data.message || 'An error occurred'}`;
        }
      } else {
        // Handle network or other errors not associated with a response
        this.surveyStatus = 'error';
        this.message = 'Failed to fetch survey status. Please check your network connection.';
      }
      
      // Re-render the component to display the error
      this.requestUpdate();
      
      // Optionally, rethrow the error if you want the calling function to handle it further
      //throw new Error(this.message);
      throw new Error(error.response ? error.response.data.message : 'Server error');
    }
  }

  setDefaultLanguage() {
    const browserLanguage = navigator.language.slice(0, 2);
    const languageMap = { en: 'English', es: 'Spanish', fr: 'French' };
    this.language = languageMap[browserLanguage] || this.languages[0];
  }

  handleNameChange(event) {
    this.name = event.target.value;
  }

  handleLanguageChange(event) {
    this.language = event.target.value;
    this.requestUpdate();
  }

  submitSurvey() {
    alert("Survey submitted successfully!");
  }

  handleNext() {
    if (this.name && this.language) {
      this.errorMessage = '';
      this.step = 'survey';
      this.requestUpdate();
    } else {
      this.errorMessage = 'Please fill in all required fields before proceeding.';
    }
  }
  /* <p class="error">${this.surveyStatus}</p>*/

  renderPageContent() {
    switch (this.page) {
      case 1: return html`<p>Question 1: How satisfied are you with our service?</p>
                    <select>
                      <option value="very-satisfied">Very Satisfied</option>
                      <option value="satisfied">Satisfied</option>
                      <option value="neutral">Neutral</option>
                      <option value="dissatisfied">Dissatisfied</option>
                      <option value="very-dissatisfied">Very Dissatisfied</option>
                    </select>`;
      
      case 2:
        return html`<p>Question 2: Would you recommend us to others?</p>
                    <select>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>`;
      case 3:
        
      return html`<p>Question 3: Any additional feedback?</p>
                    <textarea rows="4" cols="50"></textarea>`;
      
      default:
        
      return html`<p>No more questions.</p>`;
    }
  }


  render() {

    if (this.isComplete) {
      
 
      return html`
              <div class="container">
               <message-box
            title="Your survey is complete!"
            message="Thank you for your submmsion."
          ></message-box> 
              </div>
            `;
          }

    return html`
      <div class="container">
       
        ${this.surveyStatus === 'loading'
          ? html`<p>Loading survey status...</p>`
          : this.surveyStatus === 'error'
          ? html`
        

                    <message-box
            title="Sorry !  link is invalid"
            message="${this.message}"
          ></message-box> 
          `
          : html`
              ${this.step === 'initial'
                ? html`
                    <h1>${this.surveyDetails.surveyTitle}</h1>
                      <p>${this.message}</p>
                    <div>
                      <label>
                        Name:
                        
                        <input type="text" .value=${this.name} @input=${this.handleNameChange} />
                      </label>
                    </div>
                    <div>
                      <label>
                        Language:
                        <select .value=${this.language} @change=${this.handleLanguageChange}>
                          ${this.languages.map(
                            (lang) => html`<option value=${lang}>${lang}</option>`
                          )}
                        </select>
                      </label>
                    </div>
                   
                     <div class="button-container">
                
                  <button class="button" @click=${this.handleNext}>Save & Continue ></button>
                </div>
                    ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}
                  `
                : this.step === 'survey'
                ? html`
                    <div class="survey-content">
                     ${this.renderPageContent()}
                       <div class="button-container">
                       <button class="button" @click=${this.goBack}>< Go Back</button>
                        <button class="button" @click=${this.saveAndContinue}> ${this.page === 3 ? 'Save & Complete' : 'Save & Continue >'}
                        </button>
                      </div>

                      
                    </div>
                  `
                : ''}
            `}
      </div>
    `;
  }
}
