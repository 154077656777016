import { LitElement, html, css } from 'lit';

export class StackedBarChart extends LitElement {
  static styles = css`
    :host {
      display: block;
      font-family: Arial, sans-serif;
      width: 100%;
    }
    .chart {
      display: flex;
      gap: 10px;
    }
    .stack {
      display: flex;
      flex-direction: column-reverse;
      height: 200px;
      width: 50px;
      background: #f9f9f9;
      border: 1px solid #ccc;
    }
    .segment {
      flex-grow: 1;
      text-align: center;
      color: #fff;
      font-size: 12px;
    }
  `;

  static properties = {
    data: { type: Array },
  };

  constructor() {
    super();
    this.data = [
      { label: 'A', segments: [30, 40, 30] },
      { label: 'B', segments: [20, 60, 20] },
      { label: 'C', segments: [50, 30, 20] },
    ];
  }

  render() {
    return html`
      <h1>StackedBarChart</h1>
      <div class="chart">
        ${this.data.map(
          (d) => html`
            <div class="stack">
              ${d.segments.map(
                (value) =>
                  html`<div
                    class="segment"
                    style="height: ${value}%; background: #${Math.floor(
                      Math.random() * 16777215
                    ).toString(16)};"
                  ></div>`
              )}
            </div>
          `
        )}
      </div>
    `;
  }
}

customElements.define('stacked-bar-chart', StackedBarChart);
