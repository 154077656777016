import {LitElement, css} from 'lit';
export const dropdownStyles = css`
  :host {
    display: block;
    margin: 0;
    padding: 0;
    --black: #000; 
  }

    .block-group {
    display: grid
  }


    .title {
    color: var(--black, #000);
    font-family: Poppins, sans-serif;
    font-style: normal;
    line-height: 30px;
    margin-bottom: 8px; 
    font-weight: bold;
  }


    select {
      height: 56px;
width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px 4px 0 0;
  border: unset;
  border-bottom: 1px solid #000;
  background-color: rgba(240, 222, 223, 1);
    }

    .form-label {
      display: block;
      font-weight: bold;
      margin-bottom: 25px;
    }
`;
