import { css } from "lit";

export const containerStyles = css`
    :host {
        --main-content-margin-top: 52px;
        --main-content-padding: 15px;
    }
    .logo {
      margin-left: 2%;
    }
    .menu .button {
      margin-right: 1em;
    }
    header, footer {
      background: #333;
      color: #fff;
      padding: 1em;
    }
    main {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
   .landingIMG {
      max-width: 60%;
      height: auto;
    }

    ::slotted(*) {
        margin-top: var(--main-content-margin-top);
        padding: var(--main-content-padding);
    }
        

    @media (min-width: 600px) {
        :host {
            display: block;
            margin-left:1%;
            transition: margin-left 0.15s ease-in-out;
        }

        :host([collapsed]) {
            margin-left: 0;
        }
    }
`;