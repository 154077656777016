import { LitElement, html, css } from 'lit';
import { auth } from '../../../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { customElement, property, state } from 'lit/decorators.js';
import axios from 'axios';
@customElement('survey-component')
export class SurveyComponent extends LitElement {
  @property({ type: Object }) user = null;
  @property({ type: String }) surveyStatus = 'loading';
  @property({ type: String }) errorMessage = '';
  @property({ type: String }) message = '';
  @property({ type: Array }) surveyDetails = [];
  @property({ type: Number }) totalquestions = 0; 
  @property({ type: Number }) currentQueIndex = 0; 
  
  
  
 
  @property({ type: Array }) response_types = [];
  @property({ type: Array }) activities = {};
  @property({ type: String }) surveyId = '';
  @property({ type: String }) surveyTitle = '';
  @property({ type: String }) inviteCode = '';
  @property({ type: String }) surveyOrgName = '';
  // Public property for survey questions (can be fetched externally)
  @property({ type: Array }) questions = [];
  // @property ({type:Array  })  questions = [];
  static properties = {
    language: { type: String },
    languageMap: { type: Object },
    selectedLanguage: { type: String },
    surveyQuestionsIds:{ type: String },
    showErrorMessage: { type: Boolean },
    responses: { type: Object },  // Map questionId -> answer
    currentQuestionId: { type: String },
    isLoading: { type: Boolean },
    apiURL : { type: String },
  };


  @state() showPopup = false;
  @state() name = '';
  @state() language = '';
  @state() languages = { "en": "English", es: "Spanish", "zh": "Chinese" };
  @state() step = 'initial';
  @state() page = 1; // Track the current survey page
  @state() isComplete = false; // Track survey completion status
  // Internal state to track user responses
  @state() surveyResponses = [];
  // Internal state to track user responses
  //@state() responses = [];

  static styles = css` 
  
  :host {
    --main-content-margin-top: 52px;
    --main-content-padding: 15px;
}

label .questionTitle {color: var(--black, #000);
    font-family: Poppins, sans-serif;
    font-style: normal;
    line-height: 30px;
    margin-bottom: 8px;
    font-weight: bold;
  }

    .container {
      padding: 2%;
      max-width: 1200px;
      margin: auto;
      width: 90%;
      /* text-align: center; */
    }
      .button-container {
      /* display: flex; */
      justify-content: space-between;
      margin-top: 20px;
    }

    .validationmsg { text-align:center;}
    
      input {
      margin-bottom: 15px;
      padding: 12px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: rgb(240, 222, 223);
    }

    .button {
  background-color: #8e4953;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #FFFFFF solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
    margin-top: 0px;
    -moz-box-shadow: 0px 0px 4px 1px grey;
    -webkit-box-shadow: 0px 0px 4px 1px grey;
    box-shadow: 0px 0px 4px 1px  grey;
}

.error , .error-message{
  color:red;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}

.btnRight  { float:right;}
.qtnBox { font-family: Arial, sans-serif;
    /* max-width: 400px; */
    margin: auto;
    padding: 20px;
    border: 1px solid rgb(165, 0, 52);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;}


    .qtnBoxMessage{ font-family: Arial, sans-serif;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid rgb(165, 0, 52);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;}

  `;

firstUpdated() {
  // Ensure all initializations are complete before fetching survey status
    this.checkSurveyStatus();
    this.setDefaultLanguage();
}
  constructor() {
    super();
    this.checkAuth();
    this.apiURL = '';
    this.setApiUrl();
    this.language = 'en'; // Default language
    this.surveyId = '';
    this.currentQueIndex = 0; // Start at the first question
    this.currentQuestionId = '';
    this.isLoading = false; // To show a loading state
    this.response_types = [
      'single-select',
      'dropdown',
      'mc-multi',
      'mc-single',
      'boolean',
      'ranking',
      'short-answer',
      'select',
      'matrix',
      'likert',
      'numeric-range',
      'text-area',
      'text',
      'number',
      'single-select',
      'multi-select',
      'single-select-text',
      'ranked-choice'
    ];
    this.responses = {}; // To store question responses
    this.showErrorMessage = false; // For validation messages
    this.languageMap = this.language; //{ en: "English", es: "Spanish", zh: "Chinese" };
    // Detect browser language and set default
    const browserLanguage = navigator.language.slice(0, 2);
    this.language = Object.keys(this.languageMap).includes(browserLanguage)
      ? browserLanguage
      : "en"; // Default to English if browser language is not found
    this.totalquestions = this.questions.length;
    // Dynamically set the API URL based on the environment
    this.requestUpdate();
    console.log("this.totalquestions" + this.totalquestions);

  }

  getCurrentQuestion() {
    return this.questions[this.currentQueIndex] || null;
  }


  nextQuestion() {
    if (this.currentQueIndex < this.questions.length - 1) {
      this.currentQueIndex++;
      return this.getCurrentQuestion();
    } else {
      console.log("No more questions.");
      return null;
    }
  }

// Method to set API URL
setApiUrl() {
  const isLocalhost = window.location.hostname === "localhost";
  this.apiURL = isLocalhost
    ? "http://127.0.0.1:5001/datapoint-44397/us-central1/api/" // Localhost URL
    : "https://us-central1-datapoint-44397.cloudfunctions.net/api/"; // Production URL
  console.log("API URL set to:", this.apiURL);

  this.apiURL = "https://us-central1-datapoint-44397.cloudfunctions.net/api/";
  
}

  handleCheckboxChange(event) {
    const selectedValues = event.detail.payload;
    console.log('Selected Values:', selectedValues);

    // Check if the response for the same questionId already exists
    const existingIndex = this.surveyResponses.findIndex(
      (response) => response.questionId === selectedValues.questionId
    );

    if (existingIndex !== -1) {
      // Update the existing response
      this.surveyResponses[existingIndex] = selectedValues;
    } else {
      // Add a new response
      this.surveyResponses = [...this.surveyResponses, selectedValues];
    }

    this.requestUpdate(); // Trigger re-render if necessary
    console.log("this.surveyResponses", JSON.stringify(this.surveyResponses, null, 2));
  }



  _onChange(event) {
    this.language = event.target.value;
    this.dispatchEvent(new CustomEvent('language-changed', { detail: { language: this.language } }));
  }

  async connectedCallback() {
    super.connectedCallback();
    this.checkAuth();
  }

  // Handle option selection
  handleOptionChange(event, questionId) {
    this.responses = { ...this.responses, [questionId]: event.target.value };
  }

  handleLanguageChange(event) {
    this.language = event.target.value;
    console.log(`Selected language: ${this.language} - ${this.languageMap[this.language]}`);
  }

  async step1() {

    try {

      if (this.name && this.language) {
       
        this.errorMessage = '';
        this.step = 'survey';
        this.page = 1;
        // Define the payload to be sent to the API
        const surveySubmissionStep1 = {
          surveyId: this.surveyDetails.surveyId,
          surveyName: this.surveyDetails.surveyTitle,
          invitationCode: this.inviteCode,
          status: this.surveyDetails.status,
          name: this.name,
          language: this.language
        };

        this.surveyResponses = surveySubmissionStep1;
        this.requestUpdate();
        console.log("this.surveyResponses" + JSON.stringify(this.surveyResponses, null, 2));
        //console.log("this.questions" + JSON.stringify(this.questions, null, 2));
        //this.requestUpdate();
      } else {
        this.errorMessage = 'Please fill in all required fields before proceeding.';
        this.requestUpdate();
        return;
      }


    } catch (error) {
      console.error('Error submitting survey:', error);
      this.showSuccessPopup('An error occurred while submitting the survey.');
      this.errorMessage = 'An error occurred while submitting the survey.';
      this.requestUpdate();
      //this.surveyResponses = [];
    }

  }


  showSuccessPopup(message) {
    this.popupMessage = message;
    this.showPopup = true;
    setTimeout(() => {
      this.showPopup = false;
      this.requestUpdate();
    }, 3000); // Auto-hide after 3 seconds
  }


  checkAuth() {
    onAuthStateChanged(auth, (user) => {
      this.user = user;
      this.requestUpdate();
    });
  }


  goBack() {
    console.log("this.page" + this.page);
    console.log("this.step" + this.step);



    if (this.page == 1) {
      this.step = 'initial';
      this.page = 1;
      this.currentQueIndex = 0;
      this.requestUpdate();
    }
    if (this.page > 1) {
      this.page -= 1;
      this.currentQueIndex -= 1;

      this.requestUpdate();
    }
  }

 

  completeSurvey() {
    console.log("call this Survey");
    this.isComplete = true;
    this.requestUpdate();
  }

  _onLanguageChanged(event) {
    this.language = event.detail.language; // Update the parent's language
    console.log(`Parent detected language change: ${this.language}`);
    this.requestUpdate();
  }

  async checkSurveyStatus() {
    const invitationCode = this.getInvitationCodeFromUrl();
    this.inviteCode = invitationCode;
    console.log("invitationCode" + invitationCode);

    try {
      const surveyData = await this.fetchSurveyStatus(invitationCode);

      console.dir(surveyData);
      console.log("dddd");
      this.surveyStatus = surveyData.status;
      this.surveyOrgName = surveyData.orgname;
      this.surveyDetails = surveyData;
      this.surveyId = surveyData.surveyId;
      this.surveyQuestionsIds = surveyData.questions;
      this.totalquestions = surveyData.questions.length;
      
      // this.questions =  this.getSurveyQuestions(surveyData.questions).then((data) => {
      //   if (data && data.questionData) {
      //     this.questions = [...this.questions, data.questionData];
      //   }
      // });

      console.log("surveyData.questions1",JSON.stringify(this.surveyQuestionsIds,2));
      const allQuestions = await this.getSurveyQuestions(this.surveyQuestionsIds);
      console.log("data",JSON.stringify(allQuestions,2));
      //console.log("data",JSON.stringify(allQuestions,2));
      // this.questions = allQuestions.map(item => item.questionData);
      this.questions = allQuestions.map(item => ({
        id: item.referenceId, // Include the question's unique ID
        ...item.questionData  // Include all other question data
      }));
      this.questions.sort((a, b) => a.order - b.order);
      //console.log("this.questions2222" + JSON.stringify(this.questions,2));
      this.requestUpdate();
      //console.log("sss" + JSON.stringify(this.surveyDetails, null, 2));
      switch (this.surveyStatus) {
        case 'loading':
          this.message = 'Wait... Loading Survey ...';
          break;
        case 'active':
          this.message = 'Survey is active. Please proceed.';
          break;
        case 'draft':
          this.message = 'The survey is currently not accepting new submissions.';
          break;
        case 'closed':
          this.message = 'Survey has expired and is no longer available.';
          break;
        case 'error':
          this.message = 'The survey is currently not accepting new submissions.';
          break;
        default:
          this.message = 'The survey is currently not accepting new submissions.';
      }
    } catch (error) {
      this.errorMessage = error.message || 'Failed to fetch survey status.';
      this.surveyStatus = 'error';
      this.message = 'The survey is currently not accepting new submissions.';
    }
  }



  getInvitationCodeFromUrl() {
    // const urlParts = window.location.pathname.split('/');

    const pathSegments = window.location.pathname.split('/');
    const inviteId = pathSegments[pathSegments.indexOf('complete') + 1]; // Finds the ID after "edit"
    return inviteId;
  }

  async fetchSurveyStatus(invitationCode) {
    //const URL =   `http://127.0.0.1:5001/datapoint-44397/us-central1/api/survey-status?invitation_code=${invitationCode}`;
    //const URL = `https://us-central1-datapoint-44397.cloudfunctions.net/api/survey-status?invitation_code=${invitationCode}`;
   console.log(this.apiURL);

   //this.apiURL
   const URL = `${this.apiURL}survey-status?invitation_code=${invitationCode}`;
    try {
      const response = await axios.get(URL);
      return response.data;


    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          // Specific handling for 404 error
          this.surveyStatus = 'error';
          this.message = 'Survey not found. Please check the invitation code.';
        } else {
          // Handle other HTTP errors
          this.surveyStatus = 'error';
          this.message = `Error: ${error.response.status} - ${error.response.data.message || 'An error occurred'}`;
        }
      } else {
        // Handle network or other errors not associated with a response
        this.surveyStatus = 'error';
        this.message = 'Failed to fetch survey status. Please check your network connection.';
      }

      // Re-render the component to display the error
      this.requestUpdate();

      // Optionally, rethrow the error if you want the calling function to handle it further
      //throw new Error(this.message);
      throw new Error(error.response ? error.response.data.message : 'Server error');
    }
  }


   // Fetch function to call the API
   async getSurveyOrganization(surveyId) {
    //const apiUrl = `http://127.0.0.1:5001/datapoint-44397/us-central1/api/getsurveyorg?surveyId=${surveyId}`;
    //const apiUrl = `https://us-central1-datapoint-44397.cloudfunctions.net/api/getsurveyorg?surveyId=${surveyId}`;
     //this.apiURL
     const URL = `${this.apiURL}getsurveyorg?surveyId=${surveyId}`;
    try {
      const response = await fetch(URL);

      if (!response.ok) {
        // Handle API errors
        const errorData = await response.json();
        this.errorMessage = errorData.message || 'Failed to fetch organization details.';
        return;
      }

      // Parse and set organization data
      const data = await response.json();
      this.organization = data.organization;
    } catch (error) {
      console.error('Error fetching survey organization:', error);
      this.errorMessage = 'An error occurred while fetching the data.';
    }
  }
  



  setDefaultLanguage() {
    // const browserLanguage = navigator.language.slice(0, 2);
    // Map of languages
    this.languageMap = { en: "English", es: "Spanish", zh: "Chinese" };

    // List of available language keys
    this.languages = Object.keys(this.languageMap);

    // Detect browser language and set default
    const browserLanguage = navigator.language.slice(0, 2);
    this.language = this.languages.includes(browserLanguage)
      ? browserLanguage
      : this.languages[0]; // Default to the first language if not found
  }

  handleNameChange(event) {
    this.name = event.target.value;
  }

  handleLanguageChange(event) {
    this.language = event.target.value;
    this.requestUpdate();
  }

  async handleSelectionChange(event) {
    const selectedValue = event.target.value;

    console.log('Selected value:', selectedValue);

    try {
      // Define the survey data to be updated
      const updatedSurveyData = {
        question1: selectedValue, // Update the field with the selected value
      };

      // Reference to the specific survey document
      const surveyRef = doc(db, 'responses', this.surveyId); // Replace 'submit-surveys' and this.surveyId with appropriate values

      // Update the document in Firestore
      await updateDoc(surveyRef, updatedSurveyData);

      console.log('Survey record updated successfully!');
      this.showSuccessPopup('Your response has been recorded.'); // Show success feedback
    } catch (error) {
      console.error('Error updating survey record:', error);
      this.showSuccessPopup('Failed to record your response. Please try again.');
    }
  }


  getQuestionByLang(lang) {
    const question = this.questions[0]; // For demonstration, using the first question
    return {
      question: question.question[lang] || question.question.en, // Default to English if lang not found
      options: question.response_options[lang]?.options || question.response_options.en.options, // Default to English options
    };
  }

  handleValidationFailed(event) {
    const errorMessage = event.detail;
    console.log("atrun"+errorMessage); // Show error message in the console or UI
    // You can disable the submit button here if needed.
  }

  

  // validateCurrentQuestion(currentQuestionId) {
  //   console.log("Validating Current Question:", currentQuestionId);
  //   console.log("Validating Current Question:", this.responses); 
  //   // Check if the current question has a valid response
  //   const currentResponse = this.responses[currentQuestionId];
  //   console.log("Response for Current Question:", currentResponse);
  
  //   // Validation: Response must exist and not be empty
  //   let isAnswered = false;

  //   if (Array.isArray(currentResponse)) {
  //     // If it's an array, check that all items have non-empty values
  //     isAnswered = (
  //       currentResponse.length > 0 &&
  //       currentResponse.every((item) => item.value && item.value.trim() !== '')
  //     );
  //   } else if (typeof currentResponse === 'string') {
  //     // If it's a string, check that it's non-empty and not just whitespace
  //     isAnswered = currentResponse.trim() !== '';
  //   }

    
  //   if (!isAnswered) {
  //     console.error(`Current question (ID: ${currentQuestionId}) has not been answered.`);
  //   }
  
  //   return isAnswered;
  // }

  validateCurrentQuestion(currentQuestionId) {
    console.log("Validating Current Question:", currentQuestionId);
    console.log("Validating Current Question:", this.responses);
  
    // Get the current response for the question
    const currentResponse = this.responses[currentQuestionId];
    console.log("Response for Current Question:", currentResponse);
  
    // Validation: Response must exist and not be empty
    let isAnswered = false;
  
    if (Array.isArray(currentResponse)) {
      // Check if it's an array of strings or objects
      isAnswered = currentResponse.length > 0 && currentResponse.every((item) => {
        if (typeof item === 'string') {
          // Validate non-empty strings
          return item.trim() !== '';
        } else if (typeof item === 'object' && item !== null) {
          // Validate objects with title and value properties
          return (
            'title' in item &&
            'value' in item &&
            typeof item.value === 'string' &&
            item.value.trim() !== ''
          );
        }
        return false;
      });
    } else if (typeof currentResponse === 'string') {
      // If it's a string, check that it's non-empty and not just whitespace
      isAnswered = currentResponse.trim() !== '';
    }
  
    if (!isAnswered) {
      console.error(`Current question (ID: ${currentQuestionId}) has not been answered.`);
    }
  
    return isAnswered;
  }
  

  


  /* Events for questions */

 // Validate that all questions are answered
 validateForm() {


  if (!this.validateCurrentQuestion(this.currentQuestionId)) {
    alert("Please answer the current question before proceeding.");
    return 0;
  }
  else{
    return 1;
  }
  
}




  handleDropdownChange(e, questionId) {
  // Capture the value from the event detail
  const selectedValue = e.detail.value;
 
  

  if (selectedValue === '') {
    console.log('Please select a valid option!');
    this.showErrorMessage = true;
    // Optionally, show a message or mark the field as invalid
  }

   

      // if(this.showErrorMessage){
      //   return;
      // }

      this.responses[questionId] = selectedValue; // Store the response
      console.log("Responses updated:", this.responses);
    
      
    
  // Update the responses object with the selected value for the given questionId
  // this.responses[questionId] = selectedValue;

  // console.log("Responses updated:", this.responses);

  // // Check if the questionId already exists in surveyResponses
  // const existingResponseIndex = this.surveyResponses.findIndex(response => response.questionId === questionId);

  // if (existingResponseIndex !== -1) {
  //   // If the questionId exists, replace the answer with the new selected value
  //   this.surveyResponses[existingResponseIndex].answer = selectedValue;
  // } else {

  //   this.responses[questionId] = selectedValue; // Store the response


  //   // If the questionId doesn't exist, add a new response to surveyResponses
  //   this.surveyResponses.push({
  //     questionId: questionId,
  //     answer: selectedValue
  //   });
  // }

  // Optionally, log for debugging
  // console.log('Dropdown value selected:', selectedValue);
  // console.log('Updated surveyResponses:', JSON.stringify(this.surveyResponses));

  // Trigger a re-render of the component
  this.requestUpdate();

  }

  handleRadioChange(e, questionId) {
    const selectedOption = e.detail; // This will be the option selected from the child
    this.responses[questionId] = selectedOption; // Store the selected answer for the questionId
  
    // Log the responses for debugging
    console.log('Selected option for question', questionId, ':', selectedOption);
    console.log('Current responses:', this.responses);
  
    // Optionally, update survey responses or handle the data as needed
    // this.surveyResponses = [
    //   ...this.surveyResponses.filter(response => response.questionId !== questionId), // Remove previous response for this questionId
    //   { questionId, answer: selectedOption }, // Add the new answer
    // ];
    console.log('Updated surveyResponses:', JSON.stringify(this.surveyResponses));
    this.requestUpdate();
  }

  handleTextAreaChange(e,questionId){
    const selectedOption = e.detail.value; 
    this.responses[questionId] = selectedOption; // Store the selected answer for the questionId
  
    // Log the responses for debugging
    console.log('Selected option for question', questionId, ':', selectedOption);
    console.log('Current responses:', this.responses);
    console.log('Updated surveyResponses:', JSON.stringify(this.surveyResponses));
    this.requestUpdate();

  }

   // Handle validation status changes from the child
   handleValidationChange(event,questionId) {
    //this.isRankingValid = event.detail.valid;

  
    //selectedValues: event.detail.selectedValues;
    const selectedOption = event.detail.selectedValues;
    this.responses[questionId] = selectedOption; // Store the selected answer for the questionId
    console.log('Selected option for question', questionId, ':', selectedOption);
    console.log('Current responses:', this.responses);
    console.log('Updated surveyResponses:', JSON.stringify(this.surveyResponses));
    this.requestUpdate();
  }


  handleLikertResponse(e,questionId) {
    console.log('Responses from Likert Scale:', e.detail);
    const selectedOption = e.detail; // This will be the option selected from the child
    this.responses[questionId] = selectedOption; // Store the selected answer for the questionId
  
    // Log the responses for debugging
    console.log('Selected option for question', questionId, ':', selectedOption);
    console.log('Current responses:', this.responses);
  
    // Optionally, update survey responses or handle the data as needed
    // this.surveyResponses = [
    //   ...this.surveyResponses.filter(response => response.questionId !== questionId), // Remove previous response for this questionId
    //   { questionId, answer: selectedOption }, // Add the new answer
    // ];
    console.log('Updated surveyResponses:', JSON.stringify(this.surveyResponses));
    this.requestUpdate();
    
  }

  handleOptionsChanged(event,questionId) {
    const { selectedOptions } = event.detail;
  
    // You can now handle the selected options
    console.log('Selected options:', selectedOptions);
    this.responses[questionId] = selectedOptions; // Store the selected answer for the questionId

    console.log('Updated surveyResponses:', JSON.stringify(this.surveyResponses));
    this.requestUpdate();

  
    // Example: Perform validation if needed
    const multipleAnswersComponent = this.shadowRoot.querySelector('multiple-answers');
    if (!multipleAnswersComponent.validate()) {
      console.error('Validation failed: Please select at least one option.');
    }
  }

  handleValidation(e,questionId) {
    const { value } = e.detail;
    if (value) {
      console.log('Validation succeeded. Selected value:', value);
      this.responses[questionId] = value; // Store the selected answer for the questionId
      // Proceed with further actions
      console.log('Updated surveyResponses:', JSON.stringify(this.surveyResponses));
      this.requestUpdate();
    } else {
      console.error('Validation failed. Please select a value.');
    }
  }




  




  renderQuestionPageContent() {

    // Ensure `this.questions` contains the dynamic data for the survey

    
    // console.log("this.currentQueIndex" + this.currentQueIndex);
    // console.log("this.currentQueIndex" + this.questions);

    // console.log( "this.currentQueIndex"+JSON.stringify(this.questions, null, 2));
    this.currentQuestionId = this.questions[this.currentQueIndex].id;

      //console.log( "this.currentQuestionIdx"+JSON.stringify( this.currentQuestionId, null, 2));
        //console.log( "this.questions"+JSON.stringify(this.questions, null, 2));

    const currentQuestion = this.questions[this.currentQueIndex];
    // console.log("currentQuestion" + currentQuestion);
    if (!currentQuestion) {
      return html`<p>No more questions.</p>`;
    }
   

    // Render based on the type of the current question
    switch (currentQuestion.response_type) {
      case 'likert':
        return html`<div class="qtnBox">
                            <likert-scale 
                              label="${currentQuestion.title[this.language] || question.title.en }" 
                              .options="${currentQuestion.response_options[this.language]?.options || question.response_options.en.options }" 
                              .questions="${currentQuestion.question[this.language] || question.question.en }"
                              @validation-failed="${this.handleValidationFailed}"
                              @response-changed="${(e) => this.handleLikertResponse(e, currentQuestion.id)}">
                             

                              
                              >
                            </likert-scale>

                            
                      </div>`;
      case 'ranking-choice':
        return html` <div class="qtnBox">
      
      <ranking-component
         label="${currentQuestion.question[this.language] || question.question.en }"
         .activities="${currentQuestion.response_options[this.language]?.options || question.response_options.en.options }" 
        @validation-changed="${(e) => this.handleValidationChange(e, currentQuestion.id)}"
      ></ranking-component></div>`;


     
     
    
      case 'ranking':
        return html`<div class="qtnBox"><ranking-component
      label="being your least favorite activity, 5 being your favorite."
      .activities=${this.activities}
    ></ranking-component></div>`;
      case 'select':
        return html`
                   <div class="qtnBox">
                      <dp-checkbox id="part-checkbox" title="${currentQuestion.question}" 
                          .options="${currentQuestion.options}"
                          @checkbox-change="${this.handleCheckboxChange}">
                      </dp-checkbox>
                    </div>`;
      case 'numeric-range':
        return html`
                  <rating-scale
                    label="${currentQuestion.question[this.language] || question.question.en }"
                    minLabel=${currentQuestion.response_options[this.language]?.options[0] || question.response_options.en.options[0] }
                    maxLabel=${currentQuestion.response_options[this.language]?.options[1] || question.response_options.en.options[1] }
                    minValue="${currentQuestion.minValue}"
                    maxValue="${currentQuestion.maxValue}"
                     @rating-changed="${(e) => this.handleValidation(e,currentQuestion.id)}"
                  ></rating-scale>`;
      case 'text-area':
        return html`<div class="qtnBox">
                        <p>${currentQuestion.question[this.language] || question.question.en }</p>
                        

                          <dp-textarea
        id="answer"
        rows="4"
        cols="50"
         @value-changed="${(e) => this.handleTextAreaChange(e, currentQuestion.id)}"
       
      ></dp-textarea>


                      </div>`;
      case 'text':
        return html`
                    <div class="qtnBox">
                    <p>${currentQuestion.question[this.language] || question.question.en }</p>
                        <dp-text></dp-text>
                    </div>`;
      case 'number':
        return html`
                    <div class="qtnBox">
                    <p>${currentQuestion.question[this.language] || question.question.en }</p>
                          <dp-number></dp-number>
                    </div>`;
      case 'single-select':
        return html`  <div class="qtnBox">
                       <radio-buttons
  .question="${currentQuestion.question[this.language] || currentQuestion.question.en}"
  .options="${currentQuestion.response_options[this.language]?.options || currentQuestion.response_options.en.options}"
  @option-selected="${(e) => this.handleRadioChange(e, currentQuestion.id)}"
></radio-buttons>
                      </div>`;
      case 'multi-select':
        return html`<div class="qtnBox">
                    
                          
                          <multiple-answers
  .label="${currentQuestion.question[this.language] || question.question.en }"
  .options="${currentQuestion.response_options[this.language]?.options || question.response_options.en.options }"
  @options-changed="${(e) => this.handleOptionsChanged(e,currentQuestion.id)}"
></multiple-answers>

</div>`;
      case 'ranked-choice':
        return html` <div class="qtnBox">
                          <!-- <likert-scale label="Likert Scale"
                                .options=${[
            'Strongly Disagree',
            'Disagree',
            'Neutral',
            'Agree',
            'Strongly Agree'
          ]}
                              .questions=${[
            'I am good at learning.',
            'I can get better at studying effectively.',
            'I am kind towards myself when I make mistakes.',
            'Making mistakes is a part of growing.',
            'Spending time in this program helps me be more kind to myself.',
            'I believe that there is more to learning than the grade I get.',
            'I believe that I can learn how to have a growth mindset.'
          ]}>
                          </likert-scale> -->
                        </div>`;
      case 'dropdown': return html`<div class="qtnBox">
      <dp-dropdown
            id="dropdown-${currentQuestion.id}"
            .title="${currentQuestion.question[this.language] || currentQuestion.question.en}"
            .options="${currentQuestion.response_options[this.language]?.options || currentQuestion.response_options.en.options}"
            @dropdown-change="${(e) => this.handleDropdownChange(e, currentQuestion.id)}"
          ></dp-dropdown>


  </div>`;
      default:
        return html`<p>Unsupported question type: ${currentQuestion.response_type}</p>`;
    }

  }

  // async handleSubmit() {

  //   const isValid = this.validateForm();
  //   console.log("isValid"+isValid);

  //   if(isValid === 0){
  //     this.showErrorMessage = true;
  //     this.requestUpdate();
  //     return;
  //   }
  //   else{

  //     this.showErrorMessage = false;
     
    

     

    
  //   //console.log("Final Survey Data:", JSON.stringify([surveyWithAnswers], null, 2));


 
  //   if (this.currentQueIndex < this.questions.length - 1) {

  //        // Construct the `answers` array
  //   const answers = Object.keys(this.responses).map((questionId) => ({
  //     questionId,
  //     answer: this.responses[questionId],
  //   }));
  //   // Add `answers` to the survey data
  //   let surveyWithAnswers = { ...this.surveyResponses, answers };

  //     this.currentQueIndex++;
  //     this.page += 1;
  //     this.getCurrentQuestion();
  //     this.requestUpdate();
  //   } else {

  //          // Construct the `answers` array
  //   const answers = Object.keys(this.responses).map((questionId) => ({
  //     questionId,
  //     answer: this.responses[questionId],
  //   }));
  //   // Add `answers` to the survey data
  //   let surveyWithAnswers = { ...this.surveyResponses, answers };


  //     console.log('Survey Complete:', this.responses);
  //     console.log("this.surveyResponses" + JSON.stringify(surveyWithAnswers, null, 2));
      

  //     const apiUrl = 'http://127.0.0.1:5001/datapoint-44397/us-central1/api/submitSurvey';
    
  //   this.isLoading = true; // Set loading state to true
  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({surveyWithAnswers }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Error: ${response.statusText}`);
  //     }

  //     const data = await response.json();
  //     console.log('Survey submitted successfully:', data);
  //     alert('Survey submitted successfully!');
  //   } catch (error) {
  //     console.error('Error submitting survey:', error);
  //     alert('Failed to submit the survey. Please try again.');
  //   } finally {
  //     this.isLoading = false; // Reset loading state
  //     this.step = "complete";
  //     this.requestUpdate();
  //   }


     

      
  //     // Optionally reset or show a "thank you" message
  //   }


    

  //   }
  // }

  async handleSubmit(event) {

    event.preventDefault();
    let isValid = false;
    const likertScale = this.shadowRoot.querySelector('likert-scale');
    
    if (likertScale) {
       isValid = likertScale.validateResponses();
  
      if (!isValid) {
        console.log("Form submission blocked due to validation failure.");
        // Optionally, prevent form submission or provide user feedback here
      }
    } else {
      isValid = this.validateForm();
      console.log("isValid", isValid);
      console.log('Likert scale component is not found in the DOM.');
    }

  
    
  
    // Show error message if validation fails
    if (!isValid) {
      this.showErrorMessage = true;
      this.requestUpdate();
      return;
    } else {
      this.showErrorMessage = false;
    }
  
    // Construct the `answers` array
    const answers = Object.keys(this.responses).map((questionId) => ({
      questionId,
      answer: this.responses[questionId],
    }));
  
    // Add `answers` to the survey data
    const surveyWithAnswers = { ...this.surveyResponses, answers };
  
    // Check if there are more questions
    if (this.currentQueIndex < this.questions.length - 1) {
      this.currentQueIndex++;
      this.page += 1;
      this.getCurrentQuestion();
      this.requestUpdate();
    } else {
      // Final submission when all questions are answered
      //console.log("Survey Complete:", this.responses);
      //console.log("Final Survey Data:", JSON.stringify(surveyWithAnswers, null, 2));
  
      //const apiUrl = 'http://127.0.0.1:5001/datapoint-44397/us-central1/api/submitSurvey';
      //const apiUrl = 'https://us-central1-datapoint-44397.cloudfunctions.net/api/submitSurvey';
      const URL = `${this.apiURL}submitSurvey`;


      this.isLoading = true; // Set loading state to true
      try {
        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(surveyWithAnswers),
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        const data = await response.json();
        console.log('Survey submitted successfully:', data);
        alert('Survey submitted successfully!');
      } catch (error) {
        console.error('Error submitting survey:', error);
        alert('Failed to submit the survey. Please try again.');
      } finally {
        this.isLoading = false; // Reset loading state
        this.step = "complete";
        this.requestUpdate();
      }
    }
  }

  

  handleOptionChange(e, id) {
    this.responses = { ...this.responses, [id]: e.target.value };
  }

  handleFeedbackInput(e, id) {
    this.feedback = { ...this.feedback, [id]: e.target.value };
  }

  handleFeedbackSubmit() {
    this.handleSubmit();
  }


  async submitSurvey() {
   // const apiUrl = 'http://127.0.0.1:5001/datapoint-44397/us-central1/api/submitSurvey';

   const URL = `${this.apiURL}submitSurvey'`;
    
    this.isLoading = true; // Set loading state to true
    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ responses: this.surveyResponses }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Survey submitted successfully:', data);
      alert('Survey submitted successfully!');
    } catch (error) {
      console.error('Error submitting survey:', error);
      alert('Failed to submit the survey. Please try again.');
    } finally {
      this.isLoading = false; // Reset loading state
    }
  }

  

  async getSurveyQuestions(referenceArray) {

  //const URL =  "http://127.0.0.1:5001/datapoint-44397/us-central1/api/getQuestionsWithDetails";
   //const URL =  "https://us-central1-datapoint-44397.cloudfunctions.net/api/getQuestionsWithDetails";
   const URL = `${this.apiURL}getQuestionsWithDetails`;


    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ referenceArray }),
    });
  
    if (response.ok) {
      const result = await response.json();
      console.log('Fetched Questions:', result.data);
      return result.data;
    } else {
      console.error('Error fetching questions:', response.statusText);
      
      return null;
    }
  }

  render() {
    if (this.isComplete) {
      return html`
      <header-component .currectSurveyorgName=${this.surveyOrgName}></header-component>
        <div class="container">
          <message-box
            title="Your survey is complete!"
            message="Thank you for your submission."
          ></message-box>
        </div>
      `;
    }

    return html`
    <header-component .currectSurveyorgName=${this.surveyOrgName}></header-component>
      <div class="container">
        ${this.surveyStatus === 'loading'
        ? html`<p class="status-message">Loading Survey ...</p>`
        : this.surveyStatus === 'error'
          ? html`
              <message-box
                title="Sorry! The link is invalid."
                message="${this.message}"
              ></message-box>
            `
          : html`
              ${this.step === 'initial' && this.surveyStatus == 'active'
              ? html`
                    <div class="survey-details">
                      <h1 class="survey-title">${this.surveyDetails.surveyTitle}</h1>
                      <p class="message">${this.message}</p>
                      <div class="qtnBox">
                      <div class="input-group">
                        <label for="name"> Name:</label>
                        <input
                          id="name"
                          type="text"
                          .value=${this.name}
                          @input=${this.handleNameChange}
                          placeholder="Enter your name"
                        />
                        
                      </div>
                      <div class="input-group">
                        <language-selector
                          .language="${this.language}"
                          @language-changed="${this._onLanguageChanged}"
                        ></language-selector>
                      </div>
                      </div>

                       ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}

                      <div class="button-container">
                        <button class="button" @click=${this.step1}>Save & Continue ></button>
                      </div>
                     
                    </div>
                  `
                  
              : this.step === 'survey' && this.surveyStatus == 'active'
                ? html`
                    <div class="survey-details">
                      <h1 class="survey-title">${this.surveyDetails.surveyTitle}</h1>
                      <p class="message">${this.message}</p>
                     <div class="survey-content">
                      ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}

                      ${this.renderQuestionPageContent()}

                      <div class="button-container">
                        <button class="button" @click=${this.goBack}>< Go Back</button>
                        <button class="button btnRight" ?disabled="${this.isLoading}" @click=${this.handleSubmit}>

                         
                        <p>${this.isLoading ? 'Submitting...' : ''}</p>
                        ${this.page === this.questions.length ? 'Save & Complete' : 'Save & Continue >'}
                        </button>

                        <!-- Error Message (conditionally rendered) -->
<p class="error-message" ?hidden="${!this.showErrorMessage}">Please answer the current question before proceeding.</p>

                      </div>
                      </div>
                    </div>`
                : ''

            }`
      }

      ${this.step === 'complete' ? html`<div class="survey-details">
                      <h1 class="survey-title">Thank you for taking the time to complete our survey! Your feedback is invaluable in helping us improve and better serve you. We greatly appreciate your input.</h1></div>` : ``}
        ${this.showPopup
        ? html`
              <div class="popup">
                <p>${this.popupMessage}</p>
              </div>
            `
        : ''}
      </div>

      ${this.surveyStatus !== 'active' ? html`<div class="qtnBoxMessage"><div class="survey-details">
                     
                      <h3 class="message validationmsg">${this.message}</h3></div></div>
                      
                      ` : ``}

      </div>

    `;
  }

}