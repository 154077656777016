import {LitElement, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import { textStyles } from './dp-text-css.js'; 

export class DpText extends LitElement {
  static get styles() {
    return [textStyles];
  }

  static get properties() {
    return {
      id: { type: String },
      rows: { type: String },
      cols: { type: String },
      title:{type:String}
    };
  }

  constructor() {
    super();
    this.id = 'text';
    this.rows = '4'; 
    this.cols = '50';
    this.title = '';
  }

  render() {
    return html`
    <div class="block-group">
      <label for="${this.id}" class="title">${this.title}</label>
      <input class="text-s" type="text" id="${this.id}" rows="${this.rows}" cols="${this.cols}"></input>
      </div>
    `;
  }
}

customElements.define('dp-text', DpText);
