import { LitElement, html, css } from 'lit';

export class RadioButtons extends LitElement {
  static properties = {
    question: { type: String },
    options: { type: Array },
    selectedOption: { type: String },
    lang:{ type: String },
    
  };

  static styles = css`
  :host {
    display: block;
    font-family: 'Arial', sans-serif;
    color: #333;
  }

  .container {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    text-align: center; /* Center the content */
  }

  .question {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
    color: #333;
  }

  .options {
    display: flex;
    justify-content: center; /* Center horizontally */
    flex-wrap: wrap;
    gap: 24px;
  }

  .option {
    display: ruby;
      align-items: center;
      gap: 10px;
  }

  label {
    font-size: 14px;
    color: #555;
    cursor: pointer;
  }

  input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid rgb(165, 0, 52);
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    
  }

  input[type='radio']:checked {
    background-color:rgb(165, 0, 52);
  }
`;

  constructor() {
    super();
    this.question = '';
    this.options = [];
    this.selectedOption = '';
    this.resetSelection();
   
  }

  firstUpdated() {

    this.selectedOption = '';
    this.resetSelection();
   }

   connectedCallback() {
    super.connectedCallback();
    // Reset selection when the component loads
    this.selectedOption = '';
    console.log('Component loaded, state reset.');
    this.resetSelection();
  }

    // Resets the selected option
    resetSelection() {
      this.selectedOption = '';
    }





  // getQuestionByLang(lang) {
  //   const question = this.questions[0]; // For demonstration, using the first question
  //   return {
  //     question: question.question[lang] || question.question.en, // Default to English if lang not found
  //     options: question.response_options[lang]?.options || question.response_options.en.options, // Default to English options
  //   };
  // }

  // handleSelection(option) {
  //   this.selectedOption = option;
  //   this.dispatchEvent(new CustomEvent('option-selected', { detail: option }));
  // }

  handleSelection(option) {
    this.selectedOption = option;
    // Dispatch the 'option-selected' event to notify the parent
    this.dispatchEvent(new CustomEvent('option-selected', { 
      detail: option, 
      bubbles: true, // Propagate the event up the DOM
      composed: true, // Allow event to cross shadow DOM boundaries
    }));
  }


  render() {
    return html`
      <div class="container">
        <div class="question">${this.question}</div>
        <div class="options">
          ${this.options.map(
            (option) => html`
              <div class="option">
                <input
                  type="radio"
                  name="radio-group"
                  id="${option}"
                  value="${option}"
                  @change="${() => this.handleSelection(option)}"
                  ?checked="${this.selectedOption === option}"
                />
                <label for="${option}">${option}</label>
              </div>
            `
          )}
        </div>
      </div>
    `;
  }
}

customElements.define('radio-buttons', RadioButtons);
