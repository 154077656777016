import {LitElement, css} from 'lit';
export const datesStyles = css`
  input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

      .block-group {
    display: grid
  }


    .title {
    color: var(--black, #000);
    font-family: Poppins, sans-serif;
    font-style: normal;
    line-height: 30px;
    margin-bottom: 8px; 
    font-weight: bold;
  }


`;
