import { LitElement, html, css } from 'lit';
import { auth,db } from '../../firebase-config'; // Import your Firebase config
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { customElement, property } from 'lit/decorators.js';
import { getDocs, collection, query, where ,doc} from 'firebase/firestore';
import '../../components/popup/reusable-popup';

@customElement('survey-dashboard-component')
export class SurveyDashboardComponent extends LitElement {
  @property({ type: Object }) user = null;
  
  static properties = {
    organizations: { type: Array },
    surveys: { type: Array },
    selectedOrg: { type: String }, // Store selected organizations
    activeTab: { type: String },
    selectedOption: { type: String },
  };
  @property({ type: Object }) isEditing = {
    name: false,
    image: false,
    memberIndex: null, // Track which member is being edited
  };
  @property({ type: String }) newOrganizationImage = '';
  @property({ type: String }) tempMemberName = ''; // Temp name for the editing member

  @property({ type: Object }) user = null;
  @property({ type: Array }) organizations = [];
  @property({ type: Array }) surveys = [];
  @property({ type: String }) selectedOrg = localStorage.getItem('selectedOrg') || '';
  @property({ type: String }) selectedOption = localStorage.getItem('selectedOrg') || '';
  @property({ type: Boolean }) isOrgLoaded = false;

  static styles = css`
  
  .container {
     
    padding:2%;
    max-width:1200px;
    margin: auto;
    width:90%
}
    }

    .content {
      display: flex;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
    }

    

    .sidebar button {
      width: 100%;
      padding: 15px;
      background-color: #f28b82;
      border: none;
      color: white;
      cursor: pointer;
      border-radius: 10px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .sidebar ul {
      list-style: none;
      padding: 0;
    }

    .sidebar ul li {
      margin: 20px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      border-radius: 8px;
    }

    .sidebar ul li span {
      margin-left: 10px;
      font-size: 16px;
    }

    .active-tab {
      background-color: #D9D9D980;
      font-weight: bold;
      border-left: 5px solid #f28b82;
    }

    .survey-table {
      flex: 1;
      border-collapse: collapse;
      
      
    }

    .survey-table th, .survey-table td {
      padding: 1px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      
    }

     .survey-table th, .survey-table td button{
      background:none;
      border:none;
          cursor: pointer;
      
    }

    .survey-table th, .survey-table :nth-child(3) {
        text-align: right;
        padding-right:20px;
    } 

    .survey-table thead tr th {
      background-color: #f4f4f4;
      font-weight: bold;
      padding:30px 0px 10px 10px;
    }

     .survey-table tbody tr td {
      padding:15px 0px 10px 10px;
    }

    .icon-button {
      cursor: pointer;
      margin-left: 10px;
    }

    /* General container */

/* Top Bar (Create Survey Button and Dropdown) */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.create-survey-btn {
  background-color: rgb(142, 73, 83);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.create-survey-btn i {
  margin-right: 10px;
}

.dropdown select {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Main content area (split into sidebar and content) */
.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/* Sidebar */
.sidebar {
  width: 140;
  padding: 20px;

  border-right: 1px solid #ccc;
}

.tabs {
  list-style: none;
  padding: 0;
}

.tab {
  display: flex;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.tab i {
  margin-right: 10px;
}

.active {
  font-weight: bold;
}

.count {
  background-color: #f28b82;
  color: white;
  border-radius: 12px;
  padding: 2px 6px;
  margin-left: 8px;
}

/* Right Content: Survey Data */
.content {
  flex: 1;
  padding: 20px;
  background-color: white;
  overflow-y: auto;
}

.survey-table {
  width: 100%;
  border-collapse: collapse;
}

.survey-table th, .survey-table td {
  padding: 1px;
  text-align: left;
}

.survey-table tr:hover {
  background-color: #f0f0f0;
}

.survey-table input[type="checkbox"] {
  margin-right: 10px;
}

td button svg {
    vertical-align: middle;
}

.survey-table td button:hover {
color:rgb(142, 73, 83);
}
.survey-table td button:hover {
color:rgb(142, 73, 83);
font-weight:bold;
}



button.edit-delete-btn {align-items: center; gap: 8px; border: none; background: none; cursor: pointer; background:none;
border:none;}
      
select {
      padding: 8px;
      font-size: 16px;
    }

    
  `;
    

  constructor() {
    super();
    this.user = null;
    this.organizations = [];
    this.surveys = [];
    this.activeTab = "active";
    this.selectedOrg = localStorage.getItem('selectedOrg') || ''; // Load from localStorage
    this.selectedOption = localStorage.getItem('selectedOrg') || ''; // Load from localStorage
    this.handleConfirm = this.handleConfirm.bind(this); // Bind event handler
    
  }


  // Helper function to fetch Firebase auth token
  async getAuthToken() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken(); // Get the ID token
    }
    throw new Error('User not authenticated');
  }


   // API call to create a survey
   async createSurvey() {
    try {
      const token = await this.getAuthToken();
      const response = await fetch('http://127.0.0.1:5001/datapoint-44397/us-central1/createSurvey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          title: this.surveyData.title,
          description: this.surveyData.description,
          organization: this.surveyData.organization,
        }),
      });

      if (!response.ok) throw new Error(await response.text());
      const result = await response.json();
      console.log('Survey created successfully:', result);
    } catch (error) {
      console.error('Error creating survey:', error);
    }
  }


   // API call to delete a survey by ID
   async deleteSurvey(surveyId) {
    try {
      const token = await this.getAuthToken();
      const response = await fetch(`http://127.0.0.1:5001/datapoint-44397/us-central1/deleteSurvey?surveyId=${surveyId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error(await response.text());
      const result = await response.json();
      console.log('Survey deleted successfully:', result);
    } catch (error) {
      console.error('Error deleting survey:', error);
    }
  }



  connectedCallback() {
    super.connectedCallback();
    this.checkAuth();
    // Use arrow function or bind `this`
    document.addEventListener('org-changed', this.handleDropOrgChange.bind(this));
    // Add event listener to the 'confirm' event
    document.addEventListener('confirmBtn', this.handleConfirm);
    this.fetchSurveysByOrg(this.selectedOption,'active'); 
  }

  disconnectedCallback() {
    // Clean up event listener
    document.removeEventListener('org-changed', this.handleDropOrgChange.bind(this));
    document.removeEventListener('confirmBtn', this.handleConfirm);
    super.disconnectedCallback();
  }

  handleDropOrgChange(event) {
    console.log('Event caught handleDropOrgChange:', event.detail.selectedOrgValue);
    const { selectedOrgValue } = event.detail;
    this.selectedOption = selectedOrgValue;
    console.log("selectedOrgValue",selectedOrgValue);
    console.log(" this.selectedOption ", this.selectedOption );
    this.fetchSurveysByOrg(selectedOrgValue,"active")
  }

  async fetchSurveysByOrg(orgId,filterByStatus = "active") {
    try {
        //const currentOrgId = orgId;
         const ActiveFilterByStatus = filterByStatus;
        //console.log("currentOrgId",currentOrgId);
        //console.log("ActiveFilterByStatus",ActiveFilterByStatus);
        const surveysRef = collection(db, 'surveys'); // Reference to the 'cities' collection
        const orgRef = doc(db, 'organizations', orgId); 
        const queryRef = query(
            surveysRef, 
            where('organization_id', '==', orgRef), // Filtering by organization_id (reference field)
            //where('is_deleted', '==', 'no') // Filtering by status
        );
        const querySnapshot = await getDocs(queryRef); // Get documents based on the query
         // Get documents based on the query
         const surveysList = [];
        // Check if documents exist
        if (querySnapshot.empty) {
           this.isOrgLoaded = false; // No organization found
            console.log("No surveys found for the specified organization and status.");
            //return; // Exit if no documents found
        }
          // Extract survey data from the query snapshot
          querySnapshot.forEach((doc) => {
            surveysList.push({ id: doc.id, ...doc.data() });
        });
        console.log("Fetched Surveys:", surveysList); // Log the fetched surveys
        this.surveys = surveysList; // Store the fetched survey data in the component's property
        this.isOrgLoaded = true; 
        this.requestUpdate();// Organization found
        // Additional log to confirm that organization data was found and processed
      console.log("Organization data loaded and component updated.");
      } catch (error) {
        console.error("Error fetching surveys: ", error);
        this.isOrgLoaded = false;
        this.requestUpdate();
      }
  }
  // Lifecycle method to capture property changes
  updated(changedProperties) {
    if (changedProperties.has('activeTab')) {
      this.requestUpdate();  // Explicitly trigger a re-render when tab changes
    }
  }

  // Handle tab change
  handleTabChange(tab) {
    this.activeTab = tab;
    this.requestUpdate(); // Ensure component updates after tab change
  }

  
  checkAuth() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.selectedTab = 'active'; // Default tab
        this.requestUpdate();
      } else {
        this.user = null;
        this.requestUpdate();
      }
    });
  }

  handleCreateSurvey() {
    //alert('Create Survey button clicked!');
    this.dispatchEvent(
        new CustomEvent("navigate-to-route", {
          detail: { routePath: "/create-survey" },
          bubbles: true,
          composed: true,
        })
      );
  }


//   homeSurvey() {
    
//     console.log('homeSurvey button clicked');
  
//       this.dispatchEvent(
//         new CustomEvent("navigate-to-route", {
//           detail: { routePath: "/my-survey" },
//           bubbles: true,
//           composed: true,
//         })
//       );
// }
    // SurveyDashboard() {
    
    //     console.log('homeSurvey button clicked');
      
    //       this.dispatchEvent(
    //         new CustomEvent("navigate-to-route", {
    //           detail: { routePath: "/survey" },
    //           bubbles: true,
    //           composed: true,
    //         })
    //       );
    //     }

  
        handleEdit(survey) {
          console.log('Edit survey:', survey.title);
          alert(`Editing ${survey.name}`);
          // Add logic for editing here
        }
    
        handleDelete(survey) {
          console.log('Delete survey:', survey.title);
          this.surveys = this.surveys.filter((s) => s.id !== survey.id);
          
        }

  capitalizeFirstLetter(str) {
    if (!str) return ''; // Handle empty string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  
   // Event handler function
   handleConfirm(event) {
    const message = event.detail.message;  // Access any data passed with the event
    const id = event.detail.id; 
    const action = event.detail.action; 

    if(action == 'edit')
      {
        console.log('Confirm event triggered:', id+action);
        
      } else if(action == 'delete'){
          console.log('Confirm event triggered:', id+action);
      } else {
          console.log('Confirm event triggered:', id+action);
      }
    // Add any logic you want to execute when 'confirm' is triggered
  }


  openDeletePopup(surveyid) {
    const popup = this.shadowRoot.querySelector('#delete-popup');
    //popup.message = `Are you sure you want to delete the survey with ID: ${surveyid}?`; // Use the survey ID in the message
    popup.message = `Are you sure you want to delete the survey ?`; // Use the survey ID in the message
    
    popup.confirmText = 'Delete';
    popup.cancelText = 'Cancel';
    popup.id = surveyid; // Attach surveyid for further use (e.g., delete operation)
    popup.action = 'delete'; // Attach surveyid for further use (e.g., delete operation)
    popup.open();
  }

  openEditPopup(surveyid) {
    const popup = this.shadowRoot.querySelector('#edit-popup');
    //popup.message = `Are you sure you want to edit the survey with ID: ${surveyid}?`;
    popup.message = `Are you sure you want to edit the survey ?`;
    popup.confirmText = 'Edit';
    popup.id = surveyid; // Attach surveyid for further use (e.g., edit operation)
    popup.action = 'edit'; // Attach surveyid for further use (e.g., edit operation)
    popup.open();
  }


  render() {

   // Filter surveys based on the active tab
   const filteredSurveys = this.surveys.filter(survey => survey.status === this.activeTab);

    return html`
      <header-component></header-component>
      <main>
        <div class="main">
          <h2 style="text-align:center">Welcome to Data Point Studio</h2>
          <div class="container">
 ${this.isOrgLoaded
          ? html``
          : html``
        }
    
        ${this.user && (this.selectedOrg || this.selectedOrg != '') 
        ? html`<div class="top-bar">
                    <button @click="${this.handleCreateSurvey}" class="create-survey-btn">
                         <i class="fas fa-pencil-alt"></i> Create Survey</button>
                </div>
                <div class="main-content">
                     <!-- Left Sidebar: Menus -->
                     <div class="sidebar">
                        <ul class="tabs">
                            <li class="${this.activeTab === 'active' ? 'active-tab' : ''}" @click="${() => this.handleTabChange('active')}">
                                 <svg width="28" height="30" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title/><path d="M16,12a1,1,0,0,1-.49.86l-5,3A1,1,0,0,1,10,16a1,1,0,0,1-.49-.13A1,1,0,0,1,9,15V9a1,1,0,0,1,1.51-.86l5,3A1,1,0,0,1,16,12Z" fill="#464646"/><path d="M21.92,5.09a1,1,0,0,0-1.07.15L19.94,6A9.84,9.84,0,0,0,12,2a10,10,0,1,0,9.42,13.33,1,1,0,0,0-1.89-.66A8,8,0,1,1,12,4a7.87,7.87,0,0,1,6.42,3.32l-1.07.92A1,1,0,0,0,18,10h3.5a1,1,0,0,0,1-1V6A1,1,0,0,0,21.92,5.09Z" fill="#464646"/></svg><span>Active</span>
                                    <span>(${this.surveys.filter(survey => survey.status === 'active').length})</span>
                            </li>
            
                            <li class="${this.activeTab === 'draft' ? 'active-tab' : ''}" @click="${() => this.handleTabChange('draft')}">
                                  <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_1346_1596)"><path d="M23.99 9.5C23.99 8.78 23.62 8.15 23.05 7.8L14 2.5L4.95 7.8C4.38 8.15 4 8.78 4 9.5V19.5C4 20.6 4.9 21.5 6 21.5H22C23.1 21.5 24 20.6 24 19.5L23.99 9.5ZM21.99 9.5V9.51L14 14.5L6 9.5L14 4.82L21.99 9.5ZM6 19.5V11.84L14 16.86L21.99 11.87L22 19.5H6Z" fill="black"/></g><defs><filter id="filter0_d_1346_1596" x="-2" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1346_1596"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1346_1596" result="shape"/></filter></defs></svg>
                                    <span>Draft</span><span>(${this.surveys.filter(survey => survey.status === 'draft').length})</span>
                            </li>
                            <li class="${this.activeTab === 'closed' ? 'active-tab' : ''}" @click="${() => this.handleTabChange('closed')}">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 16V2C16 0.9 15.1 0 14 0H4C2.9 0 2 0.9 2 2V16H0V18H18V16H16ZM14 16H4V2H14V16Z" fill="black"/><path d="M8 8H6V10H8V8Z" fill="black"/></svg>
                                    <span>Closed</span><span>(${this.surveys.filter(survey => survey.status === 'archived').length})</span>
                            </li>
                            <li class="${this.activeTab === 'archived' ? 'active-tab' : ''}" @click="${() => this.handleTabChange('archived')}">
                                     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 16.125V0.125H2V16.125H0V18.125H11.25C11.25 19.095 12.03 19.875 13 19.875C13.97 19.875 14.75 19.095 14.75 18.125H20V16.125H18ZM16 8.125H14V6.125H16V8.125ZM12 8.125H4V6.125H12V8.125ZM12 10.125V12.125H4V10.125H12ZM14 10.125H16V12.125H14V10.125ZM16 4.125H14V2.125H16V4.125ZM12 2.125V4.125H4V2.125H12ZM4 16.125V14.125H12V16.125H4ZM14 16.125V14.125H16V16.125H14Z" fill="black"/></svg>
                                     <span>Archived</span><span>(${this.surveys.filter(survey => survey.status === 'archived').length})</span>
                             </li>
                        </ul>
                     </div>
                
                     <!-- Right Content: Survey Data List -->
                        <div class="content">
                        
                            <!-- Survey Table   <dp-checkbox id="scope-checkbox" '></dp-checkbox> -->
                                 <table class="survey-table">
                                ${filteredSurveys.length > 0 ? html`<thead>
        <tr>
            <th>#</th> <!-- Row number column -->

            <th>Title</th>
            <th>Actions</th>
        </tr>
    </thead> ` : html` <p></p>`}
                                    <tbody>
                                    ${filteredSurveys.length > 0 ? html`${filteredSurveys.map((survey, index) => html`
                                        <tr>
                                        <td>${index + 1}</td> 
                                            <td> 
                                            ${survey.title}
                                          </td>
               
                                            <td>
                                            ${survey.status == 'draft' ? html` <button @click="${() => this.openEditPopup(survey.id,'edit')}">

                                               
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 16L11 20H21V16H15Z" fill="black"/><path d="M12.06 7.19L3 16.25V20H6.75L15.81 10.94L12.06 7.19ZM5.92 18H5V17.08L12.06 10.02L12.98 10.94L5.92 18Z" fill="black"/><path d="M18.71 8.04C19.1 7.65 19.1 7.02 18.71 6.63L16.37 4.29C16.17 4.09 15.92 4 15.66 4C15.41 4 15.15 4.1 14.96 4.29L13.13 6.12L16.88 9.87L18.71 8.04Z" fill="black"/></svg>
                                                        <span>Edit</span>
                                                </button>`:''}

                                             ${survey.status == 'draft' ? html`   <button @click="${() => this.openDeletePopup(survey.id,'delete')}">
                                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z" fill="black"/></svg>
                                                    <span>Delete</span> </button>`:''}
                                             </td>
                                         </tr>
                                    `)}` : html` <tr> <td colspan="3"> No ${this.activeTab} surveys are available for this organization at the moment. <br /> Please check back later or contact your administrator for more information</td></tr>`}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
               
          `
        : html` 

        ${!this.selectedOrg || this.selectedOrg == ''  ? html` <message-box
            title="No Organization Assigned"
            message="Please contact the admin for assistance."
          ></message-box> ` : html` <p>You are not logged in. Please <a href="/login">login</a> to manage your organization.</p>`}

        
        
        
       `
      }
      </div></div>

        

      </main>
      
            <reusable-popup
            id="delete-popup"
            @confirm=${this.handleDelete}
            @cancel=${() => console.log('Delete canceled')}
          ></reusable-popup>

          <reusable-popup
            id="edit-popup"
            @confirm=${this.handleEdit}
            @cancel=${() => console.log('Edit canceled')}
          ></reusable-popup>

      <footer-component></footer-component>
    `;
  }
}