import { LitElement, html } from 'lit';
import { emailStyles } from './dp-email-css';

export class DpEmail extends LitElement {
  static get styles() {
    return [ emailStyles ];
  }

  static get properties() {
    return {
      id: {type: String},
      pattern: {type: String},
      required: {type: Boolean}
    };
  }

  // Pattern example:".+@beststartupever\.com"
  constructor() {
    super();
    this.id = '';
    this.pattern = '';
    this.required = false;
  }

  render() {
    return html`
        <input
            class="email-box"
            type="email"
            id=${this.id}
            pattern=${this.pattern}
            ?required=${this.required}
            title="Please correct error"
            placeholder="username@domain.com"
        >            
        </input>
    `;
  }
}

customElements.define('dp-email', DpEmail);
