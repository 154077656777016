import { LitElement, html, css } from 'lit';
import Chart from 'chart.js/auto';

export class ScatterPlot extends LitElement {
  static styles = css`
    .chart-container {
      width: 100%;
      max-width: 600px;
      margin: auto;
      padding: 16px;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }
    canvas {
      display: block;
    }
  `;

  firstUpdated() {
    const ctx = this.renderRoot.querySelector('#scatterChart').getContext('2d');
    new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [
          {
            label: 'Scatter Dataset',
            data: [
              { x: 1, y: 2 },
              { x: 2, y: 3 },
              { x: 3, y: 4 },
              { x: 4, y: 6 },
              { x: 5, y: 7 },
            ],
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
        },
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            title: {
              display: true,
              text: 'X-Axis',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Y-Axis',
            },
          },
        },
      },
    });
  }

  render() {
    return html`
    <h1>Scatter Plot</h1>
      <div class="chart-container">
        <canvas id="scatterChart"></canvas>
      </div>
    `;
  }
}

customElements.define('scatter-plot', ScatterPlot);
