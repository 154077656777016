export const defaultlinkConfig = {
  applicationLinks: {
    'Home': '/home',
    'Login': '/login',
    'Signup': '/signup',
    'survey': '/survey',
    'Forgot Password': '/forgot-password',
    'My Account':'/my-account'
  },
  externalLinks: {
    'Google': 'http://google.com',
    'The Verge': 'https://theverge.com'
  }
};

export const defaultRouterConfig = {
  routes: {
    '/': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'landing.html', active: false },
    '/login': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'login.html', active: false },
    '/signup': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'signup.html', active: false },
    '/forgot-password': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'forgot-password.html', active: false },
    '/my-account': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'my-account.html', active: false },
    '/manage-org': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'manage-org-account.html', active: false },
    '/my-survey': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'my-survey.html', active: false },
    '/surveyList': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'survey-dashboard.html', active: false },
    '/create-survey': { title: 'CHANGE-DEFAULT-ROUTER-CONFIG', content: 'create-survey.html', active: false },
    '/survey-questions': { title: 'Choose the Survey Questions', content: 'survey-questions.html', active: false },
     "/complete": { title: 'Survey Not Found', content: 'survey-error.html' }, // Fallback route
    '/complete/:surveyId': { title: 'Complete Survey', content: 'public-survey.html', active: false },

    "/survey": { title: 'Survey', content: 'home.html', active: false },
    '/survey-publish': { title: 'Publish Your Survey', content: 'survey-publish-component.html', active: false },
  // Reports Routes 
    "/reports": { title: "Report Home", content: "report-home.html", active: false },
    '/404': { title: 'Not Found ', content: '404.html', active: false },
    '*': { title: 'Not Found', content: 'not-found.html', active: false }
  }
};