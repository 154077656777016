import { LitElement, html, css } from 'lit';

export class MultipleAnswers extends LitElement {
  static properties = {
    label: { type: String },
    options: { type: Array },
    selectedOptions: { type: Array },
  };

  static styles = css`
    :host {
      display: block;
      font-family: 'Arial', sans-serif;
      color: #333;
    }

    .multiple-component {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .question {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
    }
    
    .options-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 10px;
    }

    .option {
      display: ruby;
      align-items: center;
      gap: 10px;
    }

    .option input[type='checkbox'] {
      width: 24px;
      height: 24px;
      border: 2px solid #a50034;
      border-radius: 3px;
      background-color: #fff;
      cursor: pointer;
    }

    .option input[type='checkbox']:checked {
      background-color: #a50034;
    }

    .option input[type='checkbox']:checked::after {
      content: '✔';
      color: white;
      font-size: 15px;
    }

    .option label {
      font-size: 14px;
      color: #333;
    }

    .error {
      color: red;
      font-size: 14px;
    }
  `;

  constructor() {
    super();
    this.question = 'How would you rate your experience with this program?';
    this.options = [];
    this.selectedOptions = [];
    this.errorMessage = ''; // Initialize error message
  }

  toggleOption(option) {
    if (this.selectedOptions.includes(option)) {
      this.selectedOptions = this.selectedOptions.filter((item) => item !== option);
    } else {
      this.selectedOptions = [...this.selectedOptions, option];
    }

    // Dispatch event when options change
    this.dispatchEvent(
      new CustomEvent('options-changed', { detail: { selectedOptions: this.selectedOptions } })
    );

    // Clear validation error if options are selected
    this.errorMessage = '';
  }

  validate() {
    if (this.selectedOptions.length === 0) {
      this.errorMessage = 'Please select at least one option.';
      return false;
    }
    this.errorMessage = ''; // Clear error if valid
    return true;
  }

  render() {
    return html`
      <div class="multiple-component">
        <div class="question">${this.label}</div>
        <div class="options-grid">
          ${this.options.map(
            (option) => html`
              <div class="option">
                <input
                  type="checkbox"
                  id="${option}"
                  .checked="${this.selectedOptions.includes(option)}"
                  @change="${() => this.toggleOption(option)}"
                />
                <label for="${option}">${option}</label>
              </div>
            `
          )}
        </div>
        <div class="error">${this.errorMessage ? this.errorMessage : ''}</div>
      </div>
    `;
  }
}

customElements.define('multiple-answers', MultipleAnswers);
