import { LitElement, html, css } from 'lit';

export class LikertScale extends LitElement {
  static properties = {
    questions: { type: Array },
    options: { type: Array },
    responses: { type: Object },
    label:{type:String},
    errorMessage: { type: String }
  };


  static styles = css`
    :host {
      display: block;
      font-family: 'Arial', sans-serif;
      color: #333;
    }

    .table-container {
      margin: 16px 0;
      border-collapse: collapse;
      width: 100%;
      background-color: #f7f7f7;
      border-radius: 8px;
      overflow: hidden;
    }

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
    }

    th {
      background-color: #f3f3f3;
      padding: 12px 16px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #666;
    }

    th:first-child {
      text-align: left;
    }

    td {
      padding: 16px;
      text-align: center;
      font-size: 14px;
    }

    tr:nth-child(even) {
      background-color: #eeeeee;
    }

    tr:nth-child(odd) {
      background-color: #ffffff;
    }

    .question {
      text-align: left;
      font-weight: 500;
    }

    input[type='radio'] {
      appearance: none;
      -webkit-appearance: none;
      width: 24px;
      height: 24px;
      border: 2px solid #a50034;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
    }

    input[type='radio']:checked {
      background-color: #a50034;
    }
  `;

  constructor() {
    super();
    this.questions = [];
    this.options = [];
    this.responses = {};
    this.label = '';
    this.errorMessage = '';

    
  }

  // handleResponse(question, value) {
  //   this.responses = { ...this.responses, [question]: value };
  //   this.dispatchEvent(new CustomEvent('response-changed', { detail: this.responses }));
  // }

  handleResponse(question, value) {
    this.responses = { ...this.responses, [question]: value };
    
    // Log the updated responses
    console.log('Updated responses:', this.responses);
    
    // Dispatch the event with updated responses
    this.dispatchEvent(new CustomEvent('response-changed', { detail: this.responses }));
  }



   // Validate if all questions have been answered
   validateResponses() {
    const unansweredQuestions = this.questions.filter(
      (question) => !this.responses[question]
    );

    if (unansweredQuestions.length > 0) {
      this.errorMessage = 'Please answer all questions.';
      this.dispatchEvent(new CustomEvent('validation-failed', { detail: this.errorMessage }));
      return false;
    }
    
    this.errorMessage = ''; // Clear error if all questions are answered
    return true;
  }

  render() {
    return html`
      <div class="table-container">
        <h2>${this.label}</h2>
        <table>
          <thead>
            <tr>
              <th></th>
              ${this.options.map((option) => html`<th>${option}</th>`)}
            </tr>
          </thead>
          <tbody>
            ${this.questions.map(
              (question) => html`
                <tr>
                  <td class="question">${question}</td>
                  ${this.options.map(
                    (option) => html`
                      <td>
                        <input
                          type="radio"
                          name="${question}"
                          value="${option}"
                          @change="${() => this.handleResponse(question, option)}"
                          ?checked="${this.responses[question] === option}"
                        />
                      </td>
                    `
                  )}
                </tr>
              `
            )}
          </tbody>
        </table>
        <!-- Show error message if any -->
        ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}
      
      </div>
    `;
  }
}

customElements.define('likert-scale', LikertScale);
