import { LitElement, html } from 'lit';
import { iconButtonStyles } from './icon-button-styles.js';
import { baseStyles } from "../../styles/base-styles.js";

import '@material/web/iconbutton/icon-button.js';
import '@material/web/iconbutton/outlined-icon-button.js';
import '@material/web/iconbutton/filled-icon-button.js';
import '@material/web/iconbutton/filled-tonal-icon-button.js';

export class IconButton extends LitElement {
    static properties = {
        type: { type: "standard" | "filled" | "filled-tonal" | "outlined" }, // 'standard', 'filled', 'filled-tonal', 'outlined'
        link: { type: String },
        toggle: { type: Boolean },
        selected: { type: Boolean },
        disabled: { type: Boolean },
        icon: { type: String },
        toggledIcon: { type: String }
    };

    static styles = [
        baseStyles,
        iconButtonStyles
    ];

    constructor() {
        super();
        this.toggle = false;
        this.selected = false;
        this.disabled = false;
    }

    renderButton() {
        const innerHtml = this.toggle ? 
            html`
                <q1-icon>${this.icon}</q1-icon>
                <q1-icon slot="selected">${this.toggledIcon}</q1-icon>`
        :    html`
                <q1-icon>${this.icon}</q1-icon>`;

        let buttonTemplate;

        switch (this.type) {
            case 'filled':
                buttonTemplate = html`<md-filled-icon-button ?toggle="${this.toggle}" ?disabled="${this.disabled}" href="${this.link}" target="${this.link}">
                    ${innerHtml}
                </md-filled-icon-button>`;
                break;
            case 'filled-tonal':
                buttonTemplate = html`<md-filled-tonal-icon-button ?toggle="${this.toggle}" ?disabled="${this.disabled}" href="${this.link}" target="${this.link}">
                    ${innerHtml}
                </md-filled-tonal-icon-button>`;
                break;
            case 'outlined':
                buttonTemplate = html`<md-outlined-icon-button ?toggle="${this.toggle}" ?disabled="${this.disabled}" href="${this.link}" target="${this.link}">
                    ${innerHtml}
                </md-outlined-icon-button>`;
                break;
            default:
                buttonTemplate = html`<md-icon-button ?toggle="${this.toggle}" ?disabled="${this.disabled}" href="${this.link}" target="${this.link}">
                    ${innerHtml}
                </md-icon-button>`;
                break;
        }

        return buttonTemplate;
    }

    render() {
        return html`
            ${this.renderButton()}
        `;
    }

}

// Define the new element
customElements.define('q1-icon-button', IconButton);
