import { LitElement, html, css } from 'lit';
import Chart from 'chart.js/auto';

export class LineChart extends LitElement {
  static styles = css`
    .chart-container {
      width: 100%;
      max-width: 600px;
      margin: auto;
      padding: 16px;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }
    canvas {
      display: block;
    }
  `;

  firstUpdated() {
    const ctx = this.renderRoot.querySelector('#lineChart').getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
          {
            label: 'Sales Over Time',
            data: [10, 20, 15, 30, 25],
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            tension: 0.4, // Smooth curve
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Sales',
            },
          },
        },
      },
    });
  }

  render() {
    return html`
      
      <h1>Line Chart</h1><div class="chart-container">
        <canvas id="lineChart"></canvas>
      </div>
    `;
  }
}

customElements.define('line-chart', LineChart);
