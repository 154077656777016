import { LitElement, html, css } from 'lit';
import { collection, query, where, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('survey-question-component')
export class SurveyQuestionComponent extends LitElement {
  @property({ type: Array }) questionBank = [];
  @property({ type: Array }) allQuestions = [];
  @property({ type: Array }) filteredQuestions = [];
  @property({ type: Array }) selectedQuestions = [];
  @property({ type: String }) selectedQuestionBank = '';
  @property({ type: String }) surveyId = ''; // Store the survey ID from URL

  @state() selectedQuestionIds = new Set(); // To track selected questions

  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      max-width: 900px;
      margin: 0 auto;
      padding: 16px;
      font-family: Arial, sans-serif;
    }
    .question-bank {
      display: flex;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 16px;
      width: 100%;
    }
    .categories {
      width: 30%;
      border-right: 1px solid #e5e5e5;
      padding-right: 16px;
    }
    .categories ul {
      list-style-type: none;
      padding: 0;
    }
    .categories li {
      padding: 8px;
      cursor: pointer;
      border-radius: 4px;
      transition: background 0.3s;
    }
    .categories li:hover {
      background-color: #f0f0f0;
    }
    .categories li.selected {
      font-weight: bold;
    }
    .questions {
      width: 70%;
      padding-left: 16px;
    }
    .selected-questions {
      margin-top: 24px;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 16px;
    }
    .button-group {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
    button {
      padding: 8px 16px;
      margin-right: 8px;
      background-color: rgb(142, 73, 83);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
    }
    button:hover {
      background-color: #d0d0d0;
    }
  `;

  constructor() {
    super();
    this.questionBank = [];
    this.allQuestions = [];
    this.filteredQuestions = [];
    this.selectedQuestions = [];
    this.selectedQuestionBank = '';
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchQuestionBanks();
    const urlParams = new URLSearchParams(window.location.search);
    this.surveyId = urlParams.get('id');

    if (this.surveyId) {
      this.loadSurveyData(this.surveyId);
    } else {
      console.warn('No survey ID found in URL');
    }
  }

  async loadSurveyData(surveyId) {
    // Implement logic to load survey data based on the surveyId
    console.log(`Loading survey data for ID: ${surveyId}`);
    // Add your Firebase fetch logic here
  }

  async fetchQuestionBanks() {
    try {
      const surveysRef = collection(db, 'question-banks');
      const queryRef = query(surveysRef, where('status', '==', 'active'));
      const querySnapshot = await getDocs(queryRef);
      this.questionBank = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (this.questionBank.length > 0) {
        this.selectQuestionBank(this.questionBank[0].name);
      }
      this.requestUpdate();
    } catch (error) {
      console.error("Error fetching question banks:", error);
    }
  }

  async fetchQuestionsBy(questionBankId) {
    try {
      const questionsRef = collection(db, 'questionbox');
      const queryRef = query(questionsRef, where('framework', '==', questionBankId));
      const querySnapshot = await getDocs(queryRef);
      this.filteredQuestions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      this.requestUpdate();
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  }

  selectQuestionBank(questionBankName) {
    this.selectedQuestionBank = questionBankName;
    this.fetchQuestionsBy(questionBankName);
  }

  toggleQuestion(questionId) {
    if (this.selectedQuestionIds.has(questionId)) {
      this.selectedQuestionIds.delete(questionId);
      this.selectedQuestions = this.selectedQuestions.filter(q => q.id !== questionId);
    } else {
      this.selectedQuestionIds.add(questionId);
      const question = this.filteredQuestions.find(q => q.id === questionId);
      if (question) {
        this.selectedQuestions.push({
          id: question.id,
          title: question.question,
          bankId: question.questionKey,
          bankName: question.framework,
          order: this.selectedQuestions.length + 1 // Automatically assign an order based on the current length
        });
      }
    }
    console.log("Selected Questions:", this.selectedQuestions);
    this.requestUpdate();
  }

  updateOrder(questionId, newOrder) {
    const question = this.selectedQuestions.find(q => q.id === questionId);
    if (question) {
      question.order = parseInt(newOrder, 10);
      this.requestUpdate();
    }
  }



  async saveSurvey() {
    const questionsArray = Array.from(this.selectedQuestionIds);
    console.log("Questions to save:", questionsArray);
    await this.saveToFirestore(questionsArray);
  }

  async saveOrUpdateSurvey(surveyId = null) {
    try {
      // Create an array of question references with their order
      const selectedQuestionsData = Array.from(this.selectedQuestionIds).map((questionId) => {
        const question = this.selectedQuestions.find(q => q.id === questionId);
        return {
          reference: doc(db, 'questionsbox', questionId), // Firestore document reference
          order: question ? question.order : null // Get the order if exists
        };
      });
  
      // Prepare the survey data
      const surveyData = {
        selectedQuestions: selectedQuestionsData, // Store references and orders
        updatedAt: new Date() // Add timestamp
      };
  
      const surveyRef = doc(db, 'surveys', surveyId || 'newSurveyId');
  
      // Save the survey document, merging it if it already exists
      await setDoc(surveyRef, surveyData, { merge: true });
      console.log('Survey saved successfully with data:', surveyData);
    } catch (error) {
      console.error("Error saving or updating survey:", error);
      alert("Error saving or updating survey. Please try again.");
    }
  }

  


  async saveToFirestore(questionsArray) {
    console.log("Saving questions:", questionsArray);

    const selectedQuestionsData = Array.from(this.selectedQuestionIds).map((questionId) => {
      const question = this.selectedQuestions.find(q => q.id === questionId);
      return {
        reference: doc(db, 'questionsbox', questionId), // Firestore document reference
        order: question ? question.order : null // Get the order if exists
      };
    });

    
    const surveyData = {
      selectedQuestions: selectedQuestionsData
    };

    const surveyRef = doc(db, 'surveys', this.surveyId || 'newSurveyId');
    try {
      await setDoc(surveyRef, surveyData, { merge: true });
      console.log('Survey saved successfully with data:', surveyData);
    } catch (error) {
      console.error('Error saving survey:', error);
    }
  }

  publishSurvey() {

    //this.saveSurvey();

    // Prepare the survey data to be saved as active
    const surveyData = {
      status: 'active', // Set the survey status to 'active'
      updatedAt: new Date() // Update timestamp
    };
  
    // Reference to the survey document in Firestore
    const surveyRef = doc(db, 'surveys', this.surveyId);
  
    // Update the survey document with the active status
    setDoc(surveyRef, surveyData, { merge: true })
      .then(() => {
        //alert('Survey published successfully!');
        console.log('Survey status updated to active.');
        this.dispatchEvent(
          new CustomEvent("navigate-to-route", {
            detail: { routePath: "/survey-publish?id="+this.surveyId},
            bubbles: true,
            composed: true,
          })
        );

        
      })
      .catch((error) => {
        console.error('Failed to publish survey:', error);
        alert('Failed to publish survey. Please try again.');
      });
  }

  


  render() {
    return html`
      <div class="container">
        <h2>Question Selection for Survey ID: ${this.surveyId}</h2>
        <div class="question-bank">
          <div class="categories">
            <p><strong>Library of Question Banks</strong></p>
            <ul>
              ${this.questionBank.map(bank => html`
                <li class="${this.selectedQuestionBank === bank.name ? 'selected' : ''}" @click="${() => this.selectQuestionBank(bank.name)}">
                  ${bank.name} <small>(Key: ${bank.key})</small>
                </li>
              `)}
            </ul>
          </div>
          <div class="questions">
            <h3>${this.selectedQuestionBank ? `Questions for "${this.selectedQuestionBank}"` : ''}</h3>
            <div>
              ${this.filteredQuestions.map(question => html`
                <div>
                  <input type="checkbox" ?checked="${this.selectedQuestionIds.has(question.id)}" @change="${() => this.toggleQuestion(question.id)}" />
                  <label>${question.question}</label>
                </div>
              `)}
            </div>
          </div>
        </div>
        <div class="selected-questions">
          <h4>All Selected Questions (${this.selectedQuestions.length} selected)</h4>
          <ul>
            ${this.selectedQuestions.map(question => html`
              <li>
                <strong>${question.bankName}</strong>: ${question.title}
                <button @click="${() => this.toggleQuestion(question.id)}">Remove</button>
                 <input 
        type="number" 
        .value="${question.order}" 
        @input="${(e) => this.updateOrder(question.id, e.target.value)}" 
      />
              </li>
            `)}
          </ul>
        </div>
        <div class="button-group">
          <button @click="${this.editSurvey}">Edit</button>
          <button @click="${this.saveSurvey}">Save</button>
          <button class="primary" @click="${this.publishSurvey}">Publish Survey</button>
        </div>
      </div>
    `;
  }
}