import { LitElement, html, css } from 'lit';
import Chart from 'chart.js/auto';

export class BarChart extends LitElement {
  static properties = {
    chartData: { type: Object }, // Data for the chart
    chartOptions: { type: Object }, // Configuration options for the chart
  };

  static styles = css`
    .chart-container {
      width: 100%;
      max-width: 600px;
      margin: auto;
      padding: 16px;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }
    canvas {
      display: block;
    }
  `;

  constructor() {
    super();
    this.chartData = {
      labels: ['January', 'February', 'March', 'April', 'May'],
      datasets: [
        {
          label: 'Sales',
          data: [65, 59, 80, 81, 56],
          backgroundColor: ['rgba(75, 192, 192, 0.6)'],
          borderColor: ['rgba(75, 192, 192, 1)'],
          borderWidth: 1,
        },
      ],
    };

    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  }

  firstUpdated() {
    // Render the Chart.js chart after the element has been updated
    const ctx = this.renderRoot.querySelector('#myChart').getContext('2d');
    new Chart(ctx, {
      type: 'bar',
      data: this.chartData,
      options: this.chartOptions,
    });
  }

  render() {
    return html`
    <h1>Bar Chart</h1>
      <div class="chart-container">
        <canvas id="myChart"></canvas>
      </div>
    `;
  }
}

customElements.define('bar-chart', BarChart);
