import {LitElement, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import { textareaStyles } from './dp-textarea-css.js'; 

export class DpTextarea extends LitElement {
  static get styles() {
    return [textareaStyles];
  }

  static get properties() {
    return {
      id: { type: String },
      rows: { type: String },
      cols: { type: String }
    };
  }

  constructor() {
    super();
    this.id = 'text';
    this.rows = '4'; 
    this.cols = '50';
  }

  render() {
    return html`
    <div class="block-group">
      <label for="${this.id}" class="title">Title</label>
      <textarea id="${this.id}" rows="${this.rows}" cols="${this.cols}"></textarea>
      </div>
    `;
  }
}

customElements.define('dp-textarea', DpTextarea);
