import { LitElement, html, css } from 'lit';

export class ImageSlider extends LitElement {
  static styles = css`
  :host {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  .slide {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Adjust height as needed */
  }
  .slide img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 10px; /* Rounded corners */
  }
  .buttons {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  .button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
  }
  .button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

static properties = {
  currentSlide: { type: Number },
};

constructor() {
  super();
  this.currentSlide = 0;
  this.slides = ['assets/homesliders/image1.jpg', 'assets/homesliders/image2.jpg', 'assets/homesliders/image3.jpg']; // Example images
}

firstUpdated() {
  this.startAutoSlide();
}

startAutoSlide() {
  setInterval(() => {
    this.nextSlide();
  }, 3000); // Slide transition interval (3 seconds)
}

nextSlide() {
  this.currentSlide = (this.currentSlide + 1) % this.slides.length;
  this.updateSliderPosition();
}

prevSlide() {
  this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
  this.updateSliderPosition();
}

updateSliderPosition() {
  const slider = this.shadowRoot.querySelector('.slider');
  const slideWidth = slider.clientWidth;
  slider.style.transform = `translateX(-${this.currentSlide * slideWidth}px)`;
}

render() {
  return html`
    <div class="slider">
      ${this.slides.map(
        (slide) => html`
          <div class="slide">
            <img src="${slide}" alt="Slide Image" />
          </div>
        `
      )}
    </div>
    <div class="buttons">
      <button class="button" @click="${this.prevSlide}">‹</button>
      <button class="button" @click="${this.nextSlide}">›</button>
    </div>
  `;
}
}
customElements.define('image-slider', ImageSlider);
