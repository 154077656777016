import {LitElement, html} from 'lit';
import {dropdownStyles} from './dp-dropdown-css.js';

export class DropdownComponent extends LitElement {
  static get styles() {
    return [dropdownStyles];
  }
  static get properties() {
    return {
      id: {type: String},
      title: {type: String},
      options: {type: Array},
    };
  }


  constructor() {
    super();
    this.title = '';
    this.options = [];
  }

  render() {
    return html`
      <div class="block-group">
      <label class="title">${this.title}</label>
      <select @change="${this._onChange}">
        ${this.options.map(
      (option) =>
        html`
            <option 
              value="${option}" 
            >
              ${option}
            </option>
            `,
  )}
      </select>
      </div>
    `;
  }
}

customElements.define('dp-dropdown', DropdownComponent);
