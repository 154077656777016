import { LitElement, html, css } from 'lit';

class ReusablePopup extends LitElement {
  static properties = {
    message: { type: String },
    isOpen: { type: Boolean },
    confirmText: { type: String },
    cancelText: { type: String },
    id: { type: String },
    action: { type: String }
  };

  constructor() {
    super();
    this.message = 'Are you sure?'; // Default message
    this.isOpen = false;
    this.confirmText = 'Confirm'; // Default button text
    this.cancelText = 'Cancel';
    this.id = null;
    this.action = 'edit';
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  confirmAction(id,action) {
  

   // this.dispatchEvent(new CustomEvent('confirm')); // Trigger confirm event

     this.dispatchEvent(new CustomEvent('confirmBtn', {
      detail: {
        message: 'Confirmed action',  // You can add extra data if needed
        id:id,
        action:action
      },
      bubbles: true, // Allow the event to bubble up to parent
      composed: true // Cross shadow DOM boundary if needed
    }));



  
    this.close();
  }

  cancelAction() {
    this.dispatchEvent(new CustomEvent('cancel')); // Trigger cancel event
    this.close();
  }

  render() {
    if (!this.isOpen) return null;
    return html`
      <div class="modal-overlay">
        <div class="modal">
          <p>${this.message}</p>
          <div class="actions">
            <button @click="${() => this.confirmAction(this.id,this.action)}">${this.confirmText}</button>
            <button @click=${this.cancelAction}>${this.cancelText}</button>
          </div>
        </div>
      </div>
    `;
  }

  static styles = css`
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .modal {
      background: #f9e5e5;
      padding: 20px;
      border-radius: 10px;
      text-align: center;
    }
    .actions {
      margin-top: 20px;
    }
    button {
      padding: 8px 16px;
      margin: 0 10px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }
    button:first-child {
      background: #c4453d;
      color: white;
    }
    button:last-child {
      background: #cccccc;
    }
  `;
}

customElements.define('reusable-popup', ReusablePopup);