import { Q1Router, Container, Button } from "../src/app-components.js";
import { routerConfig } from "./routerConfig.js";

const container = document.getElementById('container');
const contentDiv = document.getElementById('app');
const router = new Q1Router(routerConfig, container, contentDiv);

// This is to get highlighter.js syntax highlighting after changing routes
const observer = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
      if (mutation.addedNodes.length == 1) {
          hljs.highlightAll();
      }
  });
});

observer.observe(document.body, {
  childList: true,
  subtree: true
});

hljs.addPlugin(new CopyButtonPlugin());