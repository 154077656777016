import { LitElement, html, css } from 'lit';
import axios from 'axios';
import { getAuth, signInWithEmailAndPassword ,sendEmailVerification,onAuthStateChanged, signOut,connectAuthEmulator } from "firebase/auth";
import { auth } from '../../firebase-config';
//import '../../components/loadingSpinner/loading-spinner.js'; // Import the spinner component
import { customElement, state } from 'lit/decorators.js';

export class LoginComponent extends LitElement {

  @state() user = null; // State to hold user data


  connectedCallback() {
    super.connectedCallback();
    this.checkAuthStatus(); // Check authentication on load
  }

  checkAuthStatus() {
    // Listen for auth state changes
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.navigateToDashboard(); // Redirect to dashboard if logged in
      } else {
        this.user = null;
        this.requestUpdate(); // Ensure LitElement updates to reflect login state
      }
    });
  }


  navigateToDashboard() {
    this.dispatchEvent(new CustomEvent('navigate-to-route', {
      detail: { routePath: '/forgot-password' },
      bubbles: true,
      composed: true,
    }));
  }


  static styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh;
      
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      max-width: 400px;
      padding: 20px;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin-top: 20px; /* Adjusted margin for top */
    }

    h2 {
      margin-bottom: 20px;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .login-form input {
      margin-bottom: 15px;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      
      background-color: rgb(240, 222, 223);
    }

    .error {
      color: red;
      margin-top: 10px;
    }

    .success {
      color: green;
      margin-top: 10px;
    }

    .text-link {
      color: #8e4953;
      font-size: 16px;
      margin-top: 10px;
       margin-bottom: 10px;
      cursor: pointer;
    }
       .text-link.center {
      text-align:center;
    }
       .button {
      position: relative;
     
      font-size: 16px;
      cursor: pointer;
    }

    .button[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .loader-container {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    .hidden {
      display: none;
    }
    .button {
  background-color: #8e4953;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #FFFFFF solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
    margin-top: 0px;
    -moz-box-shadow: 0px 0px 4px 1px grey;
    -webkit-box-shadow: 0px 0px 4px 1px grey;
    box-shadow: 0px 0px 4px 1px  grey;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}`;

  static properties = {
    email: { type: String },
    password: { type: String },
    errorMessage: { type: String },
    successMessage: { type: String },
    isLoading: { type: Boolean }
  };

  constructor() {
    super();
    this.email = '';
    this.password = '';
    this.errorMessage = '';
    this.successMessage = '';
    this.isLoading = false;
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this[name] = value;
  }

  navigateToForgotPassword() {
    this.dispatchEvent(new CustomEvent('navigate-to-route', {
      detail: { routePath: '/forgot-password' },
      bubbles: true,
      composed: true,
    }));
  }

  navigateToSignUP() {
    this.dispatchEvent(new CustomEvent('navigate-to-route', {
      detail: { routePath: '/signup' },
      bubbles: true,
      composed: true,
    }));
  }

  async generateverificationlink (user){
    try {

      await sendEmailVerification(user);
  } catch (error) {
  }  
  }

  async handleSubmit(event) {
    event.preventDefault();
  
    // Reset messages and start loading
    this.errorMessage = '';
    this.successMessage = '';
    this.isLoading = true;
  
    try {
      // Input validation (example: prevent empty fields)
      if (!this.email.trim() || !this.password.trim()) {
        this.errorMessage = 'Email and Password are required.';
        return;
      }
  
      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
      const user = userCredential.user;
  
      if (!user.emailVerified) {
        // Handle unverified email
        this.isLoading = false;
        this.errorMessage = 'Email not verified. Please verify your email before logging in.';
        await signOut(auth); // Log out the user for security
        return;
      }
  
      // Custom response on successful login
      const response = {
        uid: user.uid,
        email: user.email,
        userData:user,
        message: 'Login successful! Welcome back.',
        userData:user
      };
  
      console.log('Custom Response:', response);
   // Update success message and navigate to the dashboard
    this.isLoading = false;
     this.successMessage = response.message;
      

      //this.redirectToRoute('/dashboard'); // Example route
    } catch (error) {
      // Improved error handling with custom error messages
     
      if (error.code === 'auth/invalid-credential') {
        this.errorMessage = 'Invalid email or password. Please try again.';
        this.isLoading = false;
      } else if (error.code === 'auth/user-not-found') {
        this.errorMessage = 'No account found with this email.';
        this.isLoading = false;
      } else {
        this.errorMessage = 'Login failed: Internal server error. Please try again later.';
        this.isLoading = false;
      }

    } finally {
      // Ensure loading state is reset
      this.isLoading = false;
     
      this.dispatchEvent(new CustomEvent('navigate-to-route', {
                detail: { routePath: '/survey' },
                bubbles: true,
                composed: true,
              }));
    }
  }

  

  render() {
    return html`
      <div class="main">
         ${!this.user
        ? html`
        <h2>Login</h2>
        <form class="login-form" @submit="${this.handleSubmit}">
          <input
            type="email"
            name="email"
            .value="${this.email}"
            @input="${this.handleInputChange}"
            placeholder="Email"
            required
          />
          <input
            type="password"
            name="password"
            .value="${this.password}"
            @input="${this.handleInputChange}"
            placeholder="Password"
            required
          />
          <a class="text-link" @click="${this.navigateToForgotPassword}">Forgot Your Password?</a>
          <button type="submit" class="button" ?disabled="${this.isLoading}">
            ${this.isLoading ? 'Logging...' : 'Login'}
            <span class="loader-container ${this.isLoading ? '' : 'hidden'}">
              <loading-spinner></loading-spinner>
            </span>
          </button>


          ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ''}
          ${this.successMessage ? html`<p class="success">${this.successMessage}</p>` : ''}

          <a class="text-link center" @click="${this.navigateToSignUP}">New User ? SignUp </a>
        </form>
      </div>`: ""}
      </div>
    `;
  }
}

customElements.define('login-component', LoginComponent);
