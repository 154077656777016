import { LitElement, html, css } from 'lit';
import { auth,db }  from '../../firebase-config'; // Import your Firebase config
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { customElement, property } from 'lit/decorators.js';
import { getDocs, collection, query, where,doc,setDoc ,getDoc,updateDoc,deleteDoc} from 'firebase/firestore';
import '../../components/loadingSpinner/loading-spinner.js'; // Import the spinner component


@customElement('survey-edit-component')
export class SurveyEditComponent extends LitElement {
  @property({ type: Object }) user = null;
  static properties = {
    activeSection: { type: String }, // Track the currently active section
  };
  @property({ type: Object }) isEditing = {
    name: false,
    image: false,
    memberIndex: null, // Track which member is being edited
  };
  @property({ type: String }) newOrganizationImage = '';
  @property({ type: String }) tempMemberName = ''; // Temp name for the editing member
   
  static styles = css`
  
  .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 800px;
      margin: 0 auto;
      font-family: Arial, sans-serif;
    }

    label {
      font-size: 14px;
      color: #4a4a4a;
    }

    .checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
    }

    /* button {
    background-color: rgb(142, 73, 83);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    }

    button.save {
      background-color: #7c3a3a;
      color: white;
    } */

    /* button.cancel {
      background-color: #f3d1d1;
      color: #7c3a3a;
    } */

      .button {
  background-color: #8e4953;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #FFFFFF solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
    margin-top: 0px;
    -moz-box-shadow: 0px 0px 4px 1px grey;
    -webkit-box-shadow: 0px 0px 4px 1px grey;
    box-shadow: 0px 0px 4px 1px  grey;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}


      .error-message { color:red;}
      .success { color:green;}
     .loader-container {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    .hidden {
      display: none;
    }
     .error {  color: red;
      margin-top: 2px;
      font-size:10px 
      }

      input.invalid {
      border-color: red;
    }

    input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox-group {
  margin-bottom: 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}

.hidden-checkbox {
  visibility: hidden;
  position: absolute;
}

.custom-checkbox {
  position: relative;
  height: 18px;
  width: 18px;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 4px;
  margin-right:5px;
}

.hidden-checkbox:checked + .custom-checkbox {
  background-color: rgba(142, 73, 83, 1);
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 16px;
  visibility: hidden;
}

.hidden-checkbox:checked + .custom-checkbox::before {
  content: "\\2714";
  visibility: visible;
}

.title {
    color: var(--black, #000);
    font-family: Poppins, sans-serif;
    font-style: normal;
    line-height: 30px;
    margin-bottom: 8px; 
    font-weight: bold;
  }


    select {
      height: 56px;
width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px 4px 0 0;
  border: unset;
  border-bottom: 1px solid #000;
  background-color: rgba(240, 222, 223, 1);
    }

    .form-label {
      display: block;
      font-weight: bold;
      margin-bottom: 25px;
    }
    

  .text-s{
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 4px 4px 0 0;
    border: unset;
    border-bottom: 1px solid #000;
    background-color: rgba(240, 222, 223, 1);
    padding:10px;
  }

  input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox-group {
  margin-bottom: 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}

.hidden-checkbox {
  visibility: hidden;
  position: absolute;
}

.custom-checkbox {
  position: relative;
  height: 18px;
  width: 18px;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 4px;
  margin-right:5px;
}

.hidden-checkbox:checked + .custom-checkbox {
  background-color: rgba(142, 73, 83, 1);
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 16px;
  visibility: hidden;
}

.hidden-checkbox:checked + .custom-checkbox::before {
  content: "\\2714";
  visibility: visible;
}

/* .options-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 5px;
    } */
    .option {
      display: inline-flex;
      align-items: center;
      gap: 10px;
    }
    .option input[type='checkbox'] {
      appearance: none;
      -webkit-appearance: none;
      width: 24px;
      height: 24px;
      border: 2px solid #a50034;
      border-radius: 3px;
      background-color: #fff;
      cursor: pointer;
    }
    .option input[type='checkbox']:checked {
      background-color: #a50034; /* Matches checkbox fill color */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .option input[type='checkbox']:checked::after {
      content: '✔';
      color: white;
      font-size: 15px;
    }
    .option label {
      font-size: 14px;
      color: #333;
    }

  `;

  static properties = {
    title: { type: String },
    administered: { type: String },
    ageGroup: { type: String },
    scope: { type: Array },
    measurement: { type: Array },
    metrics: { type: Array },
    errors: { type: Object },
    selectedOrg: { type: String }, // Store selected organizations
    selectedOption: { type: String },
    successMessage: { type: String },
    isLoading: { type: Boolean },
    surveyId: { type: Boolean },
  };


  constructor() {
    super();
    this.checkAuth();
    this.title = '';
    this.administered = '';
    this.ageGroup = '';
    this.scope = [];
    this.measurement = [];
    this.metrics = [];
    this.errors = {};
    this.successMessage = '';
    this.selectedOrg = localStorage.getItem('selectedOrg') || ''; // Load from localStorage
    this.selectedOption = localStorage.getItem('selectedOrg') || ''; // Load from localStorage
    this.isLoading = false;

  }

  async connectedCallback() {
    super.connectedCallback();
    this.checkAuth();
    //const urlParams = new URLSearchParams(window.location.search);
    const pathSegments = window.location.pathname.split('/');
    this.surveyId = pathSegments[pathSegments.indexOf('edit') + 1]; // Finds the ID after "edit"
 
   // this.surveyId = urlParams.get('id'); // Get survey ID from URL

    if (this.surveyId) {
      await this.loadSurveyData(this.surveyId); // Load survey data if an ID is present
    }

       // Add event listener for the custom dropdown event
  //document.addEventListener('org-changed', this.handleDropOrgChange);

    document.addEventListener('org-changed', (event) => {
        console.log('Event caught globally:', event.detail.selectedOrgValue);
      });

       // Use arrow function or bind `this`
  document.addEventListener('org-changed', this.handleDropOrgChange.bind(this));
  //this.fetchSurveys();
    
  }
  disconnectedCallback() {
    // Clean up event listener
    //document.removeEventListener('org-changed', this.handleDropOrgChange);
    document.removeEventListener('org-changed', this.handleDropOrgChange.bind(this));
    super.disconnectedCallback();
  }

  handleDropOrgChange(event) {
    console.log('Event caught handleDropOrgChange:', event.detail.selectedOrgValue);
    const { selectedOrgValue } = event.detail;
    this.selectedOption = selectedOrgValue;
    console.log("selectedOrgValue",selectedOrgValue);
    console.log(" this.selectedOption ",this.selectedOption );
  }


  // Input validation function
  validateForm() {
    this.errors = {};

    // Trim title input and check if it's empty
    if (!this.title.trim()) {
      this.errors.title = 'Title is required';
    }

    // Check if an administered time is selected
    if (!this.administered) {
      this.errors.administered = 'You must select when the survey is being administered';
    }

    // Check if age group is selected
    if (!this.ageGroup) {
      this.errors.ageGroup = 'You must select the age group being surveyed';
    }

    // Validate scope checkboxes
    if (this.scope.length === 0) {
      this.errors.scope = 'At least one scope must be selected';
    }

    // Validate measurement checkboxes
    if (this.measurement.length === 0) {
      this.errors.measurement = 'At least one part of your program must be measured';
    }

    // Validate metrics checkboxes
    if (this.metrics.length === 0) {
      this.errors.metrics = 'At least one metric must be selected';
    }

    // If there are errors, return false to prevent form submission
    return Object.keys(this.errors).length === 0;
  }


 

  checkAuth() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.requestUpdate();
      } else {
        this.user = null;
        this.requestUpdate();
      }
    });
  }


  async deleteSurvey() {
    if (!this.surveyId) {
      console.error("Survey ID is missing. Cannot delete survey.");
      return;
    }
  
    try {
      const surveyRef = doc(db, 'surveys', this.surveyId);
      await deleteDoc(surveyRef);
      console.log(`Survey with ID ${this.surveyId} deleted successfully.`);
      this.successMessage = `Survey deleted successfully.`;
      
      // Optionally, dispatch an event or redirect to another page
      this.dispatchEvent(
        new CustomEvent("navigate-to-route", {
          detail: { routePath: "/surveys" }, // Redirect to the surveys list page
          bubbles: true,
          composed: true,
        })
      );
    } catch (error) {
      console.error("Error deleting survey: ", error);
      this.errors.general = "An error occurred while deleting the survey. Please try again.";
    }
  }
  
  

  homeSurvey() {

    
      this.dispatchEvent(
        new CustomEvent("navigate-to-route", {
          detail: { routePath: "/survey" },
          bubbles: true,
          composed: true,
        })
      );
    }

    async loadSurveyData(id) {
      this.isLoading = true;
      try {
        const surveyDocRef = doc(db, 'surveys', id);
        const surveyDoc = await getDoc(surveyDocRef);
        if (surveyDoc.exists()) {
          this.surveyData = surveyDoc.data();
          this.title = this.surveyData.title;
          this.administered = this.surveyData.time_period;
          this.ageGroup = this.surveyData.ageGroup;
          this.scope = this.surveyData.scope;
          this.measurement = this.surveyData.measurement;
          this.metrics = this.surveyData.metrics;
          
          this.version = this.surveyData.version;
          console.log('Survey found'+this.surveyData);
          console.log("this.administered"+this.administered);

         // console.log('Survey found'+this.surveyData);

        } else {
          console.error('Survey not found');
        }
      } catch (error) {
        console.error('Error loading survey data:', error);
      } finally {
        this.isLoading = false;
      }
    }

    
    handleInputChange(event) {
      const { name, value, type, checked } = event.target;
      if (type === 'checkbox') {
        if (checked) {
          this.surveyData[name] = [...(this.surveyData[name] || []), value];
        } else {
          this.surveyData[name] = (this.surveyData[name] || []).filter((val) => val !== value);
        }
      } else {
        this.surveyData[name] = value;
      }
    }

  

    async SaveContinue(e) {
      e.preventDefault();
      this.isLoading = true;
    
      // Retrieve form values
      this.title = this.shadowRoot.querySelector('input[name="title"]').value;
      this.administered = this.shadowRoot.querySelector('#administered').value;
      this.ageGroup = this.shadowRoot.querySelector('#ageGroup').value;
      this.scope = Array.from(this.shadowRoot.querySelectorAll('input[name="scope"]:checked')).map(checkbox => checkbox.value);
      this.measurement = Array.from(this.shadowRoot.querySelectorAll('input[name="measurement"]:checked')).map(checkbox => checkbox.value);
      this.metrics = Array.from(this.shadowRoot.querySelectorAll('input[name="metrics"]:checked')).map(checkbox => checkbox.value);
      const currentTimestamp = new Date().toISOString();
    
      // Form validation
      this.errors = {};
      if (!this.title.trim()) {
        this.errors.title = 'Title is required';
        this.isLoading = false;
        return;
      }
      if (!this.administered) {
        this.errors.administered = 'You must select when the survey is being administered';
        this.isLoading = false;
        return;
      }
      if (!this.ageGroup) {
        this.errors.ageGroup = 'You must select the age group being surveyed';
        this.isLoading = false;
        return;
      }
      if (this.scope.length === 0) {
        this.errors.scope = 'At least one scope must be selected';
        this.isLoading = false;
        return;
      }
      if (this.measurement.length === 0) {
        this.errors.measurement = 'At least one part of your program must be measured';
        this.isLoading = false;
        return;
      }
      if (this.metrics.length === 0) {
        this.errors.metrics = 'At least one metric must be selected';
        this.isLoading = false;
        return;
      }
    
      // Prepare survey data
      const surveyData = {
        title: this.title,
        administered: this.administered,
        ageGroup: this.ageGroup,
        scope: this.scope,
        measurement: this.measurement,
        metrics: this.metrics,
        date_updated: currentTimestamp,
        status: 'draft',
        version: 'v1',
        outcomes: [],
        indicators: [],
        language: 'en',
        organization_id: doc(db, 'organizations', this.selectedOption) // Reference to organization
      };
    
      try {
        // Check if updating an existing survey
        if (this.surveyId) {
          // Existing survey ID available, update the document
          const surveyRef = doc(db, 'surveys', this.surveyId);
          await updateDoc(surveyRef, surveyData);
          console.log(`Survey updated successfully with ID: ${this.surveyId}`);
          this.successMessage = `Survey updated successfully with ID: ${this.surveyId}`;
        } else {
          // No survey ID, create a new survey
          const surveysRef = collection(db, 'surveys');
          const newSurveyRef = doc(surveysRef); // Auto-generate ID
          await setDoc(newSurveyRef, { ...surveyData, date_created: currentTimestamp });
          console.log(`Survey created successfully with ID: ${newSurveyRef.id}`);
          this.successMessage = `Survey created successfully with ID: ${newSurveyRef.id}`;
          this.surveyId = newSurveyRef.id; // Update with new ID for further edits
        }
    
        // Redirect to survey questions page
        this.dispatchEvent(
          new CustomEvent("navigate-to-route", {
            detail: { routePath: "/survey/create/questions/" + (this.surveyId || newSurveyRef.id) },
            bubbles: true,
            composed: true,
          })
        );
      } catch (error) {
        console.error("Error saving survey: ", error);
        this.errors.general = "An error occurred while saving the survey. Please try again.";
      } finally {
        this.isLoading = false;
      }
    }
    

  render() {
    return html`
      <div class="container">
        ${this.user
        ? html`
       <div class="container">
        <h1>Edit a Survey</h1>

        <label class="title" >What is the name of the survey?</label>
        
        <input class="text-s" type="text" .value=${this.title} required/>
        ${this.errors.title ? html`<span class="error">${this.errors.title}</span>` : ''}

        <label class="title" for="administered">When is this survey being administered?</label>
        
        <select id="administered" @change=${(e) => (this.administered = e.target.value)}  .value="${this.administered || ''}">
          <option value="">Select...</option>
          <option value="Before program">Before program</option>
          <option value="After program">After program</option>
        </select>
        ${this.errors.administered ? html`<span class="error">${this.errors.administered}</span>` : ''}

        <label class="title" for="ageGroup">What age group are you surveying?</label>
        <select id="ageGroup" @change=${(e) => (this.ageGroup = e.target.value)}  .value="${this.ageGroup || ''}">
          <option value="">Select...</option>
          <option value="K-12">K-12</option>
          <option value="18-24">18-24</option>
          <option value="25-34">25-34</option>
        </select>
        ${this.errors.ageGroup ? html`<span class="error">${this.errors.ageGroup}</span>` : ''}

        <label class="title" >What is the scope of your program?</label>
        <div class="options-grid"> <div class=" option" >
          <input type="checkbox" name="scope" value="STEM" .checked=${this.scope.includes("STEM")} /><label>STEM </label>
          <input type="checkbox" name="scope" value="Gender-based" .checked=${this.scope.includes("Gender-based")} /><label> Gender-based</label>
         <input type="checkbox" name="scope" value="Academic support" .checked=${this.scope.includes("Academic support")} /><label> Academic support</label>
        </div>
        ${this.errors.scope ? html`<span class="error">${this.errors.scope}</span>` : ''}
</div> 
        <label class="title" >What part of your program are you measuring?</label>
       <div class="options-grid"> <div class=" option" >
          <input type="checkbox" name="measurement" value="Quality" .checked=${this.measurement.includes("Quality")} /> <label>Quality</label>
         <input type="checkbox" name="measurement" value="Effect on participants" .checked=${this.measurement.includes("Effect on participants")} /> <label>Effect on participants</label>
          <input type="checkbox" name="measurement" value="Effect on academic behavior" .checked=${this.measurement.includes("Effect on academic behavior")} /> <label>Effect on academic behavior</label>
        </div>
        ${this.errors.measurement ? html`<span class="error">${this.errors.measurement}</span>` : ''}
 </div>
        <label class="title" >What metric do you want to use to measure your results?</label>
        <div class="options-grid"> <div class=" option" >
          <input type="checkbox" name="metrics" value="Grade" .checked=${this.metrics.includes("Grade")} /><label> Grade</label>
          <input type="checkbox" name="metrics" value="Gender" .checked=${this.metrics.includes("Gender")}  /><label>Gender</label>
          <input type="checkbox" name="metrics" value="Race/Ethnicity" .checked=${this.metrics.includes("Race/Ethnicity")}  /><label> Race/Ethnicity</label>
          <input type="checkbox" name="metrics" value="Age" .checked=${this.metrics.includes("Age")}  /> <label>Age</label>
        </div>
        ${this.errors.metrics ? html`<span class="error">${this.errors.metrics}</span>` : ''}
        </div>
        <div class="buttons">
          <button class="cancel button" @click=${this.homeSurvey}>Cancel</button>

           <button @click=${this.SaveContinue} class="button" ?disabled="${this.isLoading}">
            ${this.isLoading ? 'Continue...' : 'Save and Continue'}
            <span class="loader-container ${this.isLoading ? '' : 'hidden'}">
              <loading-spinner></loading-spinner>
            </span>
          </button>


         
        </div>
         ${this.successMessage ? html`<span class="success">${this.successMessage}</span>` : ''}
      </div>

        `
        : html`<p>You are not logged in. Please <a href="/login">login</a> to manage your organization.</p>`
      }
      </div>
    `;
  }
}