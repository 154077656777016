// landing-component.js
import { LitElement, html, css } from 'lit';

export class LandingComponent extends LitElement {
  static styles = css`
  :host {
     
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #f0f0f0;
    }

    h2 {
      margin-bottom: 20px;
    }

    .main {
   
      align-items: center;
      justify-content: center;
      width: 60%;
      height:auto;
      margin:0% 20% 0% 20%;
   
     
     
    }
    .landing {
      padding: 1em;
      text-align: center;
    }
      
  `;

  render() {
    return html`
       <header-component></header-component>
        <div class="main">
       <h2>Welcome to Data Point Studio</h2>
         <image-slider></image-slider>   
      </div>
      <footer-component></footer-component> 
    `;
  }
}
// <img class="landingIMG" src="/assets/ladning-page.png" alt="Centered Image"/>
customElements.define('landing-component', LandingComponent);
