// dashboard-component.js
import { LitElement, html, css } from 'lit';
import { BaseView } from '../base-view/base-view.js';
import '../../components/nav-bar/nav-bar.js' // Import the Navbar component

export class DashboardComponent extends BaseView {

  static properties = {
    ...super.properties,
    another: { type: String },
  };

  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    header-component {
      width: 100%;
    }

    footer-component {
      width: 100%;
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      max-width: 800px; /* Adjusted for better responsiveness */
      padding: 20px;
      background-color: white;
  

      margin: 20px 0; /* Margin for spacing between header, main content, and footer */
      text-align: center; /* Center align text */
    }

    h2 {
      margin-bottom: 20px;
    }

    .app-footer {
      margin-top: 20px;
    }

    .landingIMG {
      max-width: 100%; /* Ensure image is responsive */
      height: auto;
    }

     .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .dashboard {
      display: flex;
      width: 80%;
      height: 80%;
      background-color: #f0f0f0;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }
    .sidebar {
      width: 250px;
      background-color: #fff;
      border-right: 1px solid #ddd;
      padding: 10px;
      box-sizing: border-box;
    }
    .content {
      flex: 1;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
    }
    .menu-item {
      cursor: pointer;
      padding: 10px;
      border-bottom: 1px solid #ddd;
      transition: background-color 0.3s;
    }
    .menu-item:hover {
      background-color: #e0e0e0;
    }
  

  `;

  constructor() {
    super();
    console.log('in home view');
  }

  firstUpdated() {
    super.firstUpdated();
  }

  signedOutTemplate() {
    return html`<p>You are signed out. Please sign in.</p>`;
  }

  signedInTemplate() {
    return html`<header-component></header-component>
      <main>
        <div class="main">
          <h2>Welcome to Data Point Studio</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <p class="app-footer">
            Just getting started
            <a target="_blank" href="#">Data Point Studio</a>.
          </p>
        </div>

        

      </main>
      
      <footer-component></footer-component>
   `;
  }


  render() {
    // if (!this.isSignedIn) return this.signedOutTemplate();

    if (this.isSignedIn) {
      return this.signedInTemplate();
    } else {
      return this.signedOutTemplate(); // Ensure this method exists
    }



    return html`
      
    `;
  }
}

customElements.define('dashboard-component', DashboardComponent);
