import { LitElement, html } from 'lit';
import { iconStyles } from './icon-styles.js';
import { baseStyles } from "../../styles/base-styles.js";

import '@material/web/icon/icon.js';

export class Icon extends LitElement {
    static properties = {
        iconSlot: { type: String }, // For specifying 'leading-icon' or 'trailing-icon'
        iconStyle: { type: "outlined" | "rounded" | "sharp" | "fill" }, // 'outlined', 'rounded', 'sharp', 'fill'
        fillLevel: { type: Number } // Ranges from 0 to 1
    };

    static styles = [
        baseStyles,
        iconStyles
    ];

    constructor() {
        super();
        this.iconSlot = '';
        this.iconStyle = 'outlined'; // default style
        this.fillLevel = 1; // default fill
    }

    render() {
        return html`<md-icon slot="${this.iconSlot}" type="${this.iconStyle}"><slot></slot></md-icon>`;
    }

    computeFillStyle() {
        return this.iconStyle === 'fill' ? `--fill-level: ${this.fillLevel}` : '';
    }
}

// Define the new element
customElements.define('q1-icon', Icon);
