import { LitElement, html } from 'lit';
import { selectStyles } from './select-styles.js';
import { baseStyles } from "../../styles/base-styles.js";

import '@material/web/select/select-option.js';
import '@material/web/select/filled-select.js';
import '@material/web/select/outlined-select.js';

export class Select extends LitElement {
    static properties = {
        type: { type: "filled" | "outlined" },
        idFor: { type: String },
        label: { type: String },
        disabled: { type: Boolean },
        options: { type: Object }, // Array of {value: String, label: String, selected: Boolean}
        required: { type: Boolean },
    };

    static styles = [
        baseStyles,
        selectStyles
    ];

    constructor() {
        super();
        this.type = "outlined"; // Default to outlined style
        this.disabled = false;
        this.required = false;
        this.label = '';
        this.options = {};
    }

    renderHelper() {
        return this.options.map(option => html`
            <md-select-option ?selected="${option.selected}" value="${option.value}">
                <div slot="headline">${option.label}</div>
            </md-select-option>
        `);
    }

    render() {
        const showBlankOption = !this.required ? html`<md-select-option aria-label="blank"></md-select-option>` : html``;

        return this.type === "outlined" ?
            html`
                <div class="q1-button-container">
                    <md-outlined-select id=${this.idFor} ?disabled=${this.disabled} ?required=${this.required} label=${this.label}>
                        ${showBlankOption}
                        ${this.renderHelper()}
                    </md-outlined-select>
                </div>
            ` : html`
                <div class="q1-button-container">
                    <md-filled-select id=${this.idFor} ?disabled=${this.disabled} ?required=${this.required} label=${this.label}>
                        ${showBlankOption}
                        ${this.renderHelper()}
                    </md-filled-select>
                </div>
            `;
    }
}

customElements.define('q1-select', Select);