import { html, render } from 'lit';

export function equalsIgnoringCase(text, other) {
  if (!text) return false;

  return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
}

export async function fetchContent(route) {
  try {
      const response = await fetch(route);
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.text();
  } catch (error) {
      console.error('Fetch error:', error);
      return `<p>Error loading the page!</p>`;
  }
};