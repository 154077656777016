import { LitElement, html, css } from 'lit';
import { buttonStyles } from "./button-styles.js";
import { baseStyles } from "../../styles/base-styles.js";

import '@material/web/button/outlined-button.js';
import '@material/web/button/elevated-button.js';
import '@material/web/button/filled-button.js';
import '@material/web/button/filled-tonal-button.js';
import '@material/web/button/text-button.js';

export class Button extends LitElement {
    static properties = {
        type: { type: String },
        disabled: { type: Boolean },
        href: { type: String },
        trailingIcon: { type: Boolean },
        hasIcon: { type: Boolean }
    };

    static styles = [
        baseStyles,
        buttonStyles
    ];

    constructor() {
        super();
        this.type = '';
        this.disabled = false;
        this.href = '';
        this.trailingIcon = false;
        this.hasIcon = false;
    }

    render() {
        let buttonTemplate;

        switch (this.type) {
            case 'elevated':
                buttonTemplate = html`<md-elevated-button raised ?disabled=${this.disabled}><slot></slot></md-elevated-button>`;
                break;
            case 'filled':
                buttonTemplate = html`<md-filled-button unelevated ?disabled=${this.disabled}><slot></slot></md-filled-button>`;
                break;
            case 'filled tonal':
                // Note: Adjust this case based on Material Design's specific implementation for 'filled tonal'
                buttonTemplate = html`<md-filled-tonal-button ?disabled=${this.disabled}><slot></slot></md-filled-tonal-button>`;
                break;
            case 'outlined':
                buttonTemplate = html`<md-outlined-button outlined ?disabled=${this.disabled}><slot></slot></md-outlined-button>`;
                break;
            case 'text':
                buttonTemplate = html`<md-text-button ?disabled=${this.disabled}><slot></slot></md-text-button>`;
                break;
            default:
                buttonTemplate = html`<md-text-button ?disabled=${this.disabled}><slot></slot></md-text-button>`;
                break;
        }

        return html`
            ${this.href ? html`<a href=${this.href}>${buttonTemplate}</a>` : buttonTemplate}
        `;
    }

}

customElements.define('q1-button', Button);
