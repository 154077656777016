import { LitElement, html } from 'lit';
import { buttonStyles } from './db-button-css';

export class DpButton extends LitElement {
  static get styles() {
    return [ buttonStyles ];
  }

  static get properties() {
    return {
      value: { type: String },
      id: { type: String },
      disabled: { type: Boolean },
      url: { type: String }
    };
  }

  constructor() {
    super();
    this.value = '';
    this.id = '';
    this.disabled = false;
    this.url = '';
  }

  render() {
    const buttonTemplate = html` 
    <input
      class="button"
      type="button"
      value="${this.value}"
      ?disabled=${this.disabled}
    />`;

    return html`${this.href
      ? html`<a href=${this.href}>${buttonTemplate}</a>`
      : buttonTemplate}`;
  }
}

customElements.define('dp-button', DpButton);
