import { LitElement, html, css } from 'lit';

class LoadingSpinner extends LitElement {
  static styles = css`
    :host {
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 3px solid rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      border-top-color: #000;
      animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    .hidden {
      display: none;
    }
       .loader-container {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    .hidden {
      display: none;
    }
      
  `;

  render() {
    return html``; // Spinner visualized by CSS, no additional HTML needed
  }
}

customElements.define('loading-spinner', LoadingSpinner);
