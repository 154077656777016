import { css } from 'lit';

export const passwordStyles = css`
    .password-box {
        height: 56px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 4px 4px 0px 0px;
    border-top: unset;
    border-right: unset;
    border-left: unset;
    border-image: unset;
    border-bottom: 1px solid rgb(0, 0, 0);
    background-color: rgb(240, 222, 223);
    }
        
`;
