import { LitElement, html, css } from 'lit';
import { auth,db }  from '../../firebase-config'; // Import your Firebase config
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { customElement, property } from 'lit/decorators.js';
import { getDocs, collection, query, where,doc,setDoc,Timestamp } from 'firebase/firestore';
import { unsafeHTML } from 'lit/directives/unsafe-html.js'; // To handle potential XSS
import '../../components/loadingSpinner/loading-spinner.js'; // Import the spinner component


@customElement('survey-create-component')
export class SurveyCreateComponent extends LitElement {
  @property({ type: Object }) user = null;
  static properties = {
    activeSection: { type: String }, // Track the currently active section
  };
  @property({ type: Object }) isEditing = {
    name: false,
    image: false,
    memberIndex: null, // Track which member is being edited
  };
  @property({ type: String }) newOrganizationImage = '';
  @property({ type: String }) tempMemberName = ''; // Temp name for the editing member
   
  static styles = css`
  
  .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 400px;
      margin: 0 auto;
      font-family: Arial, sans-serif;
    }

    label {
      font-size: 14px;
      color: #4a4a4a;
    }

    select, input[type="text"], input[type="checkbox"] {
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;
    }

    .checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
    }

    button {
    background-color: rgb(142, 73, 83);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    }

    button.save {
      background-color: #7c3a3a;
      color: white;
    }

    button.cancel {
      background-color: #f3d1d1;
      color: #7c3a3a;
    }
      .error-message { color:red;}
      .success { color:green;}
     .loader-container {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    .hidden {
      display: none;
    }
     .error {  color: red;
      margin-top: 2px;
      font-size:10px 
      }

      input.invalid {
      border-color: red;
    }
  `;

  static properties = {
    title: { type: String },
    administered: { type: String },
    ageGroup: { type: String },
    scope: { type: Array },
    measurement: { type: Array },
    metrics: { type: Array },
    errors: { type: Object },
    selectedOrg: { type: String }, // Store selected organizations
    selectedOption: { type: String },
    successMessage: { type: String },
    isLoading: { type: Boolean },
  };


  constructor() {
    super();
    this.checkAuth();
    this.title = '';
    this.administered = '';
    this.ageGroup = '';
    this.scope = [];
    this.measurement = [];
    this.metrics = [];
    this.errors = {};
    this.successMessage = '';
    this.selectedOrg = localStorage.getItem('selectedOrg') || ''; // Load from localStorage
    this.selectedOption = localStorage.getItem('selectedOrg') || ''; // Load from localStorage
    this.isLoading = false;
    

  }

  connectedCallback() {
    super.connectedCallback();
    this.checkAuth();
       // Add event listener for the custom dropdown event
      document.addEventListener('org-changed', (event) => {
        console.log('Event caught globally:', event.detail.selectedOrgValue);
      });
      // Use arrow function or bind `this`
      document.addEventListener('org-changed', this.handleDropOrgChange.bind(this));
       //this.fetchSurveys();
    
  }

  
  disconnectedCallback() {
    // Clean up event listener
    //document.removeEventListener('org-changed', this.handleDropOrgChange);
    document.removeEventListener('org-changed', this.handleDropOrgChange.bind(this));
    super.disconnectedCallback();
  }

  handleDropOrgChange(event) {

    console.log('Event caught handleDropOrgChange:', event.detail.selectedOrgValue);
    const { selectedOrgValue } = event.detail;
    this.selectedOption = selectedOrgValue;
    console.log("selectedOrgValue",selectedOrgValue);
    console.log(" this.selectedOption ",this.selectedOption );
  }

  checkAuth() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.requestUpdate();
      } else {
        this.user = null;
        this.requestUpdate();
      }
    });
  }

  generateUniqueCode(length = 20) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }

    return result;

    
}


// async  addSurvey(organizationId, title, timePeriod, language, outcomes, indicators, version, status) {
//   const surveyRef = doc(db, "surveys"); // Create a new document reference

//   const invitationCode = generateUniqueCode(); // Generate the unique invitation code

//   await setDoc(surveyRef, {
//       organization: doc(db, "organizations", organizationId), // Reference to organization
//       title: title,
//       timePeriod: timePeriod, // "Before" or "After"
//       language: language, // "English" or "Spanish"
//       outcomes: outcomes,
//       indicators: indicators,
//       version: version,
//       status: status, // "Draft", "Active", "Closed", or "Archived"
//       invitationCode: invitationCode,
//       dateCreated: Timestamp.now(),
//       dateUpdated: Timestamp.now()
//   });
// }


  
  
// async createSurvey(surveyData) { 
//     console.log('SaveContinue button clicked');

//     try {
//            // Start loading
//             // Define the reference to the organization document
//             //const orgRef = doc(db, 'organizations', orgId); // Replace orgId with the actual organization ID

//             // Define the data for the survey document
//             // const surveyDoc = {
//             //     organization_id: orgRef, // Reference to the organization
//             //     status: surveyData.status, // e.g., 'active'
//             //     time_period: surveyData.time_period, // e.g., 'Before'
//             //     title: surveyData.title, // e.g., 'Hello'
//             //     version: surveyData.version // e.g., 'v1'
//             // };

//             // Create a reference to the surveys collection
//             const surveysRef = collection(db, 'surveys');
//             // Create a new document in the surveys collection
//             const newSurveyRef = doc(surveysRef); // Automatically generate a new ID
//             await setDoc(newSurveyRef, surveyData);

//             console.log("Survey created successfully with ID: ", newSurveyRef.id);
//             const surveyID = newSurveyRef.id;
//      // const questionNext = "/survey-questions?id="${surveyID};

//             this.successMessage = "Survey created successfully with ID: ", newSurveyRef.id;
            
//             this.dispatchEvent(
//               new CustomEvent("navigate-to-route", {
//                 detail: { routePath: "/survey-questions?id="+surveyID},
//                 bubbles: true,
//                 composed: true,
//               })
//             );


//         } 
//     catch (error) {
//       console.error("Error creating survey: ", error);
      
//       }

//   }

  homeSurvey() {

    
      this.dispatchEvent(
        new CustomEvent("navigate-to-route", {
          detail: { routePath: "/" },
          bubbles: true,
          composed: true,
        })
      );
    }
  

    async SaveContinue(e) {
      e.preventDefault();
      this.isLoading = true; // Start loading
      // Get the values from form fields
     // const requiredFields = this.shadowRoot.querySelectorAll('[required]');
      this.title = this.shadowRoot.querySelector('input[name="title"]').value;
      this.administered = this.shadowRoot.querySelector('#administered').value;
      this.ageGroup = this.shadowRoot.querySelector('#ageGroup').value;
  
      // Get the selected checkboxes (Scope, Measurement, Metrics)
      this.scope = Array.from(this.shadowRoot.querySelectorAll('input[name="scope"]:checked')).map(checkbox => checkbox.value);
      this.measurement = Array.from(this.shadowRoot.querySelectorAll('input[name="measurement"]:checked')).map(checkbox => checkbox.value);
      this.metrics = Array.from(this.shadowRoot.querySelectorAll('input[name="metrics"]:checked')).map(checkbox => checkbox.value);
      //const currentTimestamp = new Date().toISOString(); // Use toISOString for a standard format


      this.errors = {};

      // Trim title input and check if it's empty
      if (!this.title.trim()) {
        this.errors.title = 'Title is required';
        this.isLoading = false; // Start loading
        return;
      }

       // Check if an administered time is selected
    if (!this.administered) {
      this.errors.administered = 'You must select when the survey is being administered';
      this.isLoading = false; // Start loading
      return;
    }

    // Check if age group is selected
    if (!this.ageGroup) {
      this.errors.ageGroup = 'You must select the age group being surveyed';
      this.isLoading = false; // Start loading
      return;
    }

    // Validate scope checkboxes
    if (this.scope.length === 0) {
      this.errors.scope = 'At least one scope must be selected';
      this.isLoading = false; // Start loading
      return;
    }

    // Validate measurement checkboxes
    if (this.measurement.length === 0) {
      this.errors.measurement = 'At least one part of your program must be measured';
      this.isLoading = false; // Start loading
      return;
    }

    // Validate metrics checkboxes
    if (this.metrics.length === 0) {
      this.errors.metrics = 'At least one metric must be selected';
      this.isLoading = false; // Start loading
      return;
    }

   const status = 'draft'; // You can modify this based on your logic
   const version = 'v1'; // You can modify this based on your logic
   const indicators = [];
   const groupCategory = "";
   const language = "en";
   const outcomes = [];
   const orgRef = doc(db, 'organizations', this.selectedOption); 
   const userID = doc(db, 'users', this.user.uid);// Replace orgId with the actual organization ID
   const invitationCode = this.generateUniqueCode(); // Generate the unique invitation code
   console.log("userID"+userID);
   // Prepare the survey data
      const surveyData = {
        title: this.title,
        time_period: this.administered,
        ageGroup: this.ageGroup,
        scope: this.scope,
        measurement: this.measurement,
        metrics: this.metrics,
        date_created: Timestamp.now(), // Add created date
        date_updated: Timestamp.now(),  // Add updated date
        invitation_code:invitationCode,
        outcomes:outcomes,
        ageGroup:groupCategory,
        version: version,
        indicators: indicators,
        status:status,
        organization_id:orgRef,
        language:language,
        createdBy: userID
        
      };

      console.log('Survey Data:', surveyData);


      try {
      
         // Create a reference to the surveys collection
         const surveysRef = collection(db, 'surveys');
         // Create a new document in the surveys collection
         const newSurveyRef = doc(surveysRef); // Automatically generate a new ID
         await setDoc(newSurveyRef, surveyData);
         

         console.log("Survey created successfully with ID: ", newSurveyRef.id);
         const surveyID = newSurveyRef.id;
        // const questionNext = "/survey-questions?id="${surveyID};

         this.successMessage = "Survey created successfully with ID: ", newSurveyRef.id;
         
         this.dispatchEvent(
           new CustomEvent("navigate-to-route", {
             detail: { routePath: "/survey-questions?id="+surveyID},
             bubbles: true,
             composed: true,
           })
         );


        


        


         
     } 
 catch (error) {
   console.error("Error creating survey: ", error);
   
   }

    }
    

  render() {
    return html`
      <div class="container">
        ${this.user
        ? html`
       <div class="container">
        <h1>Create a Survey</h1>

        <label>What is the name of the survey?</label>
        <input name="title" type="text" .value=${this.title} required/>
        ${this.errors.title ? html`<span class="error">${this.errors.title}</span>` : ''}

        <label for="administered">When is this survey being administered?</label>
        <select id="administered" @change=${(e) => (this.administered = e.target.value)}>
          <option value="">Select...</option>
          <option value="Before program">Before program</option>
          <option value="After program">After program</option>
        </select>
        ${this.errors.administered ? html`<spam class="error">${this.errors.administered}</spam>` : ''}

        <label for="ageGroup">What age group are you surveying?</label>
        <select id="ageGroup" @change=${(e) => (this.ageGroup = e.target.value)}>
          <option value="">Select...</option>
          <option value="K-12">K-12</option>
          <option value="18-24">18-24</option>
          <option value="25-34">25-34</option>
        </select>
        ${this.errors.ageGroup ? html`<span class="error">${this.errors.ageGroup}</span>` : ''}

        <label>What is the scope of your program?</label>
        <div class="checkbox-group">
          <label><input type="checkbox" name="scope" value="STEM" /> STEM</label>
          <label><input type="checkbox" name="scope" value="Gender-based" /> Gender-based</label>
          <label><input type="checkbox" name="scope" value="Academic support" /> Academic support</label>
        </div>
        ${this.errors.scope ? html`<span class="error">${this.errors.scope}</span>` : ''}

        <label>What part of your program are you measuring?</label>
        <div class="checkbox-group">
          <label><input type="checkbox" name="measurement" value="Quality" /> Quality</label>
          <label><input type="checkbox" name="measurement" value="Effect on participants" /> Effect on participants</label>
          <label><input type="checkbox" name="measurement" value="Effect on academic behavior" /> Effect on academic behavior</label>
        </div>
        ${this.errors.measurement ? html`<span class="error">${this.errors.measurement}</span>` : ''}

        <label>What metric do you want to use to measure your results?</label>
        <div class="checkbox-group">
          <label><input type="checkbox" name="metrics" value="Grade" /> Grade</label>
          <label><input type="checkbox" name="metrics" value="Gender" /> Gender</label>
          <label><input type="checkbox" name="metrics" value="Race/Ethnicity" /> Race/Ethnicity</label>
          <label><input type="checkbox" name="metrics" value="Age" /> Age</label>
        </div>
        ${this.errors.metrics ? html`<span class="error">${this.errors.metrics}</span>` : ''}

        <div class="buttons">
          <button class="cancel" @click=${this.homeSurvey}>Cancel</button>

           <button @click=${this.SaveContinue} class="button" ?disabled="${this.isLoading}">
            ${this.isLoading ? 'Continue...' : 'Save and Continue'}
            <span class="loader-container ${this.isLoading ? '' : 'hidden'}">
              <loading-spinner></loading-spinner>
            </span>
          </button>


         
        </div>
         ${this.successMessage ? html`<span class="success">${this.successMessage}</span>` : ''}
      </div>

        `
        : html`<p>You are not logged in. Please <a href="/login">login</a> to manage your organization.</p>`
      }
      </div>
    `;
  }
}