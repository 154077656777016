import { LitElement, html, css } from 'lit';
import { auth } from '../../firebase-config'; // Import your Firebase config
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { customElement, property } from 'lit/decorators.js';

@customElement('survey-home-component')
export class SurveyHomeComponent extends LitElement {
  @property({ type: Object }) user = null;
  @property({ type: Object }) organizationDetails = {
    name: 'Example Org',
    image: '',  // URL of the organization image
    members: [
      { name: 'John Doe', email: 'john@example.com' },
      { name: 'Jane Smith', email: 'jane@example.com' },
    ],
  };
  static properties = {
    activeSection: { type: String } // Track the currently active section
  };
  @property({ type: Object }) isEditing = {
    name: false,
    image: false,
    memberIndex: null, // Track which member is being edited
  };
  @property({ type: String }) newOrganizationImage = '';
  @property({ type: String }) tempMemberName = ''; // Temp name for the editing member

  static styles = css`
   .container {
      padding: 2em;
      max-width: 800px;
      margin: auto;
    }
    h2 {
      text-align: center;
    }
    .profile-section, .organization-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5em;
    }
    .organization-image {
      width: 150px;
      height: 150px;
      border-radius: 8px;
      background-color: #ccc;
      background-size: cover;
      background-position: center;
      border: 3px solid #007bff;
      position: relative;
      cursor: pointer;
    }
    .upload-label {
      background-color: #8e4953;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      margin-top: 10px;
      display: inline-block;
    }
    input[type="file"] {
      display: none;
    }
    input {
      width: 100%;
      padding: 8px;
      margin-left: 1em;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
    .details-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      border: 1px solid #ddd;
      margin-bottom: 10px;
      border-radius: 5px;
      position: relative;
    }
    .label {
      font-weight: bold;
    }
    .value {
      margin-left: 1em;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .edit-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 15px;
    }
    .button {
      padding: 8px 16px;
      font-size: 16px;
      background-color: #8e4953;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }
    .cancel-button {
      background-color: #8e4953;
    }
    .danger-zone {
      margin-top: 2em;
      text-align: center;
      border-top: 2px solid red;
      padding-top: 1em;
    }
    .danger-button {
      background-color: #8e4953;
      padding: 8px 16px;
      color: white;
      cursor: pointer;
      border-radius: 5px;
    }

     .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .header h1 {
      margin: 0;
    }

    .header button {
      padding: 10px 20px;
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
    }

    .main-content {
      display: flex;
      gap: 20px;
    }

    .left-menu {
      width: 20%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #ddd;
    }

    .menu-item {
  display: flex;
  align-items: center; /* Align icon and text */
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.menu-item svg {
  margin-right: 8px; /* Space between icon and text */
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item.active {
  background-color: #e0e0e0;
  font-weight: bold;
}


    .right-content {
      width: 80%;
    }

    .survey-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #ddd;
    }

    .survey-item div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .survey-item button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex; align-items: center; gap: 8px; border: none; background: none; cursor: pointer;
    }

    .survey-item button img {
      width: 20px;
      height: 20px;
    }
      button.edit-delete-btn {display: flex; align-items: center; gap: 8px; border: none; background: none; cursor: pointer;}
       button.edit-edit-btn {display: flex; align-items: center; gap: 8px; border: none; background: none; cursor: pointer;}
      
  `;

  constructor() {
    super();
    this.checkAuth();
    this.activeSection = 'active'; // Default to 'Active' section
  }

  checkAuth() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.requestUpdate();
      } else {
        this.user = null;
        this.requestUpdate();
      }
    });
  }

  toggleEdit(field, index = null) {
    if (field === 'membername') {
      this.isEditing = { ...this.isEditing, memberIndex: index };
      this.tempMemberName = this.organizationDetails.members[index].name; // Save current member name to temp
    } else {
      this.isEditing = { ...this.isEditing, [field]: !this.isEditing[field] };
    }
    this.requestUpdate();
  }

  handleInputChange(e, field, index = null) {
    if (field === 'membername' && index !== null) {
      this.tempMemberName = e.target.value; // Update temporary name
    } else {
      this.organizationDetails = { ...this.organizationDetails, [field]: e.target.value };
    }
  }

  handleImageUpload(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.newOrganizationImage = reader.result;  // Store the base64 image
        this.isEditing.image = true;  // Set to editing mode
        this.requestUpdate();
      };
      reader.readAsDataURL(file);
    }
  }

  saveChanges(field) {
    if (field === 'image') {
      this.organizationDetails.image = this.newOrganizationImage;  // Save the uploaded image
    } else if (field === 'membername' && this.isEditing.memberIndex !== null) {
      this.organizationDetails.members[this.isEditing.memberIndex].name = this.tempMemberName; // Update member name
    }
    this.isEditing = { ...this.isEditing, [field]: false, memberIndex: null };  // Exit edit mode
    this.tempMemberName = ''; // Clear temporary member name
    this.requestUpdate();
  }

  cancelEdit(field) {
    if (field === 'membername' && this.isEditing.memberIndex !== null) {
      this.tempMemberName = this.organizationDetails.members[this.isEditing.memberIndex].name; // Reset to original name
    }
    this.isEditing = { ...this.isEditing, [field]: false, memberIndex: null };  // Exit edit mode
    this.requestUpdate();
  }

  deleteMember(index) {
    this.organizationDetails.members.splice(index, 1);  // Remove member
    this.isEditing = { ...this.isEditing, memberIndex: null };  // Clear editing state
    this.requestUpdate();
  }

  createSurvey() {

  console.log('Create survey button clicked');

    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/create-survey" },
        bubbles: true,
        composed: true,
      })
    );
  }



  async logout() {
    try {
      await signOut(auth);
      console.log('User signed out');
      localStorage.clear();
      this.dispatchEvent(new CustomEvent('navigate-to-route', {
        detail: { routePath: '/' },
        bubbles: true,
        composed: true,
      }));
    } catch (error) {
      console.error('Sign out error', error);
    }
  }


  // Function to change the active section
  changeSection(section) {
    this.activeSection = section;
  }

  // Function to render the survey list based on the active section
  renderSurveyList() {
    const surveys = this.getSurveysBySection(this.activeSection);

    return surveys.length
      ? surveys.map(
        survey => html`
          <div class="survey-item">
            <div>
              <input type="checkbox" ?checked=${survey.isActive} />
             
            </div>
            <div> <p>${survey.name}</p></div>
            <div>
              <button class="edit-edit-btn" @click=${() => this.editSurvey(survey)}>
                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 16L11 20H21V16H15Z" fill="black"/>
<path d="M12.06 7.19L3 16.25V20H6.75L15.81 10.94L12.06 7.19ZM5.92 18H5V17.08L12.06 10.02L12.98 10.94L5.92 18Z" fill="black"/>
<path d="M18.71 8.04C19.1 7.65 19.1 7.02 18.71 6.63L16.37 4.29C16.17 4.09 15.92 4 15.66 4C15.41 4 15.15 4.1 14.96 4.29L13.13 6.12L16.88 9.87L18.71 8.04Z" fill="black"/>
</svg> Edit
              </button>
              <button class="edit-delete-btn" @click=${() => this.deleteSurvey(survey)}>
                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z" fill="black"/>
</svg> Delete
              </button>
            </div>
          </div>
        `
      )
      : html`<p>No surveys available in this section.</p>`;
  }

  // Function to get surveys based on the active section
  getSurveysBySection(section) {
    const allSurveys = {
      active: [
        { name: 'Survey 1', isActive: true },
        { name: 'Survey 3', isActive: true }
      ],
      draft: [
        { name: 'Survey 2', isActive: false }
      ],
      closed: [
        { name: 'Survey 4', isActive: false },
        { name: 'Survey 5', isActive: false }
      ],
      archive: [
        { name: 'Survey 6', isActive: false }
      ]
    };

    return allSurveys[section] || [];
  }

  // // Function to handle creating a new survey
  // createSurvey() {
  //   console.log('Create survey button clicked');
  //   // Implement your create survey logic here
  // }

  // Function to handle editing a survey
  editSurvey(survey) {
    console.log('Edit survey:', survey.name);
    // Implement your edit survey logic here
  }

  // Function to handle deleting a survey
  deleteSurvey(survey) {
    console.log('Delete survey:', survey.name);
    console.log('Delete survey:', survey.id);
    // Implement your delete survey logic here
  }

  render() {
    return html`
      <div class="container">
        ${this.user
        ? html`
            <div class="container">
        <!-- Header with Create Survey button -->
        <div class="header">
          <h1>Survey Manager</h1>
          <button @click=${this.createSurvey}>Create Survey</button>

          
        
        </div>

        <!-- Main content with two sections -->
        <div class="main-content">
          <!-- Left Menu Section -->
          <div class="left-menu">
            <div class="menu-item ${this.activeSection === 'active' ? 'active' : ''}" @click=${() => this.changeSection('active')}>
                  <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1295_450)">
<path d="M23.99 9.5C23.99 8.78 23.62 8.15 23.05 7.8L14 2.5L4.95 7.8C4.38 8.15 4 8.78 4 9.5V19.5C4 20.6 4.9 21.5 6 21.5H22C23.1 21.5 24 20.6 24 19.5L23.99 9.5ZM21.99 9.5V9.51L14 14.5L6 9.5L14 4.82L21.99 9.5ZM6 19.5V11.84L14 16.86L21.99 11.87L22 19.5H6Z" fill="black"/>
</g>
<defs>
<filter id="filter0_d_1295_450" x="-2" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1295_450"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1295_450" result="shape"/>
</filter>
</defs>
</svg>  Active
            </div>
            <div class="menu-item ${this.activeSection === 'draft' ? 'active' : ''}" @click=${() => this.changeSection('draft')}>
             <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1295_450)">
<path d="M23.99 9.5C23.99 8.78 23.62 8.15 23.05 7.8L14 2.5L4.95 7.8C4.38 8.15 4 8.78 4 9.5V19.5C4 20.6 4.9 21.5 6 21.5H22C23.1 21.5 24 20.6 24 19.5L23.99 9.5ZM21.99 9.5V9.51L14 14.5L6 9.5L14 4.82L21.99 9.5ZM6 19.5V11.84L14 16.86L21.99 11.87L22 19.5H6Z" fill="black"/>
</g>
<defs>
<filter id="filter0_d_1295_450" x="-2" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1295_450"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1295_450" result="shape"/>
</filter>
</defs>
</svg>
   Draft
            </div>
            <div class="menu-item ${this.activeSection === 'closed' ? 'active' : ''}" @click=${() => this.changeSection('closed')}>
              <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1295_452)">
<path d="M20 19V5C20 3.9 19.1 3 18 3H8C6.9 3 6 3.9 6 5V19H4V21H22V19H20ZM18 19H8V5H18V19Z" fill="black"/>
<path d="M12 11H10V13H12V11Z" fill="black"/>
</g>
<defs>
<filter id="filter0_d_1295_452" x="-3" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1295_452"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1295_452" result="shape"/>
</filter>
</defs>
</svg>
 Closed
            </div>
            <div class="menu-item ${this.activeSection === 'archive' ? 'active' : ''}" @click=${() => this.changeSection('archive')}>
              <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1295_454)">
<path d="M22 18.125V2.125H6V18.125H4V20.125H15.25C15.25 21.095 16.03 21.875 17 21.875C17.97 21.875 18.75 21.095 18.75 20.125H24V18.125H22ZM20 10.125H18V8.125H20V10.125ZM16 10.125H8V8.125H16V10.125ZM16 12.125V14.125H8V12.125H16ZM18 12.125H20V14.125H18V12.125ZM20 6.125H18V4.125H20V6.125ZM16 4.125V6.125H8V4.125H16ZM8 18.125V16.125H16V18.125H8ZM18 18.125V16.125H20V18.125H18Z" fill="black"/>
</g>
<defs>
<filter id="filter0_d_1295_454" x="-2" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1295_454"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1295_454" result="shape"/>
</filter>
</defs>
</svg>
 Archive
            </div>
          </div>

          <!-- Right Content Section (Survey List) -->
          <div class="right-content">
            ${this.renderSurveyList()}
          </div>
        </div>
      </div>
            `
        : html`<p>You are not logged in. Please <a href="/login">login</a> to manage your organization.</p>`
      }
      </div>
    `;
  }
}
