// header-component.js
import { LitElement, html, css } from "lit";
import { auth, db } from "../../firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, collection, query, where, doc, getDocs, getDoc,Timestamp,setDoc } from "firebase/firestore";
import authService from '../auth-view/authService';


export class HeaderComponent extends LitElement {
  static properties = {
    user: { type: Object },
    currentUser: { type: Object }, // Store the current user
    organizations: { type: Array }, // Store fetched organizations
    selectedOrg: { type: String }, // Store selected organizations
    surveyHome:{ type: String },
    inviteId:{ type: String },
    surveyData:{ type: Array },
    currectSurveyorgName:{ type: String }, // Store selected organizations
  };

  static styles = css`
     header { display: flex;  justify-content: space-between;  align-items: center; padding:2px; color: white; }
    .logo { font-size: 1.5em; font-weight: bold; cursor: pointer; }
    .landingIMG { max-width:100%; height: auto; width:30%; }
    .auth-buttons {  display: flex; gap: 1em; }
     button {  padding: 2em;  cursor: pointer;  text-align: right;  color: rgb(117, 91, 0); }
     button:hover {  background-color: #555;  }
    .welcome-text {  padding:0.5em;  color: #755b00;  font-size:15px;  }
    .login, .signup, .logout{padding:1em; cursor: pointer; text-align: right; color: #755b00; font-size:15px; }
     div.main {display:block;width:100%;max-width:600px;margin:0px auto;}
     .org-dropdown {padding: 0.5em;}
    select{ border-radius: 4px 4px 0px 0px; border-top: 1px solid rgb(0, 0, 0); border-right: 1px solid rgb(0, 0, 0); border-left: 1px solid rgb(0, 0, 0);border-image: unset; border-bottom: 1px solid rgb(0, 0, 0);background-color: rgb(240, 222, 223);}
    div.org-dropdown label { color: #755b00;  font-size:15px;} 
    span.no-org-message {padding: 0.5em; color: rgb(117, 91, 0);font-size: 15px;}

    @media (max-width: 768px) {
        .landingIMG { max-width:100%; height: auto; width:100%; }   
    }
  `;

  constructor() {
    super();
    this.user = null;
    this.currentUser = null; // Initialize currentUser as null
    this.organizations = []; // Initialize organizations array
    this.selectedOrg = localStorage.getItem('selectedOrg') || ''; // Load from localStorage
    this.surveyHome = '';
    this.inviteId = '';
    this.surveyData = [];
    this.currectSurveyorgName = '';
  }

  async getInvitationCodeFromUrl() {
    // const urlParts = window.location.pathname.split('/');

    const pathSegments = window.location.pathname.split('/');
    const surveyHomeCheck = pathSegments[pathSegments.indexOf('complete')]; // Finds the ID after "edit"
    this.inviteId  = pathSegments[pathSegments.indexOf('complete') + 1]; // Finds the ID after "edit"
    this.surveyHome = surveyHomeCheck;
    
     this.requestUpdate();
     console.log(" this.surveyHome"+ this.surveyHome);
    //this.surveyData = await this.fetchSurveyData(this.inviteId);
  }

  async fetchSurveyData() {

    if(this.inviteId){
    const url = `https://us-central1-datapoint-44397.cloudfunctions.net/api/survey-status?invitation_code=${invitationCode}`;
    try {
      const response = await axios.get(url);
      return response.data;


    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          // Specific handling for 404 error
          this.surveyStatus = 'error';
          this.message = 'Survey not found. Please check the invitation code.';
        } else {
          // Handle other HTTP errors
          this.surveyStatus = 'error';
          this.message = `Error: ${error.response.status} - ${error.response.data.message || 'An error occurred'}`;
        }
      } else {
        // Handle network or other errors not associated with a response
        this.surveyStatus = 'error';
        this.message = 'Failed to fetch survey status. Please check your network connection.';
      }
      
      // Re-render the component to display the error
      this.requestUpdate();
      
      // Optionally, rethrow the error if you want the calling function to handle it further
      //throw new Error(this.message);
      throw new Error(error.response ? error.response.data.message : 'Server error');
    }
  }
  }



 

  connectedCallback() {
    super.connectedCallback();
    this.checkAuthState(); // Check authentication state when component is mounted

    if(!this.currentUser){
    this.getInvitationCodeFromUrl();
    }
  }

  checkAuthState() {
    //const auth = auth;

    // Wait for Firebase authentication state to be available
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, set currentUser and fetch organizations
        this.currentUser = user;
        this.fatchUserBasedOrganizations();

      } else {
        // No user is signed in, set currentUser to null
        console.log("No user is signed in");
        this.currentUser = null;
      }
    });
  }

  handleOrgChange(event) {
    this.selectedOrg = event.target.value;
    const selectedOrgValue = event.target.value;
    console.log('Selected Organization:', this.selectedOrg); // Debugging log
    localStorage.setItem('selectedOrg', this.selectedOrg); // Save in localStorage
    this.dispatchEvent(new CustomEvent('org-changed', {
      detail: {  selectedOrgValue },
      bubbles: true, // Allows the event to bubble up to parent elements
      composed: true
    }));

    


  }

  async fatchOrganizationNameOfSurvey(){

//organization_id


  }



  async fatchUserBasedOrganizations(){

    if (!this.currentUser) {
      console.log("User is not logged in, skipping fetch");
      return; // Exit early if no user is logged in
    }

    try
    {

      console.log("User logged in Function Org Called");
      const orgRef = collection(db, 'organizations'); // Reference to 'organizations' collection
      const querySnapshot = await getDocs(orgRef);
      const userId = this.currentUser.uid; // Assuming currentUser is set and has a uid property
      const userPath = `users/${userId}`; // User path to match in the members array
       // Map through the query results to extract organization data
      this.organizations = querySnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().name || 'Unknown Organization', // Fallback for name
      members: doc.data().members || [] ,// Fallback to empty array if members undefined
      survey: doc.data().survey || [], // Array of survey references
      reports: doc.data().reports || [], // Array of report references
      dateCreated: doc.data().dateCreated?.toDate() || new Date(), // Convert Firestore timestamp
      dateUpdated: doc.data().dateUpdated?.toDate() || new Date()  // Convert Firestore timestamp
      })).filter(org => org.members.some(memberRef => memberRef.path === userPath)); // Match userPath
      // Check if userPath matches any member reference path
      console.log('Fetched Organizations:', this.organizations); // Debugging log
     // console.log('Fetched Organizations:', this.organizations.length); // Debugging log

      const dataOrg = this.organizations;

      this.dispatchEvent(new CustomEvent('org-data', {
        detail: {  dataOrg },
        bubbles: true, // Allows the event to bubble up to parent elements
        composed: true
      }));

      
      if(this.organizations.length > 0)
        {

        // Check if 'selectedOrg' exists in localStorage
          if (!localStorage.getItem('selectedOrg')) 
             {
                // If localStorage is empty, store the first organization (index 0) in localStorage
                if (this.organizations.length > 0) {
                  const firstOrganization = this.organizations[0];
                  localStorage.setItem('selectedOrg', firstOrganization.id);
                 const firstOrganizationID = firstOrganization.id;

                  this.dispatchEvent(new CustomEvent('isOrgLoaded', {
                    detail: {  firstOrganizationID },
                    bubbles: true, // Allows the event to bubble up to parent elements
                    composed: true
                  }));

                  this.dispatchEvent(
                    new CustomEvent('org-data', {
                      detail: { message: 'Fetch new organizations' },
                      bubbles: true,
                      composed: true,
                    })
                  );

                  
                  console.log('No organization found in localStorage. Setting the first organization:', firstOrganization);
                } else {
                  console.log('No organizations found in Firestore');
                }
              }

        }
        else{

          localStorage.removeItem('selectedOrg');

        }
    
      } catch (error) {
      console.error('Error fetching organizations:', error);
      }


  }

  login() {
    // Add your login logic here
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/login" },
        bubbles: true,
        composed: true,
      })
    );
  }

  logo() {
    // Add your login logic here
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/" },
        bubbles: true,
        composed: true,
      })
    );
  }

  signup() {
    // Add your login logic here
    // Router.go("/signup");
    this.dispatchEvent(
      new CustomEvent("navigate-to-route", {
        detail: { routePath: "/signup" },
        bubbles: true,
        composed: true,
      })
    );
  }

  logout() {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        this.dispatchEvent(
          new CustomEvent("navigate-to-route", {
            detail: { routePath: "/" },
            bubbles: true,
            composed: true,
          })
        );

      })
      .catch((error) => {
        console.error("Sign out error", error);
      });
  }

  render() {
    return html`
      <header>
        <div class="logo">
          <img
            @click=${this.logo}
            class="landingIMG"
            src="../assets/logo.png"
            alt="Centered Image"
          />
        </div>
        <div class="auth-buttons">
        ${this.currentUser
          ? html`
              ${this.organizations.length > 0
                ? html`
                    <div class="org-dropdown">
                      <label for="org-select">Choose an Organization:</label>
                      <select id="org-select" @change="${this.handleOrgChange}">
                        <option value="" disabled>--Select an Organization--</option>
                        ${this.organizations.map(
                          (organization) => html`
                            <option
                              value="${organization.id}"
                              ?selected="${this.selectedOrg === organization.id}"
                            >
                              ${organization.name}
                            </option>
                          `
                        )}
                      </select>
                      <span class="welcome-text">Welcome, ${this.currentUser.displayName || this.currentUser.name}</span>
                      <dropdown-menu .details="${this.currentUser}"></dropdown-menu>
                    </div>
                  `
                : html`<span class="no-org-message">No organizations Assigned for your account.</span>
                      <span class="welcome-text">Welcome, ${this.currentUser.displayName || this.currentUser.name}</span>
                      <dropdown-menu .details="${this.currentUser}"></dropdown-menu></div>`
              }
            `
          : html `
          <div>
      ${this.surveyHome === 'complete' && !this.currentUser
        ? html`<span class="welcome-text">Organization Name : ${this.currectSurveyorgName} </span>` // Show only the company name
        : html`
            <p>
              This ${this.surveyHome}
              <span class="signup" @click=${this.signup}>Sign Up</span>
              <span class="login" @click=${this.login}>Log In</span>
            </p>
          `}
    </div>
            `}
      </div>
      </header>
    `;
  }
}

customElements.define("header-component", HeaderComponent);
