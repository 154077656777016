import {LitElement, html} from 'lit';
import {dropdownStyles} from './dp-dropdown-css.js';

export class QDropdownComponent extends LitElement {
  static get styles() {
    return [dropdownStyles];
  }
  static get properties() {
    return {
      id: {type: String},
      title: {type: String},
      options: {type: Array},
    };
  }


  constructor() {
    super();
    this.title = '';
    this.options = [];
  }

  render() {
    return html`
      <div class="block-group">
      <label class="title">${this.title}</label>

      ${this.options};
      <select @change="${this._onChange}">
      ${Object.entries(this.options).map(
                ([key, name]) => html`
                              <option value=${key} ?selected=${this.language === key}>${name}</option>
                            `
              )}
                       
      </select>
      </div>
    `;
  }
}

customElements.define('question-dropdown', QDropdownComponent);
