import { LitElement, html } from 'lit';
import { resetStyles } from './dp-reset-css';

export class DpReset extends LitElement {
  static get styles() {
    return [ resetStyles ];
  }

  static get properties() {
    return {
      id: {type: String},
      value: {type: String}
    };
  }

  constructor() {
    super();
    this.id = '';
    this.value = '';
  }

  render() {
    return html`
        <input
            class="reset"
            type="reset"
            id=${this.id}
            value=${this.value}
        >            
        </input>
    `;
  }
}

customElements.define('dp-reset', DpReset);

