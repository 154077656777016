import {LitElement, html} from 'lit';
import {dropdownStyles} from './dp-dropdown-css.js';

export class DropdownComponent extends LitElement {
  static get styles() {
    return [dropdownStyles];
  }
  static get properties() {
    return {
      id: {type: String},
      title: {type: String},
      options: {type: Array},
      selectedValue: { type: String }, // Store the selected value
      
    };
  }


  constructor() {
    super();
    this.title = '';
    this.options = [];
    console.log("title"+this.title);
    console.log("options"+this.options);
    this.selectedValue = ''; // Initialize selected value
  }

  _onChange(event) {
    this.selectedValue = event.target.value; // Update the selected value
    console.log(`Dropdown changed: ${this.selectedValue}`);

    // Emit the custom 'dropdown-change' event
    this.dispatchEvent(
      new CustomEvent('dropdown-change', {
        detail: { value: this.selectedValue }, // Pass the selected value
        bubbles: true, // Allow event to bubble up
        composed: true, // Cross shadow DOM boundaries
      })
    );
  }

  

  render() {
    return html`
      <div class="block-group">
        <label class="title">${this.title}</label>
        <select @change="${this._onChange}">
        <option value="" disabled selected hidden>Please select</option>
          ${this.options.map(
            (option) =>
              html`
                <option value="${option}" ?selected=${this.selectedValue === option}>
                  ${option}
                </option>
              `
          )}
        </select>
      </div>
    `;
  }
}

customElements.define('dp-dropdown', DropdownComponent);
