// authService.js
import { getAuth, onAuthStateChanged } from 'firebase/auth';

class AuthService {
  constructor() {
    this.currentUser = null;
    this.auth = getAuth();
    this.initAuthListener();
  }

  initAuthListener() {
    onAuthStateChanged(this.auth, (user) => {
      this.currentUser = user;
      console.log('Auth State Changed:', user);
      // You can also trigger custom events or callbacks here
    });
  }

  getCurrentUser() {
    return this.currentUser;
  }
}

const authService = new AuthService();
export default authService;
