import { LitElement, html, css } from "lit";
import axios from "axios";
import config from '../../config.js';
import { property } from 'lit/decorators.js';
import '../../components/loadingSpinner/loading-spinner.js'; // Import the spinner component
import { getAuth, signInWithEmailAndPassword ,sendEmailVerification,onAuthStateChanged, signOut} from "firebase/auth";
import { auth } from '../../firebase-config';
export class SignupComponent extends LitElement {
  @property({ type: String }) apiUrl = config.apiUrl; // Use the config

  static styles = css`
    /* Add your styles here */

    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh;
    }

    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90%;
      max-width: 500px;
      background-color: #ffffff;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    h2 {
      margin-bottom: 20px;
      text-align: center;
    }

    .signup-form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .signup-form input {
      margin-bottom: 15px;
      padding: 12px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: rgb(240, 222, 223);
    }

    .signup-form button {
      padding: 12px;
      font-size: 16px;
      background-color: #8e4953;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 20px;
      margin-bottom: 10px;
    }

    .signup-form button:hover {
      background-color: #7c4049;
    }

    .error {
      color: red;
      margin-top: 10px;
    }

    .success {
      color: green;
      margin-top: 10px;
    }

    .text-link {
      color: #8e4953;
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .text-link.center {
      text-align: center;
    }

    .button {
      position: relative;
    
      font-size: 16px;
      cursor: pointer;
    }

    .button[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .loader-container {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    .hidden {
      display: none;
    }
  `;

  static properties = {
    fname: { type: String },
    lname: { type: String },
    //organization: { type: String },
    email: { type: String },
    password: { type: String },
    cfpassword: { type: String },
    errorMessage: { type: String },
    successMessage: { type: String },
    isLoading: { type: Boolean },
    emailVerify :{ type: String },
    passwordVerify : { type: String },
  };

  constructor() {
    super();
    this.fname = "";
    this.lname = "";
    //this.organization = "";
    this.email = "";
    this.password = "";
    this.cfpassword = "";
    this.errorMessage = "";
    this.successMessage = "";
    this.isLoading = false;
    this.emailVerify = "";
    this.passwordVerify = "";
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this[name] = value;
  }

  handleClear() {

    // Simulate some async work
    
      this.fname = "";
      this.lname = "";
      //this.organization = "";
      this.email = "";
      this.password = "";
      this.cfpassword = "";
      this.errorMessage = "";
      this.successMessage = "";
  
  }

  validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  navigateToLogin() {
    this.dispatchEvent(new CustomEvent('navigate-to-route', {
      detail: { routePath: '/login' },
      bubbles: true,
      composed: true,
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (!this.fname || !this.lname || !this.email || !this.password || !this.cfpassword) {
      this.errorMessage = "All fields are required.";
      return;
    } else if (!this.validateEmail(this.email)) {
      this.errorMessage = "Please enter a valid email address.";
      return;
    } else if (this.password !== this.cfpassword) {
      this.errorMessage = "Passwords do not match.";
      return;
    } else {
      this.errorMessage = "";
      this.successMessage = "";

      this.isLoading = true; // Start loading

      try {
        const response = await axios.post(
         'https://us-central1-datapoint-44397.cloudfunctions.net/api/create-account',
         //'http://127.0.0.1:5001/datapoint-44397/us-central1/api/create-account',
          {
            fname: this.fname,
            lname: this.lname,
            // organization: this.organization,
            email: this.email,
            password: this.password,
            cfpassword: this.cfpassword,
            role:"user"
          }
        );

        if (response.status === 200) {
          console.log("Signup successful:", response.data);
         let userId = response.data.userId
         this.emailVerify = this.email;
         this.passwordVerify = this.password;
          this.handleClear();
          //this.successMessage = response.data.message;
          this.successMessage = 'Signup successful! A verification email has been sent to your email address.';
          this.errorMessage = ''; // Clear any error message

        } else {
          this.errorMessage = response.data.message;
          this.isLoading = false; // Stop loading
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.errorMessage = error.response.data.message;
          this.isLoading = false; // Stop loading
        } else if (error.response && error.response.status === 401) {
          this.errorMessage = error.response.data.message;
          this.isLoading = false; // Stop loading
        } else {
          this.errorMessage = 'Something went wrong. Please try again after some time.';
          this.isLoading = false; // Stop loading
        }
        console.error("Signup error: Internal server error. Please try again later.");
        this.errorMessage = 'Something went wrong. Please try again after some time.';
      } finally {
        this.isLoading = false; // Stop loading
        const userCredential = await signInWithEmailAndPassword(auth, this.emailVerify, this.passwordVerify);
        const user = userCredential.user;

        await sendEmailVerification(user);

        signOut(auth)
        .then(() => {
          this.successMessage = 'Signup successful! A verification email has been sent to your email address.';
          this.errorMessage = ''; // Clear any error message
        })
        .catch((error) => {
          this.successMessage = '';
          this.errorMessage = error;
        });

      }
    }
  }

  render() {
    return html`
      <div class="main">
        <h2>Create a DataPoint Account</h2>
        <form class="signup-form" @submit="${this.handleSubmit}">
          <input
            type="text"
            name="fname"
            .value="${this.fname}"
            @input="${this.handleInputChange}"
            placeholder="First Name"
            required
          />
          <input
            type="text"
            name="lname"
            .value="${this.lname}"
            @input="${this.handleInputChange}"
            placeholder="Last Name"
            required
          />
          <!-- <input
            type="text"
            name="organization"
            .value="${this.organization}"
            @input="${this.handleInputChange}"
            placeholder="Organization"
            required
          /> -->
          <input
            type="email"
            name="email"
            .value="${this.email}"
            @input="${this.handleInputChange}"
            placeholder="Email"
            required
          />
          <input
            type="password"
            name="password"
            .value="${this.password}"
            @input="${this.handleInputChange}"
            placeholder="Password"
            required
          />
          <input
            type="password"
            name="cfpassword"
            .value="${this.cfpassword}"
            @input="${this.handleInputChange}"
            placeholder="Re-enter Password"
            required
          />

          <button type="submit" class="button" ?disabled="${this.isLoading}">
            ${this.isLoading ? 'Signing Up...' : 'Sign Up'}
            <span class="loader-container ${this.isLoading ? '' : 'hidden'}">
              <loading-spinner></loading-spinner>
            </span>
          </button>

          <button class="button" @click="${this.handleClear}">Clear
          </button>

          ${this.errorMessage ? html`<p class="error">${this.errorMessage}</p>` : ""}
          ${this.successMessage ? html`<p class="success">${this.successMessage}</p>` : ""}

          <a class="text-link center" @click="${this.navigateToLogin}">Already have an account? Login</a>
        </form>
      </div>
    `;
  }
}

customElements.define("signup-component", SignupComponent);
