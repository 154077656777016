import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { datesStyles } from  './dp-date-css.js';

export class DateComponent extends LitElement {
  static get styles() {
    return [datesStyles];
  }
  static get properties() {
    return {
      id: { type: String },
      rows: { type: String },
      cols: { type: String }
    };
  }


  constructor() {
    super();
    this.id = 'text';
    this.rows = '4'; 
    this.cols = '50';
  }

  render() {
    return html`
        <div class="block-group">
      <label for="${this.id}" class="title">Title</label>

      <input type="date"
             id="${this.id}"
             min="${this.min}"
             max="${this.max}"
             .value="${this.value}">
             </div>
    `;
  }
}

customElements.define('dp-date', DateComponent);
