import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { textareaStyles } from './dp-textarea-css.js';

@customElement('dp-textarea')
export class DpTextarea extends LitElement {
  static styles = [textareaStyles];

  @property({ type: String }) id = 'text'; // Unique ID for the textarea
  @property({ type: Number }) rows = 4; // Number of rows
  @property({ type: Number }) cols = 50; // Number of columns
  @property({ type: String }) value = ''; // Current value of the textarea
  @state() errorMessage = ''; // Holds the validation error message

  // Handle input changes
  handleInput(event) {
    this.value = event.target.value;
    this.dispatchChangeEvent(); // Dispatch custom event on change
    this.validate(); // Trigger validation
  }

  // Validation logic
  validate() {
    if (!this.value || this.value.trim().length === 0) {
      this.errorMessage = 'This field is required.';
    } else if (this.value.length < 10) {
      this.errorMessage = 'Input must be at least 10 characters long.';
    } else {
      this.errorMessage = ''; // No errors
    }
    this.requestUpdate(); // Trigger re-render to show error message
    return this.errorMessage === ''; // Return true if valid
  }

  // Dispatch a custom change event
  dispatchChangeEvent() {
    const event = new CustomEvent('value-changed', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  render() {
    return html`
      <div class="block-group">
        <label for="${this.id}" class="title">Title</label>
        <textarea
          id="${this.id}"
          rows="${this.rows}"
          cols="${this.cols}"
          .value="${this.value}"
          @input="${this.handleInput}"
        ></textarea>
        <div class="validation-message">
          ${this.errorMessage
            ? html`<p class="error">${this.errorMessage}</p>`
            : ''}
        </div>
      </div>
    `;
  }
}
