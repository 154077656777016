import { LitElement, html, css } from 'lit';

export class PageLoader extends LitElement {
  static properties = {
    loading: { type: Boolean },
  };

  static styles = css`
    .loader-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1000;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s;
    }

    .loader-overlay.active {
      visibility: visible;
      opacity: 1;
    }

    .loader {
      width: 50px;
      height: 50px;
      border: 5px solid #f3f3f3;
      border-top: 5px solid rgb(142, 73, 83);
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  constructor() {
    super();
    this.loading = false; // Default state
  }

  // Simulated API call or page load
  async loadHidePage() {
    this.loading = false; // Hide loader
  }

  async loadShowPage() {
    this.loading = true; // Show loader
    // await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulate delay
    // this.loading = false; // Hide loader
  }



  render() {
    return html`
      <!-- <button @click="${this.loadPage}">Load Page</button> -->
      <div class="loader-overlay ${this.loading ? 'active' : ''}">
        <div class="loader"></div>
      </div>
    `;
  }
}

customElements.define('page-loader', PageLoader);
