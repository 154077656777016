import { css } from 'lit';

export const navBarStyles = css`
  :host {
    display: block;
    transition: margin-left 0.15s ease-in-out;
  }

  :host([collapsed]) {
    margin-left: 0;
  }

  .navbar {
    position: fixed;
    display: flex;
    align-items: center;
    background-color: lightgrey;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    height: 52px;
    z-index: 10;
  }

  .nav-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #a9a9a9;
  }

  .nav-links a {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  li {
    float: left;
  }

  .nav-links a:hover {
    background-color: #111;
    color: white;
  }

  .profile-icon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    position: fixed;
    right: 5px;
    cursor: pointer;
  }

  .popup-menu {
    position: absolute;
    top: 50%;
    right: 0%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    display: none;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }

  .profile-icon:hover .popup-menu,
  .popup-menu:hover,
  .menu-open {
    display: block;
    z-index: 200;
  }

  .navbar .popup-menu a {
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
  }

  .navbar .popup-menu a:hover {
    background-color: #f0f0f0;
  }

  .hamburger {
    min-width: 50px;
    font-size: 25px;
    cursor: pointer;
    z-index: 1000;
    align-self: stretch;
    text-align: center;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    background-color: #808080;
  }

  .hamburger:hover {
    background-color: #111;
    color: white;
  }

  .app-suite-name {
    padding: 5px;
  }

  @media (min-width: 600px) {
    .app-suite-name {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .nav-links {
      display: none;
    }
  }
`;
